import PropTypes from 'prop-types';
import { useState } from 'react';
import { Button, Divider, Switch, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { getSearchParam, updateSearchParam } from '@icp/utils';
import SizeDefaultSvg from './size-default.svg';
import SizeDefaultSvgSelected from './size-default-selected.svg';
import SizeSmallSvg from './size-small.svg';
import SizeSmallSvgSelected from './size-small-selected.svg';
import SizeLargeSvg from './size-large.svg';
import SizeLargeSvgSelected from './size-large-selected.svg';
import Icon from '../Icon';

const all = ['showDeleted', 'size', 'export', 'resetSetting'];

function TableSetting(props) {
  const {
    items = all,
    size,
    exportType,
    onSizeChange,
    onResetSetting,
    onRefresh,
    onExport,
  } = props;

  const { t } = useTranslation(['icp-components']);

  const [open, setOpen] = useState(false);

  const showDeleted = getSearchParam('include_deleted') === 'true';

  const handleShowDeletedChanged = (checked) => {
    updateSearchParam('include_deleted', checked);
    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <>
      <Tooltip title={t('toolbar.options')}>
        <Button
          className="icp-table-toolbar-setting-button"
          type="text"
          icon={<Icon name="oct:kebab-horizontal" size={16} />}
          onClick={() => setOpen(true)}
        />
      </Tooltip>
      {open ? (
        <div
          className="icp-backdrop"
          onClick={() => setOpen(false)}
          style={{ zIndex: 'var(--max-z-index)' }}
        />
      ) : null}
      <div
        className="icp-table-setting-hidden-wrapper"
        style={!open ? { visibility: 'hidden' } : undefined}
      >
        <div
          className="icp-table-setting"
          style={
            !open
              ? {
                  transform: 'translate(50%)',
                  opacity: 0,
                  visibility: 'hidden',
                }
              : undefined
          }
        >
          <div className="icp-table-setting-title">
            {t('toolbar.options')}
            <button className="icp-action-button" onClick={() => setOpen(false)}>
              <Icon name="oct:x" size={14} />
            </button>
          </div>
          {items.includes('showDeleted') ? (
            <div className="icp-table-setting-row">
              <span className="icp-table-setting-label">{t('toolbar.show-deleted')}</span>
              <Switch defaultChecked={showDeleted} onChange={handleShowDeletedChanged} />
            </div>
          ) : null}
          {items.includes('size') ? (
            <div className="icp-table-setting-column">
              <div className="icp-table-setting-label">{t('toolbar.size')}</div>
              <div className="icp-center" style={{ gap: 18, justifyContent: 'flex-start' }}>
                <div
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => onSizeChange('default')}
                >
                  <img
                    src={size === 'default' ? SizeDefaultSvgSelected : SizeDefaultSvg}
                    style={{ width: 80 }}
                    alt={t('toolbar.size-default')}
                  />
                  <div style={{ marginTop: 4 }}>{t('toolbar.size-default')}</div>
                </div>
                <div
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => onSizeChange('small')}
                >
                  <img
                    src={size === 'small' ? SizeSmallSvgSelected : SizeSmallSvg}
                    style={{ width: 80 }}
                    alt={t('toolbar.size-small')}
                  />
                  <div style={{ marginTop: 4 }}>{t('toolbar.size-small')}</div>
                </div>
                <div
                  style={{ textAlign: 'center', cursor: 'pointer' }}
                  onClick={() => onSizeChange('large')}
                >
                  <img
                    src={size === 'large' ? SizeLargeSvgSelected : SizeLargeSvg}
                    style={{ width: 80 }}
                    alt={t('toolbar.size-default')}
                  />
                  <div style={{ marginTop: 4 }}>{t('toolbar.size-large')}</div>
                </div>
              </div>
            </div>
          ) : null}
          <Divider style={{ margin: 0 }} />
          <div className="icp-table-setting-actions">
            {items.includes('export') ? (
              <div className="icp-action-button" onClick={onExport}>
                <Icon name="solar:download-minimalistic-bold" size={16} />
                {(exportType === 'excel' && t('toolbar.export-excel')) ||
                  (exportType === 'png' && t('toolbar.export-png')) ||
                  ''}
              </div>
            ) : null}
            {items.includes('resetSetting') ? (
              <div className="icp-action-button" onClick={onResetSetting}>
                <Icon name="solar:restart-bold" size={16} />
                {t('toolbar.clear-setting')}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

TableSetting.propTypes = {
  items: PropTypes.arrayOf(PropTypes.oneOf(all)),
  exportType: PropTypes.oneOf(['excel', 'png']),
  size: PropTypes.oneOf(['default', 'small']),
  onRefresh: PropTypes.func,
  onSizeChange: PropTypes.func,
  onResetSetting: PropTypes.func,
  onExport: PropTypes.func,
};

export default TableSetting;
