import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { immutableMove } from '@icp/utils';
import SearchBox from '../SearchBox';
import Icon from '../Icon';
import Popover from '../Popover';
import Reorderable from '../Reorderable';

function sortByOrder(columnDefs, orderedColIds) {
  const existColDefs = orderedColIds.map((colId) => {
    return columnDefs.find((colDef) => colDef.colId === colId);
  });
  const restColDefs = columnDefs.filter(
    (colDef) => !orderedColIds.find((colId) => colId === colDef.colId),
  );
  return existColDefs.concat(restColDefs).filter(Boolean);
}

function ColumnSelect(props) {
  const {
    columnDefs,
    orderedColIds: orderedColIdsProp,
    hiddenColIds: hiddenColIdsProp,
    onOrderChange,
    onVisibleChange,
  } = props;

  const { t } = useTranslation(['icp-components']);

  const [orderedColIds, setOrderedColIds] = useState(() => {
    return orderedColIdsProp || columnDefs.map((colDef) => colDef.colId);
  });
  const [hiddenColIds, setHiddenColIds] = useState(() => {
    return (
      hiddenColIdsProp || columnDefs.filter((colDef) => colDef.hide).map((colDef) => colDef.colId)
    );
  });
  useEffect(() => {
    if (orderedColIdsProp) {
      setOrderedColIds(orderedColIdsProp);
    }
    if (hiddenColIdsProp) {
      setHiddenColIds(hiddenColIdsProp);
    }
  }, [orderedColIdsProp, hiddenColIdsProp]);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [dragging, setDragging] = useState(false);

  const anchorEl = useRef(null);

  const displayColumns = useMemo(() => {
    return sortByOrder(columnDefs, orderedColIds)
      .map((colDef) => {
        if (
          !colDef.headerName ||
          !`${colDef.headerName}`.toLowerCase().includes(searchText || '')
        ) {
          return null;
        }
        return colDef;
      })
      .filter(Boolean);
  }, [columnDefs, orderedColIds, searchText]);

  const handleClick = (colId) => {
    const oldHide = hiddenColIds.includes(colId);

    const newHiddenColIds = [...hiddenColIds];
    const index = newHiddenColIds.indexOf(colId);
    if (oldHide) {
      if (index !== -1) {
        newHiddenColIds.splice(index, 1);
      }
    } else if (index === -1) {
      newHiddenColIds.push(colId);
    }
    setHiddenColIds(newHiddenColIds);

    onVisibleChange({ colId, hide: !oldHide, newHiddenColIds });
  };

  const handleChangeOrder = ({ fromIndex, toIndex }) => {
    const fromId = displayColumns[fromIndex].colId;
    const toId = displayColumns[toIndex].colId;
    const newOrderedColIds = immutableMove(displayColumns, fromIndex, toIndex).map(
      (colDef) => colDef.colId,
    );
    setOrderedColIds(newOrderedColIds);
    onOrderChange({ fromId, toId, newOrderedColIds });
  };

  return (
    <>
      <Tooltip title={t('toolbar.show-hide-column')}>
        <Button
          className="icp-table-toolbar-column-button"
          type="text"
          icon={<Icon name="oct:columns" size={16} />}
          onClick={() => setOpen(true)}
          ref={anchorEl}
        />
      </Tooltip>
      <Popover
        className="icp-table-column-select"
        anchorEl={anchorEl.current}
        open={open}
        placement="bottom-end"
        onClose={() => {
          setOpen(false);
          setSearchText('');
        }}
      >
        <SearchBox
          open={true}
          suppressSpeech={true}
          autoFocus={true}
          debounce={false}
          onSearch={setSearchText}
        />
        <div className={clsx('icp-table-menu icp-thin-scrollbar', { dragging })}>
          {open &&
            displayColumns.map((colDef) => {
              return (
                <Reorderable
                  key={colDef.colId}
                  onChange={handleChangeOrder}
                  onDragStart={() => setDragging(true)}
                  onDragEnd={() => setDragging(false)}
                >
                  <div
                    className="item icp-clickable"
                    onClick={!colDef.lockVisible ? () => handleClick(colDef.colId) : null}
                  >
                    <span className="drag-indicator">
                      <Icon name="material:drag-indicator-rounded" size={16} />
                    </span>
                    <span
                      style={{
                        opacity:
                          (colDef.lockVisible && 0.2) ||
                          (!hiddenColIds.includes(colDef.colId) && 1) ||
                          0,
                      }}
                    >
                      <Icon name="oct:check" size={16} />
                    </span>
                    {colDef.headerName || colDef.colId}
                  </div>
                </Reorderable>
              );
            })}
        </div>
      </Popover>
    </>
  );
}

ColumnSelect.propTypes = {
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  orderedColIds: PropTypes.arrayOf(PropTypes.string),
  hiddenColIds: PropTypes.arrayOf(PropTypes.string),
  onOrderChange: PropTypes.func,
  onVisibleChange: PropTypes.func,
};

export default ColumnSelect;
