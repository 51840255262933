/* eslint-disable no-underscore-dangle */
import clsx from 'clsx';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { selectContext } from '@icp/form-renderer-core';
import { Loading } from '@icp/components';
import { getSearchParam, loadDHTMLXGantt, randomNumber, updateSearchParam } from '@icp/utils';
import { Modal } from 'antd';
import { isEqual } from 'lodash-es';
import { useEventCallback } from '@icp/hooks';
import { useClassName, useConditionalProperty, useVariablePattern } from '../../hooks';
import { DataFiltersType, SingleDataSourceTypes } from '../../propTypes';
import { useElementDecorator, useFormApi, useIsInDesign } from '../../FormRenderCtx';
import { withFieldWrapper } from '../../fieldWrapper';
import { useStore } from '../../store';
import GanttToolbar from './GanttToolbar';
import ButtonElement from '../ButtonElement';
import { CurrentDataProvider } from '../../currentDataCtx';
import useGanttDataSource from './useGanttDataSource';
import RecursionRenderer from '../../RecursionRenderer';
import {
  addTodayMarker,
  formatGanttColumns,
  loadGanttDefaultState,
  loadGanttInitState,
  setAllGanttColumnState,
} from './utils';
import setGanttConfig from './setGanttConfig';
import setGanttCalendar from './setGanttCalendar';
import { makeSettingKey } from '../TableElement/utils';
import { saveSetting } from './settingHandler';

const EMPTY_OBJECT = {};

const GanttElement = forwardRef(function GanttElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    style,
    componentProps = {},
    readonly: readonlyProp,
    //
  } = props;
  const {
    style: componentStyle,
    // 为了统一配置方便 columnDefs 使用和 AG-Grid 一样的配置
    columnDefs: columnDefsProp,
    dataSources = EMPTY_OBJECT,
    relation: relationProp = EMPTY_OBJECT,
    mapping = EMPTY_OBJECT,
    ganttConfig = EMPTY_OBJECT,
    toolbarFields,
    toolbarChildren: toolbarChildrenProp,
    pinnedFilter,
    settingKey: settingKeyProp,
    defaultFilterModel,
    suppressSaveSetting,
  } = componentProps;

  const { t } = useTranslation(['icp-form-renderer', 'icp-vendor-dhtmlx-gantt']);

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();
  const store = useStore();
  const formApi = useFormApi();
  const context = selectContext(store.getState());
  const readonly = useConditionalProperty(readonlyProp);

  const [modal, contextHolder] = Modal.useModal();

  const settingKey = useMemo(() => {
    if (isInDesign || suppressSaveSetting) return null;
    return settingKeyProp || makeSettingKey(id, context, 'gantt');
  }, [context, id, isInDesign, settingKeyProp, suppressSaveSetting]);
  const searchKey = id ? `${id}-search` : null;

  const defaultGanttState = useMemo(() => {
    // 其余属性会自动填充成 undefined，给 Toolbar reset state 以及切换成默认视图的时候覆盖已有设置使用
    return loadGanttDefaultState({
      zoomLevel: 'month',
      filter: {
        filterModel: defaultFilterModel,
      },
    });
  }, [defaultFilterModel]);

  const [ganttLoaded, setGanttLoaded] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState({});
  const [loading, setLoading] = useState(false);
  const [ganttState, setGanttState] = useState(() => {
    return loadGanttInitState(settingKey, defaultGanttState);
  });
  const [searchText, setSearchText] = useState(getSearchParam(searchKey) || '');

  const { filter, currentCalendar } = ganttState;
  const { filterModel } = filter;

  const isUnMount = useRef(null);
  const nodeRef = useRef(null);
  const eleRef = useRef(null);
  const ganttRef = useRef(null);
  const refreshTodayMarker = useRef(null);
  const readyToSaveSetting = useRef(false);
  const ganttStateRef = useRef(null);
  ganttStateRef.current = ganttState;

  // relation 来实现类似 EditableTable 一样的绑定 Gantt 所有数据到某一个父表单数据
  const relationValue = useVariablePattern(relationProp?.value, true);
  const relation = useMemo(() => {
    return {
      colId: relationProp.colId,
      value: relationValue,
    };
  }, [relationProp.colId, relationValue]);

  const {
    loading: dataLoading,
    error,
    ganttData,
    calendarData,
  } = useGanttDataSource({
    dataSources,
    refresh: refreshFlag,
    mapping,
    relation,
    filterModel,
    searchText,
  });

  useEffect(() => {
    return () => {
      isUnMount.current = true;
    };
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [dataLoading, error, ganttData]);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const [dialogItem, setDialogItem] = useState(null);
  const dialogBtnRef = useRef(null);
  const ganttApiRef = useRef(null); // 给 GanttActionCellRenderer 用
  const deletePromisesRef = useRef([]); // 给 GanttActionCellRenderer 里处理 loading 和刷新使用

  // 不响应 id 的变化
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToRegister = useMemo(() => id || `random-gantt-id-${randomNumber(100000)}`, []);
  useEffect(() => {
    formApi.asyncComponentManager.register(idToRegister);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openDialog = useCallback((item) => {
    setDialogItem(item);
    setTimeout(() => {
      dialogBtnRef.current.node.click();
    }, 16);
  }, []);

  const handleRefresh = useCallback(() => {
    setRefreshFlag({});
  }, []);

  const ganttApi = useMemo(() => {
    return {
      node: nodeRef.current,
      get gantt() {
        return ganttRef.current;
      },
      refresh: handleRefresh,
      openDialog,
      setLoading,
    };
  }, [handleRefresh, openDialog]);
  ganttApiRef.current = ganttApi;

  useImperativeHandle(ref, () => ganttApi, [ganttApi]);

  const columnDefs = useMemo(() => {
    return formatGanttColumns({
      columnDefsProp,
      ganttApiRef,
      deletePromisesRef,
      mapping,
      readonly,
    });
  }, [columnDefsProp, mapping, readonly]);

  const handleGanttStateChange = useEventCallback((obj, notSave) => {
    if (!readyToSaveSetting.current || isUnMount.current) {
      return;
    }

    if (!(obj && typeof obj === 'object')) {
      return;
    }

    const newState = { ...ganttState, ...obj };

    if (isEqual(newState, ganttState)) {
      return;
    }

    if (Object.keys(obj).includes('newZoomLevel')) {
      refreshTodayMarker.current?.();
    }

    setGanttState(newState);
    // 只存修改过的状态
    if (!notSave) {
      saveSetting(settingKey, obj);
    }

    if (Object.keys(obj).length > 1) {
      // 同时修改多个属性表示 switch favorite view, 需要调用 gantt 的 api 去一个个应用上去
      setAllGanttColumnState({ gantt: ganttRef.current, columnDefs, ganttState: obj });
    }
  });

  useEffect(() => {
    let gantt;

    loadDHTMLXGantt().then((res) => {
      gantt = res.Gantt.getGanttInstance();

      setGanttConfig({
        isInDesign,
        gantt,
        columnDefs,
        openDialog,
        modal,
        ganttConfig,
        dataSources,
        mapping,
        relation,
        deletePromisesRef,
        context,
        readonly,
        ganttStateRef,
        onGanttStateChange: handleGanttStateChange,
        isUnMount,
      });

      if (eleRef.current) {
        gantt.init(eleRef.current);
        ganttRef.current = gantt;
        setGanttLoaded(true); // 刷新 toolbar 以及加载数据 effect
        formApi.asyncComponentManager.setReady(idToRegister);
      }
    });

    return () => {
      ganttRef.current = null;
      gantt?.destructor();
    };
  }, [
    isInDesign,
    columnDefs,
    context,
    dataSources,
    formApi,
    ganttConfig,
    idToRegister,
    mapping,
    modal,
    openDialog,
    relation,
    readonly,
    handleGanttStateChange,
  ]);

  useEffect(() => {
    if (ganttData && ganttRef.current) {
      ganttRef.current.clearAll();
      // clearAll 会同时删除 marker，所以不能在 setGanttConfig 里初始化 marker，等每次数据变化过后重新添加
      if (ganttData.data?.length) {
        refreshTodayMarker.current = addTodayMarker(ganttRef.current);
      }
      ganttRef.current.parse(ganttData);

      // 防止初始化设置 data 触发的 scroll 事件修改 scrollLeft 等。
      setTimeout(() => {
        readyToSaveSetting.current = true;
      }, 1000);
    }
  }, [ganttData, ganttLoaded]);

  useEffect(() => {
    if (Array.isArray(calendarData) && ganttRef.current) {
      const calendarConfig = currentCalendar
        ? calendarData.find((item) => item.id === currentCalendar)
        : calendarData[0];
      if (calendarConfig) {
        setGanttState((oldState) => ({
          ...oldState,
          currentCalendar: calendarConfig.id,
        }));
        setGanttCalendar({ gantt: ganttRef.current, calendarConfig });
      }
    }
  }, [ganttLoaded, currentCalendar, calendarData]);

  const handleFuzzySearch = (val) => {
    setSearchText(val);
    updateSearchParam(searchKey, val);
  };

  if (relation.colId && !relationValue) {
    throw new Error(
      `${t('gantt.invalid-relation', { valueConfig: relationProp.value })}. ${JSON.stringify(relationProp)}`,
    );
  }

  const toolbarAddButton =
    !readonly && dataSources.task?.dataSource ? (
      <ButtonElement
        componentProps={{
          className: 'icp-gantt-default-add-button',
          content: t('add', { ns: 'icp-common' }),
          icon: 'ant:plus-circle-outlined',
          type: 'primary',
          onClick: !isInDesign
            ? () => {
                openDialog({});
              }
            : null,
        }}
      />
    ) : null;
  const cellEditButton =
    !isInDesign && !readonly && dataSources.task?.dataSource && dialogItem ? (
      <ButtonElement
        componentProps={{
          action: {
            type: 'dialog',
            successAction: {
              type: 'refreshData',
              fieldIds: [id],
            },
            openFormProps: {
              formType: 'ENTITY',
              formEntityToken: dataSources.task.dataSource.token,
              formEntityLayoutToken: dataSources.task.dataSource.layoutToken,
              formEntityDataId: dialogItem.id,
              FormRendererProps: {
                defaultData: ':currentData',
              },
            },
            openDialogProps: {
              size: 'md',
              disableUseOpenContentTitle: true,
              title: dialogItem?.id
                ? `${t('edit', { ns: 'icp-common' })} :name`
                : t('gantt.new-sub-task', { ns: 'icp-form-renderer' }),
            },
          },
        }}
        style={{ display: 'none' }}
        ref={dialogBtnRef}
      />
    ) : null;

  const toolbarNoChildren = !toolbarChildrenProp && !toolbarFields?.length;
  const toolbarChildren =
    isInDesign && toolbarNoChildren ? (
      <div
        key="empty"
        data-key-path={(keyPath || []).concat('componentProps', 'toolbarFields')}
        className="form-element toolbar-fields-empty no-children"
      />
    ) : (
      toolbarChildrenProp || (
        <RecursionRenderer
          keyPath={(keyPath || []).concat('componentProps', 'toolbarFields')}
          fields={toolbarFields}
        />
      )
    );

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx('gantt-element form-element', className, classNameComp, {
          'in-design': isInDesign,
        })}
        style={{ ...style, ...componentStyle }}
        ref={nodeRef}
      >
        <GanttToolbar
          isInDesign={isInDesign}
          gantt={ganttRef.current}
          ganttConfig={ganttConfig}
          columnDefs={columnDefs}
          pinnedFilter={pinnedFilter}
          defaultGanttState={defaultGanttState}
          ganttState={ganttState}
          searchText={searchText}
          onRefresh={handleRefresh}
          onGanttStateChange={handleGanttStateChange}
          onSearch={handleFuzzySearch}
          suppressExpandAll={!mapping.parent}
          calendarData={calendarData}
          settingKey={settingKey}
        >
          {toolbarChildren}
          {toolbarAddButton}
        </GanttToolbar>
        <div style={{ position: 'relative', height: '100%', flex: 1, minHeight: 0 }}>
          <div style={{ height: '100%' }} ref={eleRef} />
          {loading || dataLoading ? <Loading delayed={false} overlay={true} /> : null}
          {error ? (
            <div className="icp-loading-overlay icp-center" style={{ color: 'var(--error-color)' }}>
              {error.message ?? `${error.error}`}
            </div>
          ) : null}
        </div>
        <CurrentDataProvider
          value={{
            ...dialogItem,
            [relation.colId]: relation.value,
          }}
        >
          {cellEditButton}
        </CurrentDataProvider>
        {contextHolder}
      </div>
    </ElementDecorator>
  );
});

GanttElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  readonly: PropTypes.bool,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 表格的列的定义，延用 ag-grid 的 columnDefs 设置
     */
    columnDefs: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        // all other ag-grid column def properties
      }),
    ),
    /**
     * 数据源
     */
    dataSources: PropTypes.shape({
      task: PropTypes.shape(SingleDataSourceTypes),
      link: PropTypes.shape(SingleDataSourceTypes),
      calendar: PropTypes.shape(SingleDataSourceTypes),
    }),
    /**
     * Gantt 数据作为表单子表的关系配置
     */
    relation: PropTypes.shape({
      colId: PropTypes.string,
      value: PropTypes.string,
    }),
    /**
     * 数据映射
     */
    mapping: PropTypes.shape({
      text: PropTypes.string,
      start_date: PropTypes.string,
      end_date: PropTypes.string,
      duration: PropTypes.string,
      progress: PropTypes.string,
      parent: PropTypes.string,
      type: PropTypes.string,
    }),
    /**
     * dhtmlx gantt 组件的配置
     * https://docs.dhtmlx.com/gantt/api__refs__gantt_props.html
     */
    ganttConfig: PropTypes.shape({}),
    /**
     * 工具栏的自定义元素
     */
    toolbarFields: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * 使用 jsx 的工具栏自定义元素
     */
    toolbarChildren: PropTypes.node,
    /**
     * 默认显示在 Filter Panel 的 filter 列，无法被删除
     */
    pinnedFilter: PropTypes.arrayOf(PropTypes.string),
    /**
     * 保存设置的 key，默认使用 id
     */
    settingKey: PropTypes.string,
    /**
     * 表格默认的 filter 条件，可以通过界面进行改变
     */
    defaultFilterModel: DataFiltersType,
    /**
     * 不保存用户调整的组件设置
     */
    suppressSaveSetting: PropTypes.bool,
  }),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
GanttElement.displayName = 'Gantt';

export default withFieldWrapper(GanttElement, { ns: 'icp-vendor-dhtmlx-gantt' });
