export function saveSetting(key, obj) {
  if (!key) return;
  // obj 不是完整的 state 对象，只是部分属性。先加载已经存下来的，再增量的存 obj 里的属性，这样实现只存用户修改过的属性。
  const settings = loadSetting(key);
  const newSettings = { ...settings, ...obj };
  localStorage.setItem(key, JSON.stringify(newSettings));
}

export function loadSetting(key) {
  if (!key) return {};
  return JSON.parse(localStorage.getItem(key)) || {};
}

export function clearSetting(key) {
  if (!key) return;
  const { favoriteView } = loadSetting(key);
  if (favoriteView) {
    localStorage.setItem(key, JSON.stringify({ favoriteView }));
  } else {
    localStorage.removeItem(key);
  }
}
