import PropTypes from 'prop-types';
import Tabs from '../Tabs';
import Tab from '../Tabs/Tab';
import Icon from '../Icon';

const iconMap = {
  Table: <Icon name="component-Table" size={16} />,
  Gantt: <Icon name="material:view-timeline-outlined" size={18} />,
};

function CombinedViewSwitch(props) {
  const { components, currentView, setCurrentView } = props;

  return (
    <Tabs value={currentView} onChange={setCurrentView} style={{ borderBottom: 0 }}>
      {components.map((component) => {
        return (
          <Tab key={component} className="icp-combined-view-tab" value={component}>
            {iconMap[component]}
            {component}
          </Tab>
        );
      })}
    </Tabs>
  );
}

CombinedViewSwitch.propTypes = {
  components: PropTypes.arrayOf(PropTypes.string),
  currentView: PropTypes.string,
  setCurrentView: PropTypes.func,
};

export default CombinedViewSwitch;
