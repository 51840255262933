import PropTypes from 'prop-types';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { AgTable } from '@icp/components';
import { message, restApi, shouldTranslateByDefault } from '@icp/settings';
import { useEventCallback } from '@icp/hooks';
import {
  toMap,
  escapeFilename,
  escapeExcelFormulaInjection,
  EvalWorker,
  getSearchParam,
  randomNumber,
  composeEvent,
  loadXlsx,
} from '@icp/utils';
import { pick, mapKeys, mapValues } from 'lodash-es';
import { resolveUrl, selectContext, selectValues } from '@icp/form-renderer-core';
import { translateEntireObj } from '@icp/i18n';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  useComponentLibrary,
  useElementDecorator,
  useFormApi,
  useIsInDesign,
} from '../../FormRenderCtx';
import {
  DEFAULT_INTERVAL_TIME,
  DEFAULT_PAGE_SIZE,
  DEFAULT_COL_DEF,
  AUTO_INDEX_COLUMN,
} from './constant';
import COLUMN_TYPES from './columnTypes';
import useInterval from './useInterval';
import { ConditionalPropertyPropType, DataFiltersType } from '../../propTypes';
import { cellRenderMapping } from './cellRenders';
import { cellEditorMapping } from './cellEditors';
import {
  useClassName,
  useDataFilters,
  useDisplayValue,
  useVariablePattern,
  useConditionalProperty,
  useHref,
  useConditionalPropertyForItemOfArray,
  useDataUrl,
} from '../../hooks';
import { useStore } from '../../store';
import { withFieldWrapper } from '../../fieldWrapper';
import {
  extractSortModel,
  formatColumnDefs,
  getDisplayTextFunc,
  getExportableColumns,
  getSelectedIds,
  makeSettingKey,
  resolveDefaultColumnDefs,
} from './utils';
import RecursionRenderer from '../../RecursionRenderer';
import EnumLoader from './cellRenders/EnumLoader';
import { useCurrentData } from '../../currentDataCtx';
import { TableContextProvider } from './tableContextCtx';
import { useCombinedView } from '../../combinedViewCtx';

const components = { ...cellRenderMapping, ...cellEditorMapping };

const TableElement = forwardRef(function TableElement(props, ref) {
  const {
    noElementDecorator,
    keyPath,
    id,
    valueField,
    className: classNameProp,
    style,
    componentProps = {},
  } = props;

  const {
    tableContext,
    defaultColDef: defaultColDefProp,
    columnDefs: columnDefsProp,
    rowData: rowDataProp,
    rowModelType = 'serverSide',
    dataSource,
    dataUrl: dataUrlProp,
    pinnedTopDataUrl: pinnedTopDataUrlProp,
    pinnedBottomDataUrl: pinnedBottomDataUrlProp,
    translateDataResponse = shouldTranslateByDefault(),
    transformDataResponse,
    dataFilters,
    selectColId,
    defaultFilterModel: defaultFilterModelProp,
    suppressStatusbar: suppressStatusbarProp = false,
    suppressToolbar: suppressToolbarProp = false,
    suppressToolbarActions: suppressToolbarActionsProp = false,
    suppressAddButton: suppressAddButtonProp = false,
    // delete api 暂时还有问题，应该没有项目在实用，并且删除应该不是一个常用功能，默认禁止掉，或者其实是不是应该换个名字叫 enableDeleteButton
    suppressDeleteButton: suppressDeleteButtonProp = true,
    suppressFuzzySearch: suppressFuzzySearchProp = false,
    suppressFuzzySearchSpeech: suppressFuzzySearchSpeechProp = false,
    suppressColumnSelect: suppressColumnSelectProp = false,
    suppressExcelExport: suppressExcelExportProp = false,
    suppressToolbarRefresh: suppressToolbarRefreshProp = false,
    suppressTableSetting: suppressTableSettingProp = false,
    suppressFullscreen: suppressFullscreenProp = false,
    suppressSaveSetting: suppressSaveSettingProp = false,
    supportShowDeleted: supportShowDeletedProp = false,
    suppressFilterPanel: suppressFilterPanelProp = false,
    suppressFavoriteView: suppressFavoriteViewProp = false,
    suppressRefreshDataWhenFilterChange = false,
    settingKey: settingKeyProp,
    tableSize,
    addButtonHref: addButtonHrefProp,
    addButtonContent,
    deleteButtonUrl,
    fuzzySearchPlaceholder,
    fuzzySearchOpen = false,
    pagination = false,
    paginationPageSize = DEFAULT_PAGE_SIZE,
    pinnedFilter,
    interval = false,
    intervalTime = DEFAULT_INTERVAL_TIME,
    excelExportMaxSizePerReq: maxSizePerReq = 1000,
    excelExportFilename,
    getContextMenuItems: getContextMenuItemsProp,
    style: componentStyle,
    toolbarFields,
    toolbarItems, // @deprecated, renamed to toolbarChildren
    toolbarChildren: toolbarChildrenProp,
    onGridReady,
    onRefresh,
    // toolbarProps,
    ...otherComponentProps
  } = componentProps;

  if (process.env.NODE_ENV === 'development') {
    if (toolbarItems) {
      console.warn(`'toolbarItems' is deprecated, use 'toolbarChildren' instead`);
    }
  }

  const routerParams = useParams();
  const store = useStore();
  const { t } = useTranslation(['icp-form-renderer', 'icp-components', 'icp-common']);
  const ElementDecorator = useElementDecorator(noElementDecorator);
  const isInDesign = useIsInDesign();
  const context = selectContext(store.getState());
  const currentData = useCurrentData();
  const formApi = useFormApi();
  const componentLibrary = useComponentLibrary();
  const combinedView = useCombinedView();

  const [pinnedTopRowData, setPinnedTopRowData] = useState(null);
  const [pinnedBottomRowData, setPinnedBottomRowData] = useState(null);
  const gridRef = useRef();
  const totalRowCount = useRef(0);

  useInterval({ gridRef, interval, intervalTime });

  const [rowDataFetched, setRowDataFetched] = useState(null);
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const columnDefs = formatColumnDefs(useConditionalPropertyForItemOfArray(columnDefsProp, 'hide'));
  const rowData =
    useDisplayValue(id, valueField) || rowDataProp || rowDataFetched || (isInDesign ? [] : null);

  const dataUrl = useDataUrl({ dataUrlProp, dataSource });
  const pinnedTopDataUrl = useVariablePattern(pinnedTopDataUrlProp);
  const pinnedBottomDataUrl = useVariablePattern(pinnedBottomDataUrlProp);

  const outerFilterModel = useDataFilters(dataFilters);
  const defaultFilterModel = useDataFilters(defaultFilterModelProp);

  const suppressStatusbar = useConditionalProperty(suppressStatusbarProp);
  const suppressToolbar = useConditionalProperty(suppressToolbarProp);
  const suppressToolbarActions = useConditionalProperty(suppressToolbarActionsProp);
  const suppressAddButton = useConditionalProperty(suppressAddButtonProp);
  const suppressDeleteButton = useConditionalProperty(suppressDeleteButtonProp);
  const suppressFuzzySearch = useConditionalProperty(suppressFuzzySearchProp);
  const suppressFuzzySearchSpeech = useConditionalProperty(suppressFuzzySearchSpeechProp);
  const suppressColumnSelect = useConditionalProperty(suppressColumnSelectProp);
  const suppressExcelExport = useConditionalProperty(suppressExcelExportProp);
  const suppressToolbarRefresh = useConditionalProperty(suppressToolbarRefreshProp);
  const suppressTableSetting = useConditionalProperty(suppressTableSettingProp);
  const suppressFullscreen = useConditionalProperty(suppressFullscreenProp);
  const suppressSaveSetting = useConditionalProperty(suppressSaveSettingProp);
  const supportShowDeleted = useConditionalProperty(supportShowDeletedProp);
  const suppressFilterPanel = useConditionalProperty(suppressFilterPanelProp);
  const suppressFavoriteView = useConditionalProperty(suppressFavoriteViewProp);
  const addButtonHref = useHref({ href: addButtonHrefProp });

  const settingKey = useMemo(() => {
    return settingKeyProp || makeSettingKey(id, context, 'table');
  }, [context, id, settingKeyProp]);

  const getPinnedTopRows = useEventCallback((config) => {
    if (!pinnedTopDataUrl) return Promise.resolve(null);
    setPinnedTopRowData(null);
    // currently pinned row data no filter, no sort
    return restApi.get(pinnedTopDataUrl, config).then(setPinnedTopRowData);
  });

  const getPinnedBottomRows = useEventCallback((config) => {
    if (!pinnedBottomDataUrl) return Promise.resolve(null);
    // currently pinned row data no filter, no sort
    setPinnedBottomRowData(null);
    return restApi.get(pinnedBottomDataUrl, config).then(setPinnedBottomRowData);
  });

  const getRows = useEventCallback((request, otherConfig) => {
    // startRow === 0 表示表格第一次 load 数据或者刷新数据
    let filterModel = request.filterModel;
    if (Array.isArray(outerFilterModel) && outerFilterModel.length) {
      filterModel = (filterModel || []).concat(outerFilterModel);
    }
    const payloadStr = JSON.stringify({ ...request, filterModel, selectColId });
    const config = {
      params: {
        payload: payloadStr && payloadStr !== '{}' ? payloadStr : undefined,
        include_deleted: getSearchParam('include_deleted'),
      },
      ...otherConfig,
    };
    const isFresh = request.startRow === 0;
    const pinnedTopRequest = isFresh ? getPinnedTopRows(config) : Promise.resolve(null);
    const pinnedBottomRequest = isFresh ? getPinnedBottomRows(config) : Promise.resolve(null);
    // dataUrl 可能会因为依赖变量导致 useDataUrl 解析失败返回 null，此时就显示个 no data found 比较合理。
    const rowDataRequest = !dataUrl
      ? Promise.resolve({ rowData: [], rowCount: 0 })
      : restApi.get(dataUrl, config).then(async (res) => {
          let results;
          let count;
          if (Array.isArray(res)) {
            // 支持一下 form 以外的 api，没做分页以及 lazy loading，直接返回整个 data array
            results = res;
            count = results.length;
          } else {
            results = res.results;
            count = res.count;
          }
          totalRowCount.current = count;
          if (transformDataResponse) {
            try {
              results = await EvalWorker.shared().execEval(results, transformDataResponse, {
                params: {
                  context: store.getState().context,
                  formData: selectValues(store.getState()),
                },
              });
            } catch (err) {
              console.error(err);
              throw err;
            }
          }
          if (translateDataResponse) {
            translateEntireObj(results);
          }
          return { rowData: results, rowCount: count };
        });

    // 等 pinned data 和普通 row data 都加载好了再 resolve，因为 pinned data 行没有单独的 loading
    return Promise.all([pinnedTopRequest, pinnedBottomRequest, rowDataRequest]).then(
      (resArr) => resArr[2],
    );
  });

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;
    // 当 clientSide 并且 props 没有传 rowData 还有 dataUrl 的时候，意味着 json 里配置一口气拿所有数据不 lazy loading
    if (!isInDesign && rowModelType === 'clientSide' && dataUrl && !rowDataProp) {
      getRows({}, { signal }).then((resData) => setRowDataFetched(resData.rowData));
    }
    return () => {
      controller.abort();
    };
  }, [rowModelType, dataUrl, getRows, rowDataProp, outerFilterModel, isInDesign]);

  useEffect(() => {
    if (!isInDesign && rowModelType === 'serverSide' && gridRef.current?.api) {
      gridRef.current.api.hideOverlay();
      gridRef.current.api.refreshServerSide({ purge: true });
    }
  }, [outerFilterModel, dataUrl, isInDesign, rowModelType]);

  const defaultColDef = useMemo(
    () => {
      return {
        ...DEFAULT_COL_DEF,
        ...resolveDefaultColumnDefs(defaultColDefProp, currentData, store, routerParams),
      };
    },
    // defaultColDef 不做更新功能
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [gridContext] = useState(() => {
    const ctx = {
      store: store.getState(),
    };

    // 每个TableElement独立EnumLoader
    ctx[Symbol.for('EnumLoader')] = new EnumLoader();

    return ctx;
  });
  const internalCtx = useMemo(() => {
    return {
      ...gridContext,
      ...otherComponentProps.context,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherComponentProps.context]);

  // 不响应 id 的变化
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const idToRegister = useMemo(() => id || `random-table-id-${randomNumber(100000)}`, []);
  useEffect(() => {
    formApi.asyncComponentManager.register(idToRegister);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGridReady = () => {
    setTimeout(() => {
      formApi.asyncComponentManager.setReady(idToRegister);
    }, 16);
  };

  const handleExportAll = () => {
    const sortModel = extractSortModel(gridRef.current.api.getColumnState());
    const filterModel = gridRef.current.getTableState().filterModel;
    const searchText = gridRef.current.getSearchText();
    const colsToExport = getExportableColumns(gridRef);

    const destroyLoadingMsg = message.loading(t('message.exporting'), 0);

    // totalRowCount.current 存的是 table 有 filter 过后的总数，不能用，单独发个请求拿一下。
    getRows({ startRow: 0, endRow: 1, sortModel, filterModel, searchText }).then(({ rowCount }) => {
      const reqCount = Math.ceil(rowCount / maxSizePerReq);
      const payloadList = new Array(reqCount).fill(0).map((_, i, arr) => {
        const startRow = i * maxSizePerReq;
        const endRow = i === arr.length - 1 ? rowCount : startRow + maxSizePerReq;
        return {
          startRow,
          endRow,
          sortModel,
          filterModel,
          searchText,
        };
      });
      Promise.all(payloadList.map((p) => getRows(p).then((res) => res.rowData)))
        // 拍平数据数组
        .then((dataList) => dataList.flatMap((x) => x))
        // 转换格式 过滤字段
        .then((data) => {
          const colIds = colsToExport.map((x) => x.colId);

          // 前端自动计算显示的 row index，data 里没有
          const hasAutoIndexCol = !!colsToExport.find(
            (colDef) => colDef.type === AUTO_INDEX_COLUMN,
          );
          if (hasAutoIndexCol) {
            data.forEach((item, index) => {
              item[AUTO_INDEX_COLUMN] = index + 1;
            });
          }

          const fieldNameMap = toMap(
            (x) => x.colId,
            (x) => x.headerName, // TODO 没用headerName而是用headerValueGetter表达式的
          )(colsToExport);

          const colHeaders = colIds.map((x) => fieldNameMap[x]);

          const valueTransformerMap = toMap(
            (colDef) => colDef.colId,
            (colDef) => getDisplayTextFunc(colDef, gridContext),
          )(colsToExport);

          const emptyItem = Object.fromEntries(colIds.map((k) => [k, null]));
          // TODO 单元格正确显示Link、图片

          return [
            data.map((item) =>
              // key转换成name
              mapKeys(
                // value转换: 日期转Date对象
                mapValues(
                  // 过滤字段
                  { ...emptyItem, ...pick(item, colIds) },
                  (v, k, o) => {
                    const originalCellValue = valueTransformerMap[k](v, o);
                    const escapedCellValue = escapeExcelFormulaInjection(originalCellValue);
                    return escapedCellValue;
                  },
                ),
                (_, key) => fieldNameMap[key],
              ),
            ),
            colHeaders,
          ];
        })
        // 导出
        .then(([data, colHeaders]) => {
          return loadXlsx().then((XLSX) => {
            const worksheet = XLSX.utils.json_to_sheet(data, { header: colHeaders });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet);
            XLSX.writeFile(workbook, `${escapeFilename(excelExportFilename) || 'export'}.xlsx`, {
              bookType: 'xlsx',
              compression: true,
            });
          });
        })
        .catch((e) => {
          console.error(e);
          message.error(t('error.export'));
        })
        .finally(() => {
          destroyLoadingMsg();
        });
    });
  };

  const handleExcelExport = () => {
    if (rowModelType !== 'serverSide') {
      gridRef.current?.api?.exportDataAsExcel({
        columnKeys: columnDefs
          .filter(
            (colDef) => colDef.colId !== 'auto-checkbox-col' && colDef.type !== 'ACTION_COLUMN',
          )
          .map((colDef) => colDef.colId),
      });
    } else {
      handleExportAll();
    }
  };

  const handleClientSideRefresh = () => {
    if (rowModelType === 'clientSide' && dataUrl && !rowDataProp) {
      gridRef.current.api.showLoadingOverlay();
      return getRows({}).then((resData) => {
        setRowDataFetched(resData.rowData);
        gridRef.current.api.hideOverlay();
      });
    }
    return Promise.resolve();
  };

  // toolbar 的删除功能，要求没行的 rowData 必须要有 id
  // 单行删除可以正常 resolve url pattern，多行删除只会把 url 里的 :ids 替换成 selectedIds
  const handleDeleteRow = (selectedIds) => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    let urlResolved;
    if (componentProps.rowSelection === 'single') {
      urlResolved = resolveUrl({
        url: deleteButtonUrl,
        store,
        currentData: selectedRows[0],
        params: routerParams,
      })[0];
    } else if (componentProps.rowSelection === 'multiple') {
      urlResolved = resolveUrl({
        url: deleteButtonUrl,
        store,
        currentData: { ids: String(selectedIds) },
        params: routerParams,
      })[0];
    }

    return restApi.delete(urlResolved).then((res) => {
      gridRef.current.refresh();
      return res;
    });
  };

  const getContextMenuItems = (params) => {
    let menus = params.defaultItems || [];

    if (getContextMenuItemsProp) {
      menus = getContextMenuItemsProp({ ...params, defaultItems: menus });
    }

    if (rowModelType === 'serverSide') {
      menus = menus.filter((x) => x !== 'export');

      if (!suppressExcelExport) {
        const exportExcelSSRM = {
          name: t('toolbar.export-excel', { ns: 'icp-components' }),
          icon: `<span class="ag-icon ag-icon-excel" unselectable="on" role="presentation"></span>`,
          action: handleExportAll,
        };
        menus.push(exportExcelSSRM);
      }
    }

    if (menus[menus.length - 1] === 'separator') {
      menus.pop();
    }

    return menus;
  };

  const tableApi = useMemo(() => {
    return {
      get node() {
        return gridRef.current.node;
      },
      get gridApi() {
        return gridRef.current.api;
      },
      getTableState: () => gridRef.current.getTableState(),
      getSearchText: () => gridRef.current.getSearchText(),
      getSelectedIds: () => getSelectedIds(gridRef.current.api),
      refresh: () => gridRef.current.refresh(),
    };
  }, []);

  useImperativeHandle(ref, () => tableApi, [tableApi]);

  const toolbarNoChildren = !toolbarChildrenProp && !toolbarItems && !toolbarFields?.length;
  const toolbarChildren =
    isInDesign && toolbarNoChildren ? (
      <div
        key="empty"
        data-key-path={(keyPath || []).concat('componentProps', 'toolbarFields')}
        className="form-element toolbar-fields-empty no-children"
      />
    ) : (
      toolbarChildrenProp ||
      toolbarItems || (
        <RecursionRenderer
          keyPath={(keyPath || []).concat('componentProps', 'toolbarFields')}
          fields={toolbarFields}
        />
      )
    );

  // EditableTable 会传 tableContext 来覆盖默认的 tableApi
  return (
    <TableContextProvider value={tableContext || tableApi}>
      <ElementDecorator keyPath={keyPath} id={id}>
        <AgTable
          // AgTable 里用了 statusBar 来实现分页的显示。当 AgReact 不会相应 statusBar 的刷新，当在 designer 里改变的没有效果，只能加个 key 来完全重新渲染 table
          key={
            isInDesign ? `${String(pagination)}${componentProps.treeData}${tableSize}` : undefined
          }
          pinnedTopRowData={pinnedTopRowData}
          pinnedBottomRowData={pinnedBottomRowData}
          suppressMovableColumns={isInDesign}
          getRowId={
            Array.isArray(rowData) && !rowData.id ? undefined : (params) => String(params.data.id)
          }
          suppressColumnVirtualisation={isInDesign}
          {...otherComponentProps}
          isInDesign={isInDesign}
          componentLibrary={componentLibrary}
          id={id}
          className={clsx('table-element form-element', className, classNameComp, {
            'in-design': isInDesign,
          })}
          style={{ ...style, ...componentStyle }}
          columnTypes={COLUMN_TYPES}
          components={components}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowData={rowData}
          rowModelType={rowModelType}
          getRows={getRows}
          defaultFilterModel={defaultFilterModel}
          pagination={pagination}
          paginationPageSize={paginationPageSize}
          pinnedFilter={pinnedFilter}
          getContextMenuItems={otherComponentProps.getContextMenuItems || getContextMenuItems}
          suppressStatusbar={suppressStatusbar}
          suppressToolbar={suppressToolbar}
          suppressToolbarActions={suppressToolbarActions}
          suppressAddButton={suppressAddButton}
          suppressDeleteButton={suppressDeleteButton}
          suppressFuzzySearch={suppressFuzzySearch}
          suppressFuzzySearchSpeech={suppressFuzzySearchSpeech}
          suppressColumnSelect={suppressColumnSelect}
          suppressExcelExport={suppressExcelExport}
          suppressToolbarRefresh={suppressToolbarRefresh}
          suppressTableSetting={suppressTableSetting}
          suppressFullscreen={suppressFullscreen}
          suppressSaveSetting={suppressSaveSetting}
          supportShowDeleted={supportShowDeleted}
          suppressFilterPanel={suppressFilterPanel}
          suppressFavoriteView={suppressFavoriteView}
          suppressRefreshDataWhenFilterChange={suppressRefreshDataWhenFilterChange}
          settingKey={settingKey}
          tableSize={tableSize}
          addButtonHref={addButtonHref}
          addButtonContent={addButtonContent}
          fuzzySearchPlaceholder={fuzzySearchPlaceholder}
          fuzzySearchOpen={fuzzySearchOpen}
          toolbarChildren={toolbarChildren}
          context={internalCtx}
          getDataPath={(data) => data.treeDataKeyPath}
          onGridReady={composeEvent(handleGridReady, onGridReady)}
          onToolbarExportExcel={handleExcelExport}
          onRefresh={rowModelType === 'clientSide' ? handleClientSideRefresh : undefined}
          onDeleteRow={handleDeleteRow}
          defaultExcelExportParams={{
            processCellCallback: (params) => {
              const { colDef } = params.column;
              const formatter = getDisplayTextFunc(colDef, gridContext);
              return formatter(params.value);
            },
          }}
          combinedView={combinedView}
          ref={gridRef}
        />
      </ElementDecorator>
    </TableContextProvider>
  );
});

TableElement.propTypes = {
  noElementDecorator: PropTypes.bool,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  valueField: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * ag-grid 默认列的属性
     */
    defaultColDef: PropTypes.shape({}),
    /**
     * 表格的列的定义，支持 ag-grid 所有的 columnDefs
     */
    columnDefs: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        // all other ag-grid column def properties
      }),
    ),
    /**
     * 通过 `dataUrl` 按照 ag-grid `serverSide` 模式获取数据
     */
    dataUrl: PropTypes.string,
    /**
     * 固定到表格顶部的行数据
     */
    pinnedTopDataUrl: PropTypes.string,
    /**
     * 固定到表格底部的行数据
     */
    pinnedBottomDataUrl: PropTypes.string,
    /**
     * 转换从 `dataUrl` 获取到的数据，使用 eval 表达式，this 指向获取到的数据
     */
    transformDataResponse: PropTypes.string,
    /**
     * 是否需要翻译请求结果
     * @default false
     */
    translateDataResponse: PropTypes.bool,
    /**
     * 也可以指定 formEntity 的数据源作为 data source
     */
    dataSource: PropTypes.shape({
      listUrl: PropTypes.string,
      token: PropTypes.string,
    }),
    /**
     * 请求数据源隐藏的固定 filter 条件，无法通过界面进行改变
     */
    dataFilters: DataFiltersType,
    /**
     * 表格默认的 filter 条件，可以通过界面进行改变
     */
    defaultFilterModel: DataFiltersType,
    /**
     * 是否禁止 Toolbar
     * @default false
     */
    suppressToolbar: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止 Toolbar 的所有操作按钮
     * @default false
     */
    suppressToolbarActions: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止 "新增" 按钮
     * @default false
     */
    suppressAddButton: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止模糊搜索
     * @default false
     */
    suppressFuzzySearch: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止模糊搜索的语音搜索功能
     * @default false
     */
    suppressFuzzySearchSpeech: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止 "显示/隐藏" 列
     * @default false
     */
    suppressColumnSelect: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止导出 Excel 的功能
     * @default false
     */
    suppressExcelExport: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止工具栏的 "刷新" 按钮
     * @default false
     */
    suppressToolbarRefresh: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止设置功能
     * @default false
     */
    suppressTableSetting: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止全屏功能
     * @default false
     */
    suppressFullscreen: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止保存表格设置
     * @default false
     */
    suppressSaveSetting: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止工具栏的 Filter 面板
     */
    suppressFilterPanel: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止保存多种 View
     */
    suppressFavoriteView: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否支持 "显示已删除" 功能
     * @default false
     */
    supportShowDeleted: ConditionalPropertyPropType(PropTypes.bool),
    /**
     * 是否禁止在 Filter Panel 里修改 filter 条件后自动刷新数据
     * @default false
     */
    suppressRefreshDataWhenFilterChange: PropTypes.bool,
    /**
     * 表格设置保存的 key，默认使用 id
     */
    settingKey: PropTypes.string,
    /**
     * 表格的尺寸，默认或者紧凑模式
     * @default 'default'
     */
    tableSize: PropTypes.oneOf(['default', 'small']),
    /**
     * 新增按钮的链接地址
     */
    addButtonHref: ConditionalPropertyPropType(PropTypes.string),
    /**
     * 新增按钮的文字
     */
    addButtonContent: PropTypes.string,
    /**
     * 模糊搜索输入框的 placeholder
     */
    fuzzySearchPlaceholder: PropTypes.string,
    /**
     * 模糊搜索是否永远展开显示
     */
    fuzzySearchOpen: PropTypes.bool,
    /**
     * 导出 Excel 的文件名
     * @default 'export'
     */
    excelExportFilename: PropTypes.string,
    /**
     * 导出 Excel 的单请求行数
     */
    excelExportMaxSizePerReq: PropTypes.number,
    /**
     * 是否使用分页功能
     * @default false
     */
    pagination: PropTypes.bool,
    /**
     * 分页功能每页显示的行数
     * @default 20
     */
    paginationPageSize: PropTypes.number,
    /**
     * 默认显示在 Filter Panel 的 filter 列，无法被删除
     */
    pinnedFilter: PropTypes.arrayOf(PropTypes.string),
    /**
     * 是否使用定期刷新数据功能
     * @default false
     */
    interval: PropTypes.bool,
    /**
     * 表格定期刷新数据的时间间隔（s）
     */
    intervalTime: PropTypes.number,
    /**
     * 工具栏的自定义元素
     */
    toolbarFields: PropTypes.arrayOf(PropTypes.shape({})),
    /**
     * 使用 jsx 的工具栏自定义元素
     */
    toolbarChildren: PropTypes.node,
    // all other ag-grid grid options
  }),
};

// FormControl 里去调用了 getComponentDisplayName 获取组件名字
TableElement.displayName = 'Table';

export default withFieldWrapper(TableElement, { ns: 'icp-vendor-aggrid' });
