import { get } from 'lodash-es';
import { isField } from '@icp/form-schema';
import {
  FIELD_TYPE_COLLAPSE_ITEM,
  FIELD_TYPE_FORM,
  FIELD_TYPE_STEP,
  FIELD_TYPE_TAB,
  FIELD_TYPE_TABLE_COLUMN,
} from '../constant';
import { colIndexToColId } from '../store';

function getBreadcrumb(str) {
  const breadcrumb = [];
  const stack = [];
  const parsMap = {
    '{': '}',
    '[': ']',
  };
  let i = str.length;
  let arrayIndex = -1;
  let indentFlag = false;
  let inValue = false;

  // 获取 "key": 中的 key
  const getObjectKey = () => {
    const keyChars = [];
    let inKey = false;

    while (i > 0) {
      i--;

      const char = str[i];

      if (char === '"') {
        if (!inKey) {
          inKey = true;
        } else {
          break;
        }
      } else if (inKey) {
        keyChars.unshift(char);
      }
    }

    return keyChars.join('');
  };

  while (i >= 0) {
    i--;

    const char = str[i];

    if (char === '{') {
      if (stack.length) {
        const nearest = stack.pop();
        if (nearest && nearest === parsMap[char]) {
          continue;
        }
      } else {
        indentFlag = false;
        arrayIndex++;
      }
    }

    if (char === '[') {
      stack.pop();
      if (arrayIndex >= 0 && !indentFlag && !stack.length) {
        breadcrumb.unshift(arrayIndex);
      }
    }

    if (char === '}' || char === ']') {
      stack.push(char);
    }

    // 如果进入对象或者数组内部，不再处理
    if (stack.length) {
      continue;
    }

    // 进入 string value 不做任何处理，防止 string value 里写的 js 代码里有 { } [ ] 等后续符号
    if (char === '"') {
      if (!inValue) {
        inValue = true;
        continue;
      } else {
        inValue = false;
        continue;
      }
    }

    // 当前是在 string value 里，不做任何处理
    if (inValue) {
      continue;
    }

    if (char === ':') {
      if (!indentFlag) {
        const key = getObjectKey();
        breadcrumb.unshift(key);
        indentFlag = true;
        arrayIndex = -1;
      }
    }

    if (char === ',') {
      if (!indentFlag) {
        arrayIndex++;
      }
    }
  }

  return breadcrumb;
}

function breadcrumbToSelectedField(breadcrumb, schema) {
  let keyPath = breadcrumb.slice();

  const findNearest = () => {
    const lastNumberIndex = keyPath.findLastIndex((v) => typeof v === 'number');

    if (lastNumberIndex === -1) {
      return { type: FIELD_TYPE_FORM, keyPath: [] };
    }

    const isAutoGroupColColumn = keyPath.includes('autoGroupColumnDef');
    keyPath = keyPath.slice(0, lastNumberIndex + 1);
    const secondLastValue = keyPath[lastNumberIndex - 1];
    const lastIndexValue = keyPath[lastNumberIndex];

    if (secondLastValue === 'columnDefs' || isAutoGroupColColumn) {
      if (!isAutoGroupColColumn) {
        keyPath = keyPath.slice(0, lastNumberIndex - 2);
      }
      const field = get(schema, keyPath);

      if (field.component !== 'Table' && field.component !== 'EditableTable') {
        return null;
      }

      const colDef = field.componentProps.columnDefs[lastIndexValue];

      return {
        type: FIELD_TYPE_TABLE_COLUMN,
        keyPath,
        colId: colDef.colId || colIndexToColId(field, lastIndexValue),
        colIndex: lastIndexValue,
        isEditableTable: field.component === 'EditableTable',
        isAutoGroupColColumn,
      };
    }

    if (secondLastValue === 'items') {
      keyPath = keyPath.slice(0, lastNumberIndex - 2);
      const field = get(schema, keyPath);
      const typeMap = {
        Steps: FIELD_TYPE_STEP,
        Collapse: FIELD_TYPE_COLLAPSE_ITEM,
        Tabs: FIELD_TYPE_TAB,
      };

      return {
        type: typeMap[field.component],
        keyPath,
        itemIndex: lastIndexValue,
      };
    }

    if (keyPath.length === 0) {
      return { type: FIELD_TYPE_FORM, keyPath: [] };
    }

    if (isField(get(schema, keyPath))) {
      return { keyPath };
    }

    // 去掉最后一个 number，防止进入下次循环死循环
    keyPath = keyPath.slice(0, keyPath.length - 1);

    return null;
  };

  while (keyPath.length > 0) {
    const found = findNearest();
    if (found) {
      return found;
    }
  }

  return {};
}

export default function getJSONSelectedField(editor, schema) {
  const position = editor.getPosition();
  const lineContent = editor.getModel().getLineContent(position.lineNumber);
  const range = {
    startLineNumber: 1,
    startColumn: 1,
    endLineNumber: position.lineNumber,
    endColumn: lineContent.length + 1,
  };
  const beforeValue = editor.getModel().getValueInRange(range);
  const breadcrumb = getBreadcrumb(beforeValue);
  return breadcrumbToSelectedField(breadcrumb, schema);
}
