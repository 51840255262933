/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Popover } from '@icp/components';
import ButtonUI from '../ButtonElement/ButtonUI';

function GanttActionCellRenderer(props) {
  const { task, ganttApiRef, deletePromisesRef, mapping } = props;

  const { t } = useTranslation(['icp-form-renderer']);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl;

  return (
    <div className="gantt-action-cell">
      <ButtonUI
        type="text"
        size="small"
        icon="oct:kebab-horizontal"
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.target);
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        className="gantt-action-popover"
        onClose={() => setAnchorEl(null)}
      >
        {mapping.parent ? (
          <ButtonUI
            type="text"
            icon="oct:plus"
            onClick={(event) => {
              event.stopPropagation();
              ganttApiRef.current.openDialog({
                [mapping.parent]: task.id,
              });
              setAnchorEl(null);
            }}
          >
            {t('gantt.new-sub-task', { ns: 'icp-form-renderer' })}
          </ButtonUI>
        ) : null}
        <ButtonUI
          type="text"
          icon="solar:pen-bold-duotone"
          onClick={(event) => {
            event.stopPropagation();
            ganttApiRef.current.openDialog(task._original);
            setAnchorEl(null);
          }}
        >
          {t('edit', { ns: 'icp-common' })}
        </ButtonUI>
        <ButtonUI
          type="text"
          icon="solar:trash-bin-minimalistic-bold-duotone"
          danger={true}
          onClick={(event) => {
            event.stopPropagation();
            ganttApiRef.current.gantt.confirm({
              text: t('action.delete-confirm', { name: task.text }),
              callback: () => {
                // 删除 task 会自动级联删除所有的子 task 和所有相关的 link
                ganttApiRef.current.setLoading(true);
                deletePromisesRef.current = [];
                ganttApiRef.current.gantt.deleteTask(task.id);
                setTimeout(() => {
                  Promise.all([...deletePromisesRef.current]).then(() => {
                    ganttApiRef.current.setLoading(false);
                  });
                }, 16);
              },
            });
            setAnchorEl(null);
          }}
        >
          {t('delete', { ns: 'icp-common' })}
        </ButtonUI>
      </Popover>
    </div>
  );
}

export default GanttActionCellRenderer;
