import { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const CombinedViewCtx = createContext(null);

export function CombinedViewProvider({ children, value }) {
  return <CombinedViewCtx.Provider value={value}>{children}</CombinedViewCtx.Provider>;
}

CombinedViewProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.shape({}),
};

export function useCombinedView() {
  return useContext(CombinedViewCtx);
}
