import { restApi } from '@icp/settings';
import { translateEntireObj } from '@icp/i18n';
import { get } from 'lodash-es';
import { EvalWorker } from '@icp/utils';
import {
  excludeData,
  getRowsFromCachedData,
  logDuplicate,
  resolveIdsToFetch,
  setRowsToCachedData,
} from './utils';

class ACLDataSource {
  constructor(props) {
    this.props = props;

    // serverSide cache, rowId => rows detail information
    this.cachedData = new Map();
    // clientSide all rows
    this.cachedAllRowsData = props.staticAllRowsData || null;

    this.fetchAllRowsPromise = null;
  }

  fetchAllIds(request) {
    const { idListUrl, outerFilterModel, dataExclusion, stringEqual } = this.props;

    let filterModel = request.filterModel;
    if (Array.isArray(outerFilterModel) && outerFilterModel.length) {
      filterModel = (filterModel || []).concat(outerFilterModel);
    }

    return restApi
      .get(idListUrl, {
        params: {
          payload: JSON.stringify({
            filterModel,
            searchText: request.searchText,
            sortModel: request.sortModel,
          }),
        },
      })
      .then((allIds) => {
        allIds = excludeData(allIds, dataExclusion);
        return stringEqual ? allIds.map((id) => String(id)) : allIds;
      });
  }

  fetchRowsByIds(ids) {
    const {
      dataUrl,
      translateDataResponse,
      transformDataResponse,
      selectColId,
      stringEqual,
      mapping,
    } = this.props;

    const idsToFetch = resolveIdsToFetch(this.cachedData, ids);
    const payload = selectColId ? JSON.stringify({ selectColId }) : undefined;
    return Promise.resolve(
      idsToFetch.length
        ? restApi.get(dataUrl, {
            params: {
              ids: String(idsToFetch),
              payload,
            },
          })
        : [],
    ).then(async (rows) => {
      if (translateDataResponse) {
        translateEntireObj(rows);
      }

      if (transformDataResponse) {
        rows = await this.transformData(rows);
      }
      setTimeout(() => {
        logDuplicate(rows, mapping);
      }, 100);

      setRowsToCachedData(this.cachedData, rows, stringEqual, mapping);

      return getRowsFromCachedData(this.cachedData, ids);
    });
  }

  fetchRowsSlice(request) {
    const {
      dataUrl,
      outerFilterModel,
      dataResponseKeyPath,
      dataExclusion,
      mapping,
      translateDataResponse,
      transformDataResponse,
      selectColId,
      stringEqual,
    } = this.props;

    let filterModel = request.filterModel;
    if (Array.isArray(outerFilterModel) && outerFilterModel.length) {
      filterModel = (filterModel || []).concat(outerFilterModel);
    }

    const config = {
      params: {
        payload: JSON.stringify({
          ...request,
          filterModel,
          selectColId,
        }),
      },
    };

    return restApi.get(dataUrl, config).then(async (res) => {
      let rows = dataResponseKeyPath ? get(res, dataResponseKeyPath) : res;
      rows = excludeData(rows, dataExclusion, mapping.value);

      if (transformDataResponse) {
        rows = await this.transformData(rows);
      }

      if (translateDataResponse) {
        translateEntireObj(rows);
      }

      setTimeout(() => {
        logDuplicate(rows, mapping);
      }, 100);

      setRowsToCachedData(this.cachedData, rows, stringEqual, mapping);

      // 使用 ag-grid 的 serverSide 模式必须要返回 count
      return { rowData: rows, rowCount: res.count };
    });
  }

  fetchAllRows() {
    const {
      dataUrl,
      outerFilterModel,
      dataResponseKeyPath,
      dataExclusion,
      mapping,
      translateDataResponse,
      transformDataResponse,
      stringEqual,
    } = this.props;

    if (this.fetchAllRowsPromise) {
      return this.fetchAllRowsPromise;
    }

    if (this.cachedAllRowsData) {
      return Promise.resolve(this.cachedAllRowsData);
    }

    const config =
      Array.isArray(outerFilterModel) && outerFilterModel.length
        ? { params: { payload: JSON.stringify({ filterModel: outerFilterModel }) } }
        : null;

    this.fetchAllRowsPromise = restApi.get(dataUrl, config).then(async (res) => {
      let rows = dataResponseKeyPath ? get(res, dataResponseKeyPath) : res;
      rows = excludeData(rows, dataExclusion, mapping.value);

      if (transformDataResponse) {
        rows = await this.transformData(rows);
      }

      if (translateDataResponse) {
        translateEntireObj(rows);
      }

      this.cachedAllRowsData = stringEqual
        ? rows.map((item) => {
            // api response, mutable change doesn't matter
            if (item[mapping.value] !== undefined && item[mapping.value] !== null) {
              item[mapping.value] = String(item[mapping.value]);
            }
            return item;
          })
        : rows;

      setTimeout(() => {
        logDuplicate(rows, mapping);
      }, 100);

      return this.cachedAllRowsData;
    });

    return this.fetchAllRowsPromise;
  }

  async transformData(originalData) {
    const { transformDataResponse } = this.props;
    let results = originalData;
    if (transformDataResponse) {
      try {
        results = await EvalWorker.shared().execEval(originalData, transformDataResponse);
      } catch (err) {
        console.error(err);
        throw err;
      }
    }
    return results;
  }
}

export default ACLDataSource;
