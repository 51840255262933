import { ELEMENT_ENUMS, INPUT_ELEMENT_ENUM, LAYOUTS_ENUMS, WRAPPERS_ENUMS } from './constants';

export function isLayout(info) {
  return LAYOUTS_ENUMS.includes(info?.component);
}

export function isElement(info) {
  return ELEMENT_ENUMS.includes(info?.component);
}

export function isWrapper(info) {
  return WRAPPERS_ENUMS.includes(info?.component);
}

export function isInputField(info) {
  return INPUT_ELEMENT_ENUM.includes(info?.component);
}

export function isField(info) {
  return isLayout(info) || isElement(info) || isWrapper(info);
}

export function forEachInputFieldInSchema(schema, cb, ignoreEmptyIdFields = true) {
  forEachFieldInSchema(schema, (field, keyPath) => {
    // 没有 id 的 input field 没有意义，不调用 cb
    if (isInputField(field) && (field.id || !ignoreEmptyIdFields)) {
      return cb(field, keyPath);
    }
    return null;
  });
}

// cb 返回真值表示停止遍历
export function forEachFieldInSchema(schema, cb) {
  function dfsToLegacyList(field, keyPath) {
    const { itemField } = field.componentProps || {};

    if (!itemField || !isField(itemField)) {
      return null;
    }

    keyPath = keyPath.concat('componentProps', 'itemField');

    const result = cb(itemField, keyPath);
    if (result) {
      return result;
    }

    return dfs(itemField.fields, keyPath.concat(['fields']));
  }

  function dfsToItems(field, keyPath) {
    if (!fieldHasItemsWithFields(field)) {
      return null;
    }

    keyPath = keyPath.concat('componentProps', 'items');
    for (let itemIndex = 0; itemIndex < field.componentProps.items.length; itemIndex++) {
      const item = field.componentProps.items[itemIndex];

      const result = dfs(item.fields, keyPath.concat(itemIndex, 'fields'));

      if (result) {
        return result;
      }
    }

    return null;
  }

  function dfsToTable(field, keyPath) {
    if (!field.componentProps) {
      return null;
    }

    // table action column
    if (Array.isArray(field.componentProps.columnDefs)) {
      for (let index = 0; index < field.componentProps.columnDefs.length; index++) {
        const colDef = field.componentProps.columnDefs[index];

        const { actions } = colDef.cellRendererParams || {};
        if (colDef.type === 'ACTION_COLUMN' && actions) {
          for (let actionIndex = 0; actionIndex < actions.length; actionIndex++) {
            const action = actions[actionIndex];

            if (action.component === 'Button' || action.component === 'Switch') {
              keyPath = keyPath.concat([
                'componentProps',
                'columnDefs',
                index,
                'cellRendererParams',
                'actions',
                actionIndex,
              ]);

              const result = cb(action, keyPath);
              if (result) {
                return result;
              }
            }
          }
        }
      }
    }

    if (field.componentProps.toolbarFields) {
      const result = dfs(
        field.componentProps.toolbarFields,
        keyPath.concat('componentProps', 'toolbarFields'),
      );
      if (result) {
        return result;
      }
    }

    return null;
  }

  function dfs(fields, keyPath) {
    if (!Array.isArray(fields)) {
      return null;
    }

    for (let index = 0; index < fields.length; index++) {
      const field = fields[index];

      let result = cb(field, keyPath.concat(index));
      if (result) {
        return result;
      }

      // field 不是一个合法对象的时候不继续遍历
      if (!field) {
        continue;
      }

      // field has fields
      result = dfs(field.fields, keyPath.concat(index, 'fields'));
      if (result) {
        return result;
      }

      // field has field in componentProps.items
      result = dfsToItems(field, keyPath.concat(index));
      if (result) {
        return result;
      }

      // Legacy support for List component
      result = dfsToLegacyList(field, keyPath.concat(index));
      if (result) {
        return result;
      }

      // table action buttons
      result = dfsToTable(field, keyPath.concat(index));
      if (result) {
        return result;
      }
    }

    return null;
  }

  dfs(schema?.fields, ['fields']);
}

export function findFieldBy(schema, cb) {
  let result = {};
  forEachFieldInSchema(schema, (field, keyPath) => {
    if (cb(field)) {
      result = { field, keyPath };
      // return true to stop for each
      return true;
    }
    return false;
  });
  return result;
}

export function fieldHasItems(field) {
  return (
    ['Steps', 'Collapse', 'Tabs'].includes(field.component) &&
    Array.isArray(field.componentProps?.items)
  );
}

export function fieldHasItemsWithFields(field) {
  return fieldHasItems(field) && field.component !== 'Steps';
}

export function findSchemaFieldByToken(schema, token) {
  return findFieldBy(schema, (field) => field.id === token && isInputField(field));
}

export function fieldHasContent(field) {
  // Checkbox 也有个 content 属性不知道什么时候加的，不推荐使用不处理。
  return ['Button', 'Text', 'Divider'].includes(field.component) && field.componentProps?.content;
}
