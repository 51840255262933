import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  HELPER_TEXT_ERROR,
  HELPER_TEXT_NORMAL,
  HELPER_TEXT_TYPES,
  HELPER_TEXT_WARNING,
  helpersIsEmpty,
} from '@icp/form-renderer-core';

export default function FormHelperText({ helpers }) {
  const isEmpty = helpersIsEmpty(helpers);
  if (isEmpty) return null;

  const newHelpers = Array.isArray(helpers) ? helpers : [helpers];
  return (
    <div>
      {newHelpers.map((helper, index) => {
        const { status = HELPER_TEXT_NORMAL, text } = helper || {};
        const content = Array.isArray(text) ? text : [text];
        return (
          <div
            className={clsx('helper-text', {
              'helper-text-error': status === HELPER_TEXT_ERROR,
              'helper-text-warning': status === HELPER_TEXT_WARNING,
              'helper-text-normal': status === HELPER_TEXT_NORMAL,
            })}
            key={`${index}-${status}`}
          >
            {content.map((item, i) => {
              // 转一下 string 保证页面不会奔溃。如果字段 id 是纯 number 组成的 string 例如 '123'，yup 验证的时候会无法识别到正确的字段导致 helpers 里的 text 是个 object。
              return <div key={i}>{String(item)}</div>;
            })}
          </div>
        );
      })}
    </div>
  );
}

FormHelperText.propTypes = {
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
};
