import PropTypes from 'prop-types';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { Checkbox, Space } from 'antd';
import {
  selectPermissionsByCategory,
  fetchPermissionList,
  selectContext,
  HELPER_TEXT_TYPES,
  helpersIsEmpty,
} from '@icp/form-renderer-core';
import { resolveVariablePattern } from '@icp/utils';
import { useTranslation } from 'react-i18next';
import { Loading } from '@icp/components';
import { useDispatch, useSelector, useStore } from '../store';
import { useElementDecorator } from '../FormRenderCtx';
import FieldTitle from '../FieldTitle';
import FormHelperText from '../FormHelperText';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { ConditionalPropertyPropType } from '../propTypes';

const PermissionsElement = forwardRef(function PermissionsElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value,
    style,
    disabled,
    readonly,
    componentProps = {},
    fieldTitleProps,
    validation,
    onChange,
    helpers,
  } = props;

  const { style: compStyle, dataUrl: urlPattern, dataResponseKeyPath } = componentProps;

  const { t } = useTranslation(['icp-form-renderer']);
  const ElementDecorator = useElementDecorator();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.permission.isLoading);
  const systemPermissionsByCategory = useSelector(selectPermissionsByCategory);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const permissions = value || [];
  const permissionIds = permissions.map((perm) => perm.id);

  const store = useStore();
  const context = selectContext(store.getState());
  const dataUrl = resolveVariablePattern({ context, pattern: urlPattern });

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  useEffect(() => {
    const p = dispatch(fetchPermissionList({ dataUrl, dataResponseKeyPath }));

    return () => {
      p.abort();
    };
  }, [dispatch, dataUrl, dataResponseKeyPath]);

  const handelAccessChange = (perm) => (event) => {
    const newPermissions = event.target.checked
      ? permissions.concat(perm)
      : permissions.filter((rPerm) => rPerm.id !== perm.id);
    onChange?.(newPermissions);
  };

  const handleCheckAll = (category) => (event) => {
    const checked = event.target.checked;
    const permIds = category.permissions.map((p) => p.id);

    const newPermissions = checked
      ? permissions.concat(category.permissions)
      : permissions.filter((rPerm) => !permIds.includes(rPerm.id));
    onChange?.(newPermissions);
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'input-element',
          'form-element',
          'permissions-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
          classNameComp,
        )}
        style={{ ...style, ...compStyle }}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        {isLoading ? (
          <Loading />
        ) : (
          systemPermissionsByCategory.map((category) => (
            <section key={category.categoryName} className="perm-category-section">
              <div className="section-title">
                <span>{category.categoryName}</span>
                <Checkbox
                  disabled={disabled || readonly}
                  checked={category.permissions.every((perm) => permissionIds.includes(perm.id))}
                  onChange={handleCheckAll(category)}
                >
                  {t('all')}
                </Checkbox>
              </div>
              <div className="form-group">
                <Space size={40} wrap={true}>
                  {category.permissions.map((perm) => (
                    <Checkbox
                      disabled={disabled || readonly}
                      key={perm.id}
                      checked={permissionIds.includes(perm.id)}
                      onChange={handelAccessChange(perm)}
                    >
                      {perm.name}
                    </Checkbox>
                  ))}
                </Space>
              </div>
            </section>
          ))
        )}
        <FormHelperText helpers={helpers} />
      </div>
    </ElementDecorator>
  );
});

PermissionsElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.shape({})),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * 获取数据的地址
     */
    dataUrl: PropTypes.string,
    /**
     * TODO, 设置 projectId 好像不合适，FormRenderer 里没有 project 的概念
     */
    projectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  onChange: PropTypes.func,
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
PermissionsElement.displayName = 'Permissions';

export default withFieldWrapper(PermissionsElement);
