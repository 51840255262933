import { restApi, shouldTranslateByDefault } from '@icp/settings';
import { get } from 'lodash-es';
import { EvalWorker } from '@icp/utils';
import { translateEntireObj } from '@icp/i18n';
import { isMatchableConfigLike, isPredicateConfigLike } from './conditionalUtils';

export function dataSourceToDataUrl(dataSource, context, isAggr = false, isInDesign = false) {
  if (!dataSource) {
    return null;
  }

  if (dataSource.listUrl) {
    return dataSource.listUrl;
  }

  const pbcToken = dataSource.pbcToken || context.pbcToken;
  const formEntityToken = dataSource.token;

  if (!pbcToken || !formEntityToken) {
    console.error('Cannot transfer datasource to dataUrl:', dataSource);
    return null;
  }

  if (isAggr) {
    if (isInDesign) {
      return `/form/api/v2/form-entity-data/${context.projectToken}/${pbcToken}/${formEntityToken}/queryPipeline`;
    }
    return `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/queryPipeline`;
  }
  return `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/list`;
}

export function dataSourceToUrlGroup(dataSource, context) {
  if (!dataSource) {
    return {};
  }

  const pbcToken = dataSource.pbcToken || context.pbcToken;
  const formEntityToken = dataSource.token;

  let listIdsUrl = dataSource.listIdsUrl;
  let listByIdsUrl = dataSource.listByIdsUrl;
  let listUrl = dataSource.listUrl;

  if (!listIdsUrl) {
    listIdsUrl = `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/ids`;
  }

  if (!listByIdsUrl) {
    listByIdsUrl = `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/list-by-ids`;
  }

  if (!listUrl) {
    listUrl = `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/list`;
  }

  return { listIdsUrl, listByIdsUrl, listUrl };
}

export function legacyMultiDataUrlConfigSupport(config) {
  const {
    multiDataSource,
    multiDataSourceFlat,
    dataSource,
    dataUrl,
    dataFilters,
    dataResponseKeyPath,
    defaultValues,
    sortModel,
    transformDataResponse,
    translateDataResponse,
  } = config;

  // 当 dataUrl 配置是个数组，并且不是 ConditionalProperty 配置的时候，表示是老数据配置，在 dataUrl 里指定多个
  // url 来实现多数据源的配置。针对此做 legacy support，转换成新的配置 multiDataSource。
  if (
    !multiDataSource &&
    Array.isArray(dataUrl) &&
    (!isMatchableConfigLike(dataUrl[0]) || !isPredicateConfigLike(dataUrl[0]))
  ) {
    return {
      multiDataSource: dataUrl.map((url, index) => {
        return {
          dataSource,
          dataFilters,
          sortModel,
          transformDataResponse,
          translateDataResponse,
          dataUrl: url,
          dataResponseKeyPath: Array.isArray(dataResponseKeyPath)
            ? dataResponseKeyPath[index]
            : dataResponseKeyPath,
          defaultValue: Array.isArray(defaultValues) ? defaultValues[index] : defaultValues,
        };
      }),
      multiDataSourceFlat: true,
    };
  }

  return { multiDataSource, multiDataSourceFlat };
}

function composeDefaultValue(data, defaultValue) {
  if (Array.isArray(data)) {
    return data.map((item) => ({ ...defaultValue, ...item }));
  }
  if (typeof data === 'object' && data !== null) {
    return { ...defaultValue, ...data };
  }
  return data;
}

function sortData(data, sortModel) {
  const { id, sort, type } = sortModel;

  return data.sort((a, b) => {
    let lhs = a[id];
    let rhs = b[id];
    if (sort === 'desc') {
      [rhs, lhs] = [lhs, rhs];
    }

    if (type === 'number') {
      return lhs - rhs;
    }
    if (type === 'date') {
      return new Date(lhs) - new Date(rhs);
    }
    return `${lhs}`.localeCompare(`${rhs}`);
  });
}

/* function toApiSortModel(sortModel) {
  if (typeof sortModel !== 'object' || !Object.keys(sortModel)?.length) {
    return undefined;
  }

  return [
    {
      colId: sortModel.id,
      sortType: sortModel.type,
      sort: sortModel.sort,
    },
  ];
} */

function request({
  signal,
  skipResponseInterceptors,
  dataUrl,

  startRow,
  endRow,
  searchText,
  filterModel,
  // sortModel,

  httpMethod,
  httpBody,
  selectColId,

  aggregation,
  filterModelAfterAggregation,
}) {
  httpMethod = httpMethod?.toLowerCase();
  const payload = !aggregation
    ? {
        startRow,
        endRow,
        searchText,
        filterModel,
        // sortModel: toApiSortModel(sortModel),
        selectColId,
      }
    : {
        phases: [
          {
            filterModel,
          },
          aggregation,
          {
            filterModel: filterModelAfterAggregation || [],
          },
        ],
      };

  if (httpMethod === 'get') {
    return restApi[httpMethod](dataUrl, {
      signal,
      skipResponseInterceptors,
      params: { payload: JSON.stringify(payload) },
    });
  }

  return restApi[httpMethod](dataUrl, httpBody || payload, { signal, skipResponseInterceptors });
}

export async function fetchSingleDataSource({
  signal,
  skipResponseInterceptors,
  context,
  formData,
  searchText,

  // lazy control
  startRow,
  endRow,

  // single data source config
  dataUrl,
  dataResponseKeyPath,
  filterModel,
  sortModel,
  transformDataResponse,
  translateDataResponse = shouldTranslateByDefault(),
  defaultValue,
  httpMethod = 'get',
  httpBody,
  selectColId,

  // aggregation config
  aggregation,
  filterModelAfterAggregation,
}) {
  let data = await request({
    signal,
    skipResponseInterceptors,
    dataUrl,

    startRow,
    endRow,
    searchText,
    filterModel,
    sortModel,

    httpMethod,
    httpBody,
    selectColId,

    aggregation,
    filterModelAfterAggregation,
  });

  if (dataResponseKeyPath) {
    data = get(data, dataResponseKeyPath);
  }

  if (defaultValue) {
    data = composeDefaultValue(data, defaultValue);
  }

  // TODO，以前 useDataSource 全是前端排序，有必要改成后端排序吗？
  if (sortModel) {
    data = sortData(data, sortModel);
  }

  if (transformDataResponse) {
    data = await EvalWorker.shared().execEval(data, transformDataResponse, {
      params: {
        context,
        formData,
      },
    });
  }

  if (translateDataResponse) {
    translateEntireObj(data);
  }

  return data;
}

export async function fetchMultiDataSource({
  signal,
  skipResponseInterceptors,
  context,
  formData,
  multiDataSource,
  multiDataSourceFlat,
  dataResponseKeyPath,
  sortModel,
  transformDataResponse,
}) {
  let dataArray = await Promise.all(
    multiDataSource.map((config) =>
      fetchSingleDataSource({
        skipResponseInterceptors,
        // 如果 multiDataDataSource 里没有配置 dataResponseKeyPath 的话，使用外层单数据源配置的 dataResponseKeyPath。
        // 所以一般在组件的 componentProps 里写明的默认 dataResponseKeyPath 表示所有单数据源和多数据源的默认 dataResponseKeyPath。
        dataResponseKeyPath,
        ...config,
        formData,
        signal,
      }),
    ),
  );

  if (multiDataSourceFlat) {
    dataArray = dataArray.flat();
  }

  // multi data source 只能前端排序
  if (sortModel) {
    dataArray = sortData(dataArray, sortModel);
  }

  if (transformDataResponse) {
    dataArray = await EvalWorker.shared().execEval(dataArray, transformDataResponse, {
      params: {
        context,
        formData,
      },
    });
  }

  return dataArray;
}
