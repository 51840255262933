import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';
import { useLoadTreeData } from './utils';

function FieldTreeSelect({
  projectToken,
  pbcToken,
  formEntityToken,
  basedOn,
  multiple,
  value,
  onChange,
  ...other
}) {
  if (!value) {
    value = [];
  }

  const [data1, loading1] = useLoadTreeData({
    initialValue: basedOn ? [...value, basedOn] : value,
    projectToken,
    pbcToken,
    formEntityToken,
  });

  let basedOnField = null;
  if (basedOn && data1) {
    basedOnField = data1.resolveFieldInValue([basedOn])?.[0]?.slice(-1)[0];
    value = value.map((x) => x.substring(basedOn.length + 1));
  }

  const [data2, loading2] = useLoadTreeData({
    initialValue: value,
    projectToken,
    pbcToken: basedOnField?.referencePbc,
    formEntityToken: basedOnField?.referenceEntity,
  });

  const data = data2 || data1;
  const loading = loading2 || loading1;

  const handleChange = (newValue) => {
    if (!newValue) {
      onChange([], []);
      return;
    }
    if (multiple) {
      const newVal = newValue.map((v) => (basedOn ? `${basedOn}.${v.value}` : v.value));
      onChange(newVal, data1.resolveFieldInValue(newVal));
      return;
    }
    const newVal = basedOn ? `${basedOn}.${newValue.value}` : newValue.value;
    onChange([newVal], data1.resolveFieldInValue([newVal]));
  };

  let innerValue = loading ? undefined : data?.resolveLabelInValue(value) ?? value;
  if (!multiple) {
    innerValue = innerValue?.[0];
  }

  return (
    <TreeSelect
      popupMatchSelectWidth={false}
      showSearch={true}
      labelInValue={true}
      multiple={multiple}
      treeDefaultExpandAll={data?.loadedKeys?.length < 50}
      treeCheckable={multiple}
      treeCheckStrictly={multiple}
      loading={loading}
      fieldNames={{
        title: 'label',
        key: 'value',
        children: 'children',
      }}
      treeData={data?.treeData || []}
      loadData={data?.loadData}
      treeLoadedKeys={data?.loadedKeys || []}
      // 选中值的展示格式依靠 valueInLabel 填充
      value={innerValue}
      filterTreeNode={(inputValue, treeNode) => {
        return (treeNode?.label ?? '').toLowerCase().includes((inputValue || '').toLowerCase());
      }}
      onChange={handleChange}
      {...other}
    />
  );
}

FieldTreeSelect.propTypes = {
  projectToken: PropTypes.string,
  pbcToken: PropTypes.string,
  formEntityToken: PropTypes.string,
  basedOn: PropTypes.string,
  multiple: PropTypes.bool,
  // 无论多选单选统一传入value数组
  value: PropTypes.arrayOf(PropTypes.string),
  // 无论多选单选统一返回value数组
  onChange: PropTypes.func,
};

export default FieldTreeSelect;
