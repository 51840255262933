import PropTypes from 'prop-types';
import UploadElement from '../../UploadElement';
import { CurrentDataProvider } from '../../../currentDataCtx';

function UploadCellEditor(props) {
  const { value, onValueChange, data, context, componentProps = {} } = props;

  return (
    <CurrentDataProvider value={data}>
      <UploadElement
        className="table-upload-editor"
        suppressFormControl={true}
        value={value}
        onChange={onValueChange}
        componentProps={{
          ...componentProps,
          buttonProps: { ...componentProps.buttonProps, size: context.tableSize },
        }}
      />
    </CurrentDataProvider>
  );
}

UploadCellEditor.propTypes = {
  value: PropTypes.shape({
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        storageId: PropTypes.string,
        fileName: PropTypes.string,
        url: PropTypes.string,
      }),
    ),
  }),
  onValueChange: PropTypes.func,
  data: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  componentProps: PropTypes.shape({
    buttonProps: PropTypes.shape({}),
  }),
};

export default UploadCellEditor;
