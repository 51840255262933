import PropTypes from 'prop-types';
import { useState } from 'react';
import DatePickerElement from '../../DatePickerElement';
import { CurrentDataProvider } from '../../../currentDataCtx';

function DateCellEditor(props) {
  const { value, onValueChange, cellStartedEdit, data, context, componentProps } = props;

  const [openState, setOpenState] = useState(cellStartedEdit);

  return (
    <CurrentDataProvider value={data}>
      <DatePickerElement
        className="table-date-editor"
        suppressFormControl={true}
        value={value}
        onChange={onValueChange}
        componentProps={{
          size: context.tableSize,
          autoFocus: cellStartedEdit,
          open: openState,
          onOpenChange: setOpenState,
          ...componentProps,
        }}
      />
    </CurrentDataProvider>
  );
}

DateCellEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onValueChange: PropTypes.func,
  cellStartedEdit: PropTypes.bool,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  componentProps: PropTypes.shape({}),
};

export default DateCellEditor;
