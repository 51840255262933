import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Cascader } from 'antd';
import clsx from 'clsx';
import { shouldTranslateByDefault } from '@icp/settings';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useElementDecorator, useIsInDesign } from '../../FormRenderCtx';
import FieldTitle from '../../FieldTitle';
import FormHelperText from '../../FormHelperText';
import { useClassName, useDataSource, useVariablePatternArray } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType, SingleDataSourceTypes } from '../../propTypes';
import {
  DEFAULT_MAPPING,
  formatOptions,
  getDisplayText,
  toComponentValue,
  toInterfaceValue,
} from './utils';

const CascaderElement = forwardRef(function CascaderElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value: valueProp,
    disabled,
    style,
    componentProps = {},
    fieldTitleProps,
    validation,
    readonly,
    status,
    helpers,
    onChange,
    onTouchChanged,
  } = props;

  const {
    multiple,
    fieldNames: fieldNamesDeprecated, // @deprecated
    mapping: mappingProp,
    useOriginValue = false,
    stringEqual = false,
    dataSource,
    dataUrl,
    dataFilters,
    sortModel,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
    options: optionsProp,
    buildTreeBy,
    dataExclusion: dataExclusionProp,
    ...otherComponentProps
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const dataExclusion = useVariablePatternArray(dataExclusionProp);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const { loading, dataFetched } = useDataSource({
    skip: isInDesign || !!optionsProp,
    dataSource,
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
  });

  const mapping = mappingProp || fieldNamesDeprecated || DEFAULT_MAPPING;

  const options = useMemo(() => {
    return formatOptions({
      optionsProp,
      dataFetched,
      valueProp,
      multiple,
      mapping,
      stringEqual,
      buildTreeBy,
      dataExclusion,
    });
  }, [
    buildTreeBy,
    dataExclusion,
    mapping,
    multiple,
    dataFetched,
    optionsProp,
    stringEqual,
    valueProp,
  ]);

  const innerValue = toComponentValue({
    valueProp,
    options,
    multiple,
    mapping,
    useOriginValue,
    stringEqual,
  });

  const handleChange = (newValue, selectedOptions) => {
    onChange(toInterfaceValue({ selectedOptions, multiple, mapping, useOriginValue }));
  };

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'cascader-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={nodeRef}
      >
        <FieldTitle required={validation?.required} {...fieldTitleProps}>
          {title}
        </FieldTitle>
        <div>
          {readonly ? (
            <span className="readonly-text">
              {getDisplayText({ valueProp, mapping, useOriginValue })}
            </span>
          ) : (
            <Cascader
              {...otherComponentProps}
              className={classNameComp}
              name={id}
              value={innerValue}
              loading={loading}
              options={options}
              fieldNames={mapping}
              disabled={disabled}
              status={status}
              onChange={handleChange}
              onBlur={onTouchChanged}
              multiple={multiple}
            />
          )}
          <FormHelperText helpers={helpers} />
        </div>
      </div>
    </ElementDecorator>
  );
});

CascaderElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  /**
   * 单选时value是选项对象数组, 代表选中项的路径, 如: [{ value: 1, label: '中国' }, { value: 2, label: '上海' }] 代表选中 上海, 其路径为: 中国/上海
   * 多选时value也是选项对象数组, 代表选中项的路径的尾节点, 如: [{ value: 2, label: '上海' }, { value: 3, label: '天津' }] 代表选中上海和天津, 其路径为: [中国/上海, 中国/天津]
   */
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    ...SingleDataSourceTypes,
    /**
     * antd Cascader 元素的 options
     */
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
      }),
    ),
    /**
     * antd Cascader 元素的 fieldNames
     * 不要用 fieldNames，用我们统一的 mapping 替代。
     * @deprecated
     */
    fieldNames: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      children: PropTypes.string,
    }),
    mapping: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      children: PropTypes.string,
      // Other key map to save
    }),
    /**
     * 是否多选
     */
    multiple: PropTypes.bool,
    useOriginValue: PropTypes.bool,
    stringEqual: PropTypes.bool,
    buildTreeBy: PropTypes.string,
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
CascaderElement.displayName = 'Cascader';

export default withFieldWrapper(CascaderElement);
