/* eslint-disable react/prop-types */
import { Pagination } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useEventCallback } from '@icp/hooks';
import { getSearchParam, updateSearchParam } from '@icp/utils';

function PaginationStatusBar(props) {
  const { api, context } = props;

  const [, setRefreshFlag] = useState(Date.now());

  const isInitCurrentPageSet = useRef(false);

  const currentPageKey = context.id ? `${context.id}-page` : null;

  const handlePaginationChange = useEventCallback(() => {
    setRefreshFlag(Date.now());

    const urlCurrentPage = Number(getSearchParam(currentPageKey));
    const tableCurrentPage = api.paginationGetCurrentPage();

    // ag-grid 没有参数指定初始的 current page，也无法在 gridReady 的时候立即去调用 api.paginationGoToPage。
    // 测试下来是在 paginationChanged event 里去调用 api.paginationGoToPage 是有效的。
    // 这样的结果是 table 一定会加载第一页的数据。
    if (!isInitCurrentPageSet.current) {
      isInitCurrentPageSet.current = true;

      if (!Number.isNaN(urlCurrentPage) && urlCurrentPage !== tableCurrentPage) {
        api.paginationGoToPage(urlCurrentPage);
      }
    }

    if (currentPageKey && urlCurrentPage !== tableCurrentPage) {
      updateSearchParam(currentPageKey, tableCurrentPage);
    }
  });

  useEffect(() => {
    api.addEventListener('paginationChanged', handlePaginationChange);

    return () => {
      if (!api.isDestroyed()) {
        api.removeEventListener('paginationChanged', handlePaginationChange);
      }
    };
  }, [api, handlePaginationChange]);

  const handlePageChange = (nextPage) => {
    // ag-grid page start with 0, but antd page start with 1.
    api.paginationGoToPage(nextPage - 1);
  };

  const handleShowSizeChange = (newCurrent, newPageSize) => {
    // 如果改了 cacheBlockSize，ag-grid 一定会跳转到第一页去，后面的 api.paginationGoToPage(newCurrent - 1); 就无效了
    // 所以现在有个问题是 cacheBlockSize 是一次性的不会更新，
    // 例如，刚开始 paginationPageSize = 20，在页面改成 50 过后，cacheBlockSize 还是 20，那一页 table 就会发 3 个请求
    // api.setCacheBlockSize(newPageSize);
    api.setGridOption('paginationPageSize', newPageSize);
    api.paginationGoToPage(newCurrent - 1);
  };

  const current = api.paginationGetCurrentPage();
  const pageSize = api.paginationGetPageSize();
  const total = api.paginationGetRowCount();

  return (
    <Pagination
      current={current + 1}
      className="icp-table-pagination icp-center"
      size="small"
      showQuickJumper={true}
      showSizeChanger={true}
      pageSize={pageSize}
      total={total}
      onChange={handlePageChange}
      onShowSizeChange={handleShowSizeChange}
    />
  );
}

export default PaginationStatusBar;
