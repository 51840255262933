import { message } from '@icp/settings';
import dayjs from 'dayjs';
import { selectContext } from '@icp/form-renderer-core';
import { getTFunc } from '@icp/i18n';
import { isEqual } from 'lodash-es';
import { toMap } from '@icp/utils';
import COLUMN_TYPES from '../TableElement/columnTypes';
import { runCellValidate, runColumnValidate } from './validation';
import { forEachColumnDefs } from '../TableElement/utils';

export function isMissingCellEditor(colDef) {
  return (
    (!colDef.cellEditor && !colDef.type) ||
    (!colDef.cellEditor && !COLUMN_TYPES[colDef.type]?.cellEditor)
  );
}

export function isTextCellEditor(colDef) {
  return colDef.cellEditor === 'TextCellEditor';
}

export function isNumberCellEditor(colDef) {
  return (
    colDef.cellEditor === 'NumberCellEditor' ||
    (!colDef.cellEditor && COLUMN_TYPES[colDef.type]?.cellEditor === 'NumberCellEditor')
  );
}

export function isTextareaCellEditor(colDef) {
  return (
    colDef.cellEditor === 'TextareaCellEditor' ||
    (!colDef.cellEditor && COLUMN_TYPES[colDef.type]?.cellEditor === 'TextareaCellEditor')
  );
}

export function isSelectCellEditor(colDef) {
  return (
    colDef.cellEditor === 'SelectCellEditor' ||
    (!colDef.cellEditor && COLUMN_TYPES[colDef.type]?.cellEditor === 'SelectCellEditor')
  );
}

export function isDateCellEditor(colDef) {
  return (
    colDef.cellEditor === 'DateCellEditor' ||
    (!colDef.cellEditor && COLUMN_TYPES[colDef.type]?.cellEditor === 'DateCellEditor')
  );
}

export function isACLCellEditor(colDef) {
  return (
    colDef.cellEditor === 'ACLCellEditor' ||
    (!colDef.cellEditor && COLUMN_TYPES[colDef.type]?.cellEditor === 'ACLCellEditor')
  );
}

const needValidateUnique = (cellEditor) => {
  return [
    'TextCellEditor',
    'TextareaCellEditor',
    'NumberCellEditor',
    'DateCellEditor',
    'ACLCellEditor',
    'ParentCellEditor',
    'SelectCellEditor',
    'TimeCellEditor',
  ].includes(cellEditor);
};

export function formatEditorColumnDefs(columnDefsProp, defaultColDef, disabled, readonly) {
  if (!Array.isArray(columnDefsProp)) {
    return [];
  }

  if (disabled || readonly) {
    return columnDefsProp.map((colDef) => {
      return {
        ...colDef,
        editable: false,
      };
    });
  }

  const t = getTFunc('icp-form-renderer');

  return columnDefsProp.map((colDef) => {
    const editable = colDef.editable ?? defaultColDef?.editable;
    if (editable !== true) {
      return colDef;
    }

    if (isMissingCellEditor(colDef)) {
      // Use our custom TextCellEditor by default
      colDef = { ...colDef, cellEditor: 'TextCellEditor' };
    } else {
      // Set cellEditor clearly, for makeTableYupSchema
      colDef = {
        ...colDef,
        cellEditor: colDef.cellEditor || COLUMN_TYPES[colDef.type]?.cellEditor,
      };
    }

    // use cellEditorPopup true for TextareaCellEditor by default
    if (isTextareaCellEditor(colDef) && colDef.cellEditorPopup === undefined) {
      colDef = { ...colDef, cellEditorPopup: true };
    }

    const setValueAfterValidate = (params) => {
      const { data, context, newValue, node: nodeCurrent } = params;
      const { yupSchema } = context;

      const passed1 = runCellValidate({ yupSchema, colDef, data, value: newValue });

      if (!passed1) {
        return false;
      }

      if (needValidateUnique) {
        const allRowData = [];
        params.api.forEachNode((node) => {
          if (node !== nodeCurrent) {
            allRowData.push(node.data);
          } else {
            allRowData.push({ ...data, [colDef.colId]: newValue });
          }
        });
        const passed2 = runColumnValidate({ colDef, data: allRowData });

        if (!passed2) {
          return false;
        }
      }

      data[colDef.colId] = newValue;
      return true;
    };

    // handler validation for copy / paste case
    // Only enable copy / paste for TextCellEditor, NumberCellEditor, DateCellEditor, ACLCellEditor,
    // and SelectCellEditor
    if (isTextCellEditor(colDef) || isSelectCellEditor(colDef) || isACLCellEditor(colDef)) {
      colDef = {
        ...colDef,
        valueSetter: (params) => {
          const { newValue } = params;

          if (newValue === 'CellEditor:invalid') {
            message.error(t('error.data-invalid', { name: colDef.headerName }));
            return false;
          }

          return setValueAfterValidate(params);
        },
      };
    } else if (isDateCellEditor(colDef)) {
      colDef = {
        ...colDef,
        valueSetter: (params) => {
          const { newValue } = params;

          const isValidDate = dayjs(newValue).isValid();
          if (!isValidDate) {
            message.error(t('error.data-format-invalid', { name: colDef.headerName }));
            return false;
          }

          return setValueAfterValidate(params);
        },
      };
    } else if (isNumberCellEditor(colDef)) {
      colDef = {
        ...colDef,
        valueSetter: (params) => {
          const { newValue } = params;

          if (
            typeof newValue !== 'number' &&
            newValue !== null &&
            !(typeof newValue === 'string' && !Number.isNaN(Number(newValue)))
          ) {
            message.error(t('error.data-format-invalid', { name: colDef.headerName }));
            return false;
          }

          return setValueAfterValidate(params);
        },
      };
    } else {
      colDef = {
        ...colDef,
        suppressPaste: true,
        valueSetter: (params) => {
          return setValueAfterValidate(params);
        },
      };
    }

    if (colDef.rowDrag && !colDef.rowDragText) {
      colDef = {
        ...colDef,
        rowDragText: (params /* , dragItemCount */) => {
          return params.columns[0].colDef.cellRenderer.toDisplayText(
            params.defaultTextValue,
            params.columns[0].colDef.cellRendererParams,
            params.context,
          );
        },
      };
    }

    if (colDef.cellEditorParams?.validation?.required) {
      const originalHeaderClass = colDef.headerClass;
      colDef = {
        ...colDef,
        headerClass: (headerClassParams) => {
          const appendCls = 'editable-table-col-header-required';
          if (typeof originalHeaderClass === 'string') {
            return `${originalHeaderClass} ${appendCls}`;
          }
          if (Array.isArray(originalHeaderClass)) {
            return [...originalHeaderClass, appendCls];
          }
          if (originalHeaderClass instanceof Function) {
            return `${originalHeaderClass(headerClassParams) || ''} ${appendCls}`;
          }
          return appendCls;
        },
      };
    }
    return colDef;
  });
}

export function resolveDataUrl({ store, dataUrlResolved, id }) {
  const context = selectContext(store.getState());

  // Use props.dataUrl first
  // null means manually set to empty
  if (dataUrlResolved || dataUrlResolved === null) {
    return dataUrlResolved;
  }

  // Auto generate dataUrl according to table context
  const { pbcToken, formEntityToken, formEntityId, formEntityDataId } = context;
  if (formEntityDataId !== undefined && formEntityDataId !== null) {
    if (pbcToken && formEntityToken) {
      return `/form/api/v2/form-entity-data/${pbcToken}/${formEntityToken}/${formEntityDataId}/grid-list/${id}`;
    }
    return `/form/api/form-entity-data/${formEntityId}/${formEntityDataId}/grid-list/${id}`;
  }

  return null;
}

export function findFirstEditableColKey(gridRef) {
  const allDisplayedColumns = gridRef.current.gridApi.getAllDisplayedColumns();

  for (const column of allDisplayedColumns) {
    const { colDef } = column;
    if (colDef.editable) {
      return colDef.colId;
    }
  }

  return null;
}

export function getRowData(gridApi) {
  const rowData = [];
  gridApi.forEachNode((node) => {
    rowData.push({ ...node.data });
  });
  return rowData;
}

export function getChangedData(gridApi, oldRowData) {
  const changedData = {
    inserted: [],
    updated: [],
    deleted: [],
    oldValues: [],
  };

  const rowData = getRowData(gridApi);

  if (!Array.isArray(oldRowData)) {
    oldRowData = [];
  }

  // map 加速查找, 没有 id 的行不会生成 map
  const rowDataMap = toMap()(rowData.filter((item) => item.id));
  const oldRowDataMap = toMap()(
    oldRowData.filter((item) => item.id && !`${item.id}`.includes('inserted')),
  );

  for (const item of rowData) {
    // inserted
    const oldItem = oldRowDataMap[item.id];
    if (!item.id || !oldItem) {
      const { id, ...other } = item;
      changedData.inserted.push(other);
      continue;
    }

    // find updated
    if (oldItem && !isEqual(item, oldItem)) {
      changedData.updated.push(item);
      changedData.oldValues[item.id] = oldItem;
    }
  }

  // deleted
  for (const oldItem of oldRowData) {
    if (oldItem.id && !rowDataMap[oldItem.id]) {
      changedData.deleted.push({ id: oldItem.id });
    }
  }

  return changedData;
}

export function extractValueExpressions(columnDefs) {
  const valueExpressions = [];
  forEachColumnDefs(columnDefs, (colDef) => {
    const { valueExpression } = colDef.cellEditorParams || {};
    if (valueExpression) {
      valueExpressions.push({ id: colDef.colId, valueExpression });
    }
  });
  return valueExpressions;
}
