import './AIAgentFloatButton.css';
import PropTypes from 'prop-types';
import { cloneElement, useEffect, useState } from 'react';
import { FloatButton } from 'antd';
import Resizable from '../Resizable';
import AIAgentIcon from '../AIAgentIcon';

const PARENT_CLASS = 'ai-agent-float-button-parent';

function AIAgentFloatButton(props) {
  const {
    isInDesign,
    defaultOpen = false,
    shouldHide = false,
    maxPanelWidth = window.screen.availWidth / 2,
    children,
  } = props;
  // To ensure the AI Agent input area height is correct when defaultOpen is false,
  // need "open" to avoid the AI Agent panel added to the DOM at the beginning.
  const [open, setOpen] = useState(defaultOpen);

  const floatButton = (
    <FloatButton
      style={{ display: open || shouldHide ? 'none' : 'block' }}
      className="ai-agent-float-button ai-agent-icon-hover-wrapper"
      tooltip="AI Agent"
      icon={<AIAgentIcon size="100%" />}
      onClick={() => {
        if (!isInDesign) {
          setOpen(true);
        }
      }}
    />
  );

  useEffect(() => {
    const parentElm = document.body.querySelector('.ai-agent-float-button')?.parentElement;
    parentElm?.classList?.add(PARENT_CLASS);
    return () => {
      parentElm?.classList?.remove(PARENT_CLASS);
    };
  }, []);

  return (
    <>
      <Resizable placement="left" min={366} max={maxPanelWidth}>
        <div
          className="ai-agent-right-side-panel"
          style={{ display: open && !shouldHide ? 'flex' : 'none' }}
        >
          {cloneElement(children, {
            eventHandlers: {
              ...children.props.eventHandlers,
              onClose: () => {
                setOpen(false);
                return children.props.eventHandlers?.onClose?.();
              },
            },
          })}
        </div>
      </Resizable>
      {floatButton}
    </>
  );
}

AIAgentFloatButton.propTypes = {
  isInDesign: PropTypes.bool,
  defaultOpen: PropTypes.bool,
  shouldHide: PropTypes.bool,
  maxPanelWidth: PropTypes.number,
  children: PropTypes.node,
};

export default AIAgentFloatButton;
