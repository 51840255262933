/* eslint-disable react/prop-types */
import './FilterPanel.css';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { immutableDelete, immutableSet } from '@icp/utils';
import { useEventCallback } from '@icp/hooks';
import AddFilter from './AddFilter';
import { makeNewFilterModel } from './utils';
import FilterItem from './FilterItem';

const EMPTY_ARRAY = [];

function FilterPanel(props) {
  const {
    context,
    columnDefs,
    pinnedFilter = EMPTY_ARRAY,
    filterModel = EMPTY_ARRAY,
    onChange,
  } = props;

  const [newOne, setNewOne] = useState(null);

  useEffect(() => {
    setNewOne(null);
  }, [filterModel]);

  const handleAddFilter = (colId) => {
    const colDef = columnDefs.find((item) => item.colId === colId);
    const newFilterModel = filterModel.concat(makeNewFilterModel(colDef));
    setNewOne(colId);
    onChange(newFilterModel);
  };

  const handleDeleteFilter = (colId) => {
    const index = filterModel.findIndex((model) => model.colId === colId);
    const newFilterModel = immutableDelete(filterModel, [index]);
    onChange(newFilterModel);
  };

  const handleChangeFilter = useEventCallback((colId, k, v) => {
    let index = filterModel.findIndex((model) => model.colId === colId);

    let newFilterModel = filterModel;

    // -1 意味着修改的是 pinnedFilter 的内容
    if (index === -1) {
      index = newFilterModel.length;
      const colDef = columnDefs.find((item) => item.colId === colId);
      newFilterModel = newFilterModel.concat(makeNewFilterModel(colDef));
    }

    // pinnedFilter 永远会存在，所以删除 filter 值的时候清空一下防止 FavoriteView 无意义的 dirty
    if (k === 'filter' && (v === '' || v === null) && pinnedFilter.includes(colId)) {
      // text 类型删除 input 值表示清空改 filter
      newFilterModel = immutableDelete(newFilterModel, [index]);
    } else {
      newFilterModel = immutableSet(newFilterModel, [index, k], v);
    }

    // inRange 要求两个值，清空一下脏数据
    const old = index !== -1 && newFilterModel[index];
    if ((old && k === 'type' && old.type !== v && old.type === 'inRange') || v === 'inRange') {
      if (old.filterType === 'date') {
        newFilterModel = immutableSet(newFilterModel, [index, 'dateFrom'], null);
        newFilterModel = immutableSet(newFilterModel, [index, 'dateTo'], null);
      }

      if (old.filterType === 'number') {
        newFilterModel = immutableSet(newFilterModel, [index, 'filter'], null);
        newFilterModel = immutableSet(newFilterModel, [index, 'filterTo'], null);
      }
    }

    onChange(newFilterModel);
  });

  return (
    <div className="icp-table-filter-panel">
      {Array.isArray(pinnedFilter) &&
        pinnedFilter.map((colId) => {
          const model = filterModel.find((fm) => fm.colId === colId);
          const colDef = columnDefs.find((item) => item.colId === colId);
          if (!model && !colDef) {
            return null;
          }
          return (
            <FilterItem
              key={colId}
              context={context}
              model={model || makeNewFilterModel(colDef)}
              columnDefs={columnDefs}
              deletable={false}
              onChange={(k, v) => handleChangeFilter(colId, k, v)}
            />
          );
        })}
      {filterModel
        .filter((model) => !Array.isArray(pinnedFilter) || !pinnedFilter.includes(model.colId))
        .map((model) => (
          <FilterItem
            key={model.colId}
            context={context}
            model={model}
            columnDefs={columnDefs}
            defaultOpen={newOne === model.colId}
            onChange={(k, v) => handleChangeFilter(model.colId, k, v)}
            onDelete={() => handleDeleteFilter(model.colId)}
          />
        ))}
      <AddFilter
        columnDefs={columnDefs}
        pinnedFilter={pinnedFilter}
        filterModel={filterModel}
        onChange={handleAddFilter}
      />
    </div>
  );
}

FilterPanel.propTypes = {
  context: PropTypes.shape({}),
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  pinnedFilter: PropTypes.arrayOf(PropTypes.string),
  filterModel: PropTypes.arrayOf(PropTypes.shape({})),
  onChange: PropTypes.func,
};

export default FilterPanel;
