import loadable from '@loadable/component';

export default loadable(
  () =>
    import(
      /* webpackChunkName: "icp-form-designer" */
      /* webpackPrefetch: true */
      './FormDesigner'
    ),
);

export const PageDesigner = loadable(
  () =>
    import(
      /* webpackChunkName: "icp-form-designer" */
      /* webpackPrefetch: true */
      './PageDesigner'
    ),
);

export { createFormEntityByLayout } from './store/features/formEntitySlice';
export * from './utils';
export { ensureSchemaSatisfies } from './AIAgent/utils';
export { dataSourceToColumnDefs } from './DesignerSettings/fieldSettings/TableSettings/utils';
export { makeNewField } from './store/utils';
