export const DEFAULT_THEME = 'blue';

export const blueTheme = {
  name: 'blue',
  token: {
    colorPrimary: '#1890ff',
    colorPrimaryBg: '#e6f4ff',
    colorPrimaryHover: '#40a9ff',
    colorInfo: '#1677ff',
    colorSuccess: '#52c41a',
    colorWarning: '#faad14',
    colorError: '#ff4d4f',
    colorBorder: '#dcdedf',
    colorBorderSecondary: '#f0f0f0',
    colorLink: '#1890ff',
    colorLinkHover: '#69c0ff',
    colorLinkActive: '#096dd9',
    colorTextBase: '#000',
    colorText: '#333',
    colorTextTertiary: 'rgba(0 0 0 / 45%)',
    controlHeight: 32,
    borderRadius: 6,
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontSize: 14,
    lineHeight: 1.5,
    zIndexPopupBase: 10,
  },
  components: {},
  cssVars: {
    '--page-background-color': '#fff',
    '--card-shadow':
      'rgba(145, 158, 171, 0.2) 0px 0px 2px 0px, rgba(145, 158, 171, 0.12) 0px 12px 24px -4px',
  },
};

export const darkBlueTheme = {
  token: {
    colorPrimary: '#4540DF',
    colorPrimaryBg: '#f3f0ff',
    colorPrimaryBgHover: '#f0edff',
    colorPrimaryBorder: '#ccc4ff',
    colorPrimaryBorderHover: '#a197f7',
    colorPrimaryHover: '#726aeb',
    colorPrimaryActive: '#2c2cb8',
    colorPrimaryTextHover: '#726aeb',
    colorPrimaryText: '#4540df',
    colorPrimaryTextActive: '#2c2cb8',
    colorInfo: '#4540DF',
    colorLink: '#4540DF',
    colorLinkHover: '#a197f7',
    colorLinkActive: '#2c2cb8',
  },
  components: {},
  cssVars: {
    '--page-background-color': '#F8F8FE',
    '--card-shadow': null,
  },
};

export const greenTheme = {
  token: {
    colorPrimary: '#29A875',
    colorPrimaryBg: '#EFF8F4',
    colorPrimaryBgHover: '#cff1df',
    colorPrimaryBorder: '#95cfb2',
    colorPrimaryBorderHover: '#6dc29a',
    colorPrimaryHover: '#48b586',
    colorPrimaryActive: '#19825b',
    colorPrimaryTextHover: '#48b586',
    colorPrimaryText: '#29a875',
    colorPrimaryTextActive: '#19825b',
    colorInfo: '#29A875',
    colorLink: '#29A875',
    colorLinkHover: '#6dc29a',
    colorLinkActive: '#19825b',
  },
  components: {},
  cssVars: {
    '--page-background-color': '#F4F6FA',
    '--card-shadow': null,
  },
};

export const greenWithBlackButtonTheme = {
  token: {
    colorPrimary: '#6bb665',
    colorPrimaryBg: '#eaf5e6',
    colorPrimaryBgHover: '#dde8da',
    colorPrimaryBorder: '#d0dbce',
    colorPrimaryBorderHover: '#b7cfb4',
    colorPrimaryHover: '#8fc28a',
    colorPrimaryActive: '#4a8f47',
    colorPrimaryTextHover: '#8fc28a',
    colorPrimaryText: '#6bb665',
    colorPrimaryTextActive: '#4a8f47',
    colorInfo: '#202E3F',
    colorLink: '#202E3F',
    colorLinkHover: '#91999E',
    colorLinkActive: '#090F17',
    borderRadius: 4,
  },
  components: {
    Button: {
      colorPrimary: 'rgb(32, 46, 63)',
      colorPrimaryHover: 'rgb(69, 79, 91)',
      controlOutline: 'rgba(32, 46, 63, 0.1)',
      controlTmpOutline: 'rgba(32, 46, 63, 0.1)',
      colorPrimaryActive: 'rgb(9, 15, 23)',
      colorLink: 'rgb(32, 46, 63)',
      colorLinkActive: 'rgb(9, 15, 23)',
      colorLinkHover: 'rgb(145, 153, 158)',
    },
    Tabs: {
      colorPrimary: 'rgb(32, 46, 63)',
      colorPrimaryActive: 'rgb(9, 15, 23)',
      colorPrimaryHover: 'rgb(145, 153, 158)',
    },
  },
  cssVars: {
    '--page-background-color': '#F8F8F8',
    '--card-shadow': null,
  },
};

export const orangeTheme = {
  token: {
    colorPrimary: '#f68443',
    colorPrimaryBg: '#fff8f0',
    colorPrimaryBgHover: '#fff4e8',
    colorPrimaryBorder: '#ffddbf',
    colorPrimaryBorderHover: '#ffc496',
    colorPrimaryHover: '#ffa86e',
    colorPrimaryActive: '#cf632d',
    colorPrimaryTextHover: '#ffa86e',
    colorPrimaryText: '#f68443',
    colorPrimaryTextActive: '#cf632d',
    colorInfo: '#f68443',
    colorLink: '#f68443',
    colorLinkHover: '#fbab6d',
    colorLinkActive: '#cf632d',
    borderRadius: 16,
    borderRadiusLG: 20,
  },
  components: {},
  cssVars: {
    '--page-background-color': '#F8F8F8',
    '--card-shadow': null,
  },
};

export const grayTheme = {
  token: {
    colorPrimary: '#4c5f69',
    colorPrimaryBg: '#dce4e6',
    colorPrimaryBgHover: '#929a9c',
    colorPrimaryBorder: '#868d8f',
    colorPrimaryBorderHover: '#7a8082',
    colorPrimaryHover: '#7b919a',
    colorPrimaryActive: '#2c3a42',
    colorPrimaryTextHover: '#677175',
    colorPrimaryText: '#4c5f69',
    colorPrimaryTextActive: '#2c3a42',
    colorInfo: '#4c5f69',
    colorLink: '#4c5f69',
    colorLinkHover: '#8c979b',
    colorLinkActive: '#2c3a42',
  },
  components: {},
  cssVars: {
    '--page-background-color': '#F8F8F8',
    '--card-shadow': null,
  },
};

export const purpleTheme = {
  token: {
    colorPrimary: '#9572c6',
    colorPrimaryBg: '#f9f0ff',
    colorPrimaryBgHover: '#f3ebfa',
    colorPrimaryBorder: '#e6dfed',
    colorPrimaryBorderHover: '#d5cae0',
    colorPrimaryHover: '#b59dd4',
    colorPrimaryActive: '#7255a1',
    colorPrimaryTextHover: '#b59dd4',
    colorPrimaryText: '#9572c6',
    colorPrimaryTextActive: '#7255a1',
    colorInfo: '#9572c6',
    colorSuccess: '#8dd469',
    colorSuccessBg: '#f7fff0',
    colorSuccessBgHover: '#f7fff0',
    colorSuccessBorder: '#f1faeb',
    colorSuccessBorderHover: '#d4edc2',
    colorSuccessHover: '#d4edc2',
    colorSuccessActive: '#6bad4e',
    colorSuccessTextHover: '#b0e094',
    colorSuccessText: '#8dd469',
    colorSuccessTextActive: '#6bad4e',
    colorWarning: '#f9c255',
    colorWarningBg: '#fffcf0',
    colorWarningBgHover: '#fffcf0',
    colorWarningBorder: '#fff4d1',
    colorWarningBorderHover: '#ffe8a8',
    colorWarningHover: '#ffe8a8',
    colorWarningActive: '#d49d3d',
    colorWarningTextHover: '#ffd980',
    colorWarningText: '#f9c255',
    colorWarningTextActive: '#d49d3d',
    colorError: '#fa6265',
    colorErrorBg: '#fff2f0',
    colorErrorBgHover: '#fff1f0',
    colorErrorBorder: '#ffe1de',
    colorErrorBorderHover: '#ffb9b5',
    colorErrorHover: '#ff8e8c',
    colorErrorActive: '#d4484f',
    colorErrorTextHover: '#ff8e8c',
    colorErrorText: '#fa6265',
    colorErrorTextActive: '#d4484f',
    colorLinkHover: '#52d1ff',
    colorLinkActive: '#0089d9',
    colorTextBase: '#4d3f60',
    colorBgBase: '#fff',
    colorBgContainer: '#fff',
    colorBgElevated: '#fff',
    colorBgLayout: '#f5f5f5',
    colorBgSpotlight: 'rgba(77, 63, 96, 0.85)',
    colorBgMask: 'rgba(0, 0, 0, 0.45)',
    colorBorder: '#d9d9d9',
    colorBorderSecondary: '#f0f0f0',
    colorText: 'rgba(77, 63, 96, 0.88)',
    colorTextSecondary: 'rgba(77, 63, 96, 0.65)',
    colorTextTertiary: 'rgba(77, 63, 96, 0.45)',
    colorTextQuaternary: 'rgba(77, 63, 96, 0.25)',
    colorFill: 'rgba(77, 63, 96, 0.15)',
    colorFillSecondary: 'rgba(77, 63, 96, 0.06)',
    colorFillTertiary: 'rgba(77, 63, 96, 0.04)',
    colorFillQuaternary: 'rgba(77, 63, 96, 0.02)',
    wireframe: false,
    colorLink: '#00aaff',
    fontSize: 13,
    fontSizeSM: 10,
    fontSizeLG: 14,
    fontSizeXL: 18,
    fontSizeHeading1: 34,
    fontSizeHeading2: 28,
    fontSizeHeading3: 22,
    fontSizeHeading4: 18,
    fontSizeHeading5: 14,
    borderRadius: 16,
    borderRadiusXS: 2,
    borderRadiusSM: 8,
    borderRadiusLG: 16,
  },
  components: {
    Menu: {
      darkItemSelectedBg: 'transparent',
      darkItemHoverColor: '#b59dd4',
      darkItemSelectedColor: '#9572c6',
    },
  },
  cssVars: {
    '--page-background-color':
      'linear-gradient(109deg, #C8D2FD 0%, #F3F8FF 40%, #F3F8FF 60%, #C3EDE6 100%)',
    '--card-shadow': null,
    '--card-backdrop-filter': 'blur(3px)',
    '--card-background': 'rgba(255, 255, 255, 0.5)',
  },
  agGridCssVars: {
    '--ag-active-color': '#2196f3',
    '--ag-background-color': '#fff',
    '--ag-foreground-color': '#181d1f',
    '--ag-border-color': '#eff0f1',
    '--ag-secondary-border-color': 'var(--ag-border-color)',
    '--ag-header-background-color': '#f9fafb',
    '--ag-tooltip-background-color': 'var(--ag-header-background-color)',
    '--ag-control-panel-background-color': 'var(--ag-header-background-color)',
    '--ag-subheader-background-color': 'transparent',
    '--ag-invalid-color': '#e02525',
    '--ag-advanced-filter-join-pill-color': '#f08e8d',
    '--ag-advanced-filter-column-pill-color': '#a6e194',
    '--ag-advanced-filter-option-pill-color': '#f3c08b',
    '--ag-advanced-filter-value-pill-color': '#85c0e4',
    '--ag-icon-font-color': 'color-mix(in srgb, transparent, var(--ag-foreground-color) 90%)',
    '--ag-panel-background-color':
      'color-mix(in srgb, var(--ag-background-color), var(--ag-foreground-color) 3%)',
    '--ag-panel-border-color': 'color-mix(in srgb, transparent, var(--ag-foreground-color) 20%)',
    '--ag-menu-background-color':
      'color-mix(in srgb, var(--ag-background-color), var(--ag-foreground-color) 3%)',
    '--ag-menu-border-color': 'color-mix(in srgb, transparent, var(--ag-foreground-color) 20%)',
    '--ag-selected-row-background-color':
      'color-mix(in srgb, transparent, var(--ag-active-color) 8%)',
    '--ag-row-hover-color': 'color-mix(in srgb, transparent, var(--ag-active-color) 12%)',
    '--ag-column-hover-color': 'color-mix(in srgb, transparent, var(--ag-foreground-color) 5%)',
    '--ag-input-focus-border-color': 'var(--ag-active-color)',
    '--ag-input-focus-box-shadow':
      '0 0 0 3px color-mix(in srgb, transparent, var(--ag-input-focus-border-color) 47%)',
    '--ag-range-selection-background-color':
      'color-mix(in srgb, transparent, var(--ag-active-color) 20%)',
    '--ag-range-selection-background-color-2':
      'color-mix(in srgb, transparent, var(--ag-active-color) 36%)',
    '--ag-range-selection-background-color-3':
      'color-mix(in srgb, transparent, var(--ag-active-color) 49%)',
    '--ag-range-selection-background-color-4':
      'color-mix(in srgb, transparent, var(--ag-active-color) 59%)',
    '--ag-checkbox-background-color': 'var(--ag-background-color)',
    '--ag-checkbox-checked-color': 'var(--ag-active-color)',
    '--ag-checkbox-unchecked-color':
      'color-mix(in srgb, var(--ag-background-color), var(--ag-foreground-color) 30%)',
    '--ag-range-selection-border-color': 'var(--ag-active-color)',
    '--ag-secondary-foreground-color': 'var(--ag-foreground-color)',
    '--ag-input-border-color': 'var(--ag-border-color)',
    '--ag-input-border-color-invalid': 'var(--ag-invalid-color)',
    '--ag-disabled-foreground-color':
      'color-mix(in srgb, transparent, var(--ag-foreground-color) 50%)',
    '--ag-chip-background-color': 'color-mix(in srgb, transparent, var(--ag-foreground-color) 7%)',
    '--ag-chip-border-color':
      'color-mix(in srgb, var(--ag-header-background-color), var(--ag-foreground-color) 13%)',
    '--ag-input-disabled-border-color': 'var(--ag-border-color)',
    '--ag-input-disabled-background-color':
      'color-mix(in srgb, var(--ag-background-color), var(--ag-foreground-color) 6%)',
    '--ag-modal-overlay-background-color':
      'color-mix(in srgb, transparent, var(--ag-background-color) 66%)',
    '--ag-chart-menu-label-color':
      'color-mix(in srgb, transparent, var(--ag-foreground-color) 80%)',
    '--ag-chart-menu-pill-select-button-color':
      'color-mix(in srgb, transparent, var(--ag-foreground-color) 70%)',
    '--ag-borders': 'solid 1px',
    '--ag-border-radius': '4px',
    '--ag-wrapper-border-radius': '8px',
    '--ag-borders-side-button': 'none',
    '--ag-side-button-selected-background-color': 'transparent',
    '--ag-header-column-resize-handle-display': 'block',
    '--ag-header-column-resize-handle-width': '1px',
    '--ag-header-column-resize-handle-height': '30%',
    '--ag-header-column-resize-handle-color': 'var(--ag-secondary-border-color)',
    '--ag-grid-size': '8px',
    '--ag-icon-size': '16px',
    '--ag-list-item-height': 'calc(var(--ag-icon-size) + var(--ag-widget-vertical-spacing))',
    '--ag-column-select-indent-size': 'var(--ag-icon-size)',
    '--ag-set-filter-indent-size': 'var(--ag-icon-size)',
    '--ag-filter-tool-panel-group-indent': 'var(--ag-grid-size)',
    '--ag-advanced-filter-builder-indent-size':
      'calc(var(--ag-icon-size) + var(--ag-grid-size) * 2)',
    '--ag-cell-horizontal-padding': 'calc(var(--ag-grid-size) * 2)',
    '--ag-cell-widget-spacing': 'calc(var(--ag-grid-size) * 1.5)',
    '--ag-widget-container-vertical-padding': 'calc(var(--ag-grid-size) * 1.5)',
    '--ag-widget-container-horizontal-padding': 'calc(var(--ag-grid-size) * 1.5)',
    '--ag-widget-horizontal-spacing': 'calc(var(--ag-grid-size) * 1.5)',
    '--ag-widget-vertical-spacing': 'calc(var(--ag-grid-size) * 1)',
    '--ag-toggle-button-height': '18px',
    '--ag-toggle-button-width': '28px',
    '--ag-toggle-button-border-width': '2px',
    '--ag-font-size': '14px',
    '--ag-tab-min-width': '290px',
    '--ag-chart-menu-panel-width': '260px',
    '--ag-card-shadow': '0 1px 4px 1px rgba(186, 191, 199, 0.4)',
    '--ag-popup-shadow': '0 0 16px 0 rgba(0, 0, 0, 0.15)',
    '--ag-side-bar-panel-width': '250px',
    '--ag-row-border-color': 'transparent',
    '--ag-row-border-width': 0,
  },
  css: `
.ag-cell-wrapper .upload-item-render {
  border: none;
  border-radius: 8px;
}

.ag-cell-wrapper .upload-item-render:not(.uploading):after {
  border-radius: 8px;
}

.ag-cell-wrapper .upload-item-render img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.icp-ag-table .icp-toolbar-main {
  border: none;
}

.ag-header {
  border: 1px solid var(--ag-border-color);
  border-radius: 8px;
}

.ag-row-selected::before {
  border-radius: 8px;
}

.ag-row-hover::before {
  border-radius: 8px;
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-active:not(.ant-menu-item-selected):hover::after {
  border-bottom: 2px solid var(--colorPrimaryHover);
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected::after {
  border-bottom: 2px solid var(--colorPrimaryText);
}

.ant-menu-dark.ant-menu-horizontal > .ant-menu-item-selected:hover::after {
  border-bottom: 2px solid var(--colorPrimaryText);
}
  `,
};

export const themeMap = {
  blue: blueTheme,
  green: greenTheme,
  greenWithBlackButton: greenWithBlackButtonTheme,
  darkBlue: darkBlueTheme,
  orange: orangeTheme,
  gray: grayTheme,
  purple: purpleTheme,
};

// TODO, 临时 material 解决方案，后续需要整理
const tempMaterialTheme = {
  name: 'temp-material-ui',
  token: {
    // 这个是设给 ant 的。因为 material dialog 是 1300，TreeSelect 目前还没有适配 material，在 dialog 里用 TreeSelect 的话 dropdown 会被 material dialog 挡住看不见
    zIndexPopupBase: 1300,
  },
  cssVars: {
    '--card-padding': '24px',
    '--card-radius': '16px',
  },
  // TODO, vars 里的 css 变量机制无法实现在 form designer 里 canvas 的组件使用 theme 的 vars，因为 material 组件大部分的字体设置都是用的 0.875rem 这种
  css: `
html:not(.fd-app-context),
.form-renderer {
  -webkit-font-smoothing: antialiased;
}

body {
  color: rgb(28, 37, 46);
}

.card-layout.variant-pc .card-title {
  padding-bottom: var(--card-padding);
  border-bottom: 1px solid rgba(145, 158, 171, 0.2);
}

.card-layout.variant-pc .card-title .title-desc {
  margin-top: 4px;
  color: rgb(99, 115, 129);
}
  `,
};

// theme2 覆盖 theme1
function mergeTwoTheme(theme1, theme2) {
  // Properties in obj2 cover properties in obj1
  const mergeObj = (obj1, obj2) => {
    if (!obj2 || !Object.keys(obj2).length) return obj1;

    const merged = { ...obj1 };

    for (const [k, v] of Object.entries(obj2)) {
      if (
        typeof v === 'object' &&
        v !== null &&
        typeof merged[k] === 'object' &&
        merged[k] !== null
      ) {
        merged[k] = mergeObj(merged[k], v);
      } else {
        merged[k] = v;
      }
    }

    return merged;
  };

  const mergeCss = (css1, css2) => {
    if (!css1 && typeof css2 === 'string') return css2;
    if (!css2 && typeof css1 === 'string') return css1;

    if (typeof css1 === 'string' && typeof css2 === 'string') {
      return `${css1}${css2}}`;
    }

    return '';
  };

  return {
    base: theme2.base,
    token: mergeObj(theme1.token, theme2.token),
    components: mergeObj(theme1.components, theme2.components),
    cssVars: mergeObj(theme1.cssVars, theme2.cssVars),
    agGridCssVars: mergeObj(theme1.agGridCssVars, theme2.agGridCssVars),
    css: mergeCss(theme1.css, theme2.css),
  };
}

export function extendsTheme(themeConfig, componentLibrary) {
  const { base: baseProp, cssVars = themeConfig?.varsCss } = themeConfig;
  const base = themeMap[baseProp] ? baseProp : DEFAULT_THEME;

  // 默认都 base blueTheme
  const usedConfig = themeMap[base] || themeMap[DEFAULT_THEME];

  // themeConfig 使用的 base theme 也需要递归的去 extends theme，
  let baseTheme = usedConfig !== blueTheme ? extendsTheme(usedConfig) : blueTheme;

  // TODO, 临时解决方案
  if (componentLibrary === 'material-ui') {
    baseTheme = mergeTwoTheme(baseTheme, tempMaterialTheme);
  }

  return mergeTwoTheme(baseTheme, { ...themeConfig, base, cssVars });
}

function antThemeToCssVar(token) {
  if (!token || !Object.keys(token)) {
    return null;
  }

  const entries = Object.keys(token).map((key) => [`--${key}`, token[key]]);

  return Object.fromEntries(entries);
}

function mergeThemeCssVars(mergedTheme) {
  const { token, cssVars } = mergedTheme;
  const antCssVars = antThemeToCssVar(token);
  return { ...antCssVars, ...cssVars };
}

function insertStyleElement(cssText, id) {
  let style = document.querySelector(`[data-id="${id}"]`);
  if (!style) {
    style = document.createElement('style');
    style.setAttribute('data-id', id);
    document.head.appendChild(style);
  }
  style.textContent = cssText;
}

function removeStyleElement(id) {
  const style = document.querySelector(`[data-id="${id}"]`);
  if (style) {
    document.head.removeChild(style);
  }
}

function insertCssVarsToDocument(cssVars, id, selector) {
  if (!cssVars || !Object.keys(cssVars)) {
    return;
  }
  let cssText = `${selector}{`;
  for (const [key, value] of Object.entries(cssVars)) {
    cssText += `${key}:${value};`;
  }
  cssText += '}';

  insertStyleElement(cssText, id);
}

function insertCssToDocument(css, id) {
  if (!css) return;
  insertStyleElement(css, id);
}

export function insertThemeConfigToPage(mergedTheme, oldCss, scope) {
  const { base, agGridCssVars, css: themeCss } = mergedTheme;

  // 显示页面的主题到 html 根节点方便 debug
  if (scope === ':root') {
    document.documentElement.setAttribute('data-theme', base);
  }

  // css 变量写入页面给 vars.css 以及组件使用实现主题切换
  // 将 ant theme config 的 token 转换为 css 变量放到页面给 vars.css 配置，其余组件使用可以使用 vars.css 中的值来达到主题一致的效果
  const themeCssVars = mergeThemeCssVars(mergedTheme);
  const themeVarsStyleId = `${scope}-css-vars`;
  insertCssVarsToDocument(themeCssVars, themeVarsStyleId, scope);

  // ag-grid 的 css 变量写入页面给 vars.css 以及组件使用实现主题切换, 增加 selector 数量以优先级来覆盖 AgTable.css 里定义的变量
  const agVarsStyleId = `${scope}-ag-grid-css-vars`;
  insertCssVarsToDocument(agGridCssVars, agVarsStyleId, `${scope} div[class*='ag-theme-']`);

  // 自定义的全局 css 写入页面
  // theme css 包含 3 块内容:
  // 1. base theme 里预定义的 css;
  // 2. 在平台 themeConfig 里定义的 themeConfig.css;
  // 3. 以及旧的全局 css 设置 appConfig.css.
  const cssStyleId = `${scope}-theme-css`;
  insertCssToDocument(`${themeCss || ''}${oldCss || ''}`, cssStyleId);
}

export function removeThemeConfigFromPage(scope) {
  const themeVarsStyleId = `${scope}-css-vars`;
  const agVarsStyleId = `${scope}-ag-grid-css-vars`;
  const cssStyleId = `${scope}-theme-css`;

  document.documentElement.removeAttribute('data-theme');
  removeStyleElement(themeVarsStyleId);
  removeStyleElement(agVarsStyleId);
  removeStyleElement(cssStyleId);
}
