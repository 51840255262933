import dayjs from 'dayjs';

export default function setGanttCalendar({ gantt, calendarConfig }) {
  const copy = JSON.parse(JSON.stringify(calendarConfig));
  // worktime 属性就直接沿用 dhtmlx gantt  组件本身的 calendar 配置，只额外解析 date 为 Date 对象：https://docs.dhtmlx.com/gantt/api__gantt_addcalendar.html
  // holidays 属性是我们自定的配置，格式为数组循环调用 setWorkTime 的接口，只额外解析 date 为 Date 对象：https://docs.dhtmlx.com/gantt/api__gantt_setworktime.html
  const { worktime, holidays } = copy;

  if (worktime.customWeeks) {
    for (const [, value] of Object.entries(worktime.customWeeks)) {
      if (value.from) {
        value.from = dayjs(value.from).toDate();
      }
      if (value.to) {
        value.to = dayjs(value.to).toDate();
      }
    }
  }

  // 先清空之前设置的全局日历
  gantt.deleteCalendar('global');

  gantt.addCalendar({
    id: 'global', // global 表示 dhtmlx gantt 组件默认全局 work time
    worktime,
  });

  if (Array.isArray(holidays)) {
    for (const item of holidays) {
      if (item.date) {
        item.date = dayjs(item.date).toDate();
      }
      gantt.setWorkTime(item);
    }
  }

  gantt.render();
}
