import PropTypes from 'prop-types';
import { AgTable } from '@icp/components';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useEventCallback } from '@icp/hooks';
import { restApi } from '@icp/settings';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import ConnectorDialog from './ConnectorDialog';

function ConnectorList(props) {
  const { pbcId, connectorPbcList, canAdd = false, canEdit = false, canDelete = false } = props;

  const { t } = useTranslation(['icp-app', 'icp-form-renderer']);

  const [connectorTypeList, setConnectorTypeList] = useState(null);
  const [data, setData] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const gridRef = useRef(null);

  const fetchData = useEventCallback(() => {
    const fetchConnectorTypes = restApi
      .get('/flow/api/connector-type/list')
      .then(setConnectorTypeList);

    const fetchConnectors = Promise.all(
      connectorPbcList.map((pbc) => {
        return restApi.get(`/flow/api/connector/list-pbc-id/${pbc.id}`);
      }),
    ).then((connectorList) => {
      setData(connectorList.flatMap((x) => x));
    });

    return Promise.all([fetchConnectorTypes, fetchConnectors]);
  });

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleEdit = useEventCallback((row) => {
    setOpenDialog(row);
  });

  const handleSubmitSuccess = (res) => {
    if (openDialog?.id) {
      gridRef.current.api.applyTransaction({ update: [res] });
    } else {
      gridRef.current.api.applyTransaction({ add: [res] });
    }
    setOpenDialog(false);
  };

  const handleDelete = (ids) => {
    const selectedData = gridRef.current.api.getSelectedRows();
    const promises = [];
    for (const id of ids) {
      promises.push(restApi.delete(`/flow/api/connector/${id}`));
    }
    return Promise.all(promises).then(() => {
      gridRef.current.api.applyTransaction({ remove: selectedData });
    });
  };

  const columnDefs = useMemo(() => {
    return [
      {
        colId: 'name',
        field: 'name',
        headerName: t('connector.name'),
      },
      {
        colId: 'connectorTypeUuid',
        field: 'connectorTypeUuid',
        headerName: t('connector.connector-type'),
        valueFormatter: (params) => {
          if (!params.value) return params.value;
          return connectorTypeList.find((item) => item.uuid === params.value)?.name;
        },
      },
      {
        colId: 'baseUrl',
        field: 'baseUrl',
        headerName: 'baseUrl',
      },
      {
        colId: 'authType',
        field: 'authType',
        headerName: 'authType',
        valueFormatter: (params) => {
          if (params.value === 'BASIC_AUTH') return 'Basic Auth';
          if (params.value === 'API_KEY') return 'API Key';
          if (params.value === 'OAUTH_2') return 'OAuth 2.0';
          return '';
        },
      },
      canEdit
        ? {
            headerName: t('action.header-name', { ns: 'icp-form-renderer' }),
            cellRenderer: (params) => {
              return (
                <Button type="link" size="small" onClick={() => handleEdit(params.data)}>
                  {t('edit', { ns: 'icp-common' })}
                </Button>
              );
            },
          }
        : null,
    ].filter(Boolean);
  }, [canEdit, connectorTypeList, handleEdit, t]);

  return (
    <>
      <AgTable
        title={t('connector.list')}
        rowModelType="clientSide"
        rowSelection={canDelete ? 'multiple' : null}
        suppressAddButton={!canAdd}
        suppressDeleteButton={!canDelete}
        columnDefs={columnDefs}
        rowData={connectorTypeList ? data : null}
        getRowId={(params) => String(params.data.id)}
        onRefresh={() => fetchData()}
        onAddRow={() => setOpenDialog(true)}
        onDeleteRow={handleDelete}
        ref={gridRef}
      />
      {openDialog ? (
        <ConnectorDialog
          pbcId={openDialog.pbcId || pbcId}
          connector={typeof openDialog === 'object' ? openDialog : null}
          connectorTypeList={connectorTypeList}
          onSuccess={handleSubmitSuccess}
          onClose={() => {
            setOpenDialog(false);
          }}
        />
      ) : null}
    </>
  );
}

ConnectorList.propTypes = {
  pbcId: PropTypes.number,
  connectorPbcList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    }),
  ),
  canAdd: PropTypes.bool,
  canEdit: PropTypes.bool,
  canDelete: PropTypes.bool,
};

export default ConnectorList;
