import PropTypes from 'prop-types';
import clsx from 'clsx';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs, Tree } from 'antd';
import { Loading, Icon } from '@icp/components';
import { restApi } from '@icp/settings';
import { selectUserPermissionMap } from '@icp/form-renderer-core';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { useClassName } from '../hooks';
import { useSelector } from '../store';
import TableElement from './TableElement';

const fieldNames = { title: 'name', key: 'id', children: 'children' };

const OrgTreeElement = forwardRef(function OrgTreeElement(props, ref) {
  const { keyPath, id, className: classNameProp, style, componentProps = {} } = props;

  const { style: compStyle } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();
  const { t } = useTranslation(['icp-form-renderer']);

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const userPermissionMap = useSelector(selectUserPermissionMap);
  const roleCreatable = userPermissionMap?.ROLE_CREATE;
  const roleEditable = userPermissionMap?.ROLE_EDIT;
  const roleViewable = userPermissionMap?.ROLE_VIEW;
  const roleDeletable = userPermissionMap?.ROLE_DELETE;

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const [loading, setLoading] = useState(false);
  const [orgData, setOrgData] = useState(null);
  const [activeTab, setActiveTab] = useState('role');
  const [selectedOrg, setSelectedOrg] = useState(null);

  useEffect(() => {
    if (isInDesign) return () => {};
    const controller = new AbortController();
    const { signal } = controller;

    setLoading(true);
    restApi
      .get(`/form/api/v2/form-entity-data/get-tree-form-data/ORG`, { signal })
      .then((res) => setOrgData([res]))
      .finally(() => {
        setLoading(false);
      });

    return () => {
      controller.abort();
    };
  }, [isInDesign]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx('org-tree-element', 'form-element', className, classNameComp)}
        style={{ ...style, ...compStyle }}
        ref={nodeRef}
      >
        {loading && <Loading />}
        {!loading && (
          <>
            <div className="org-tree-container-left icp-thin-scrollbar">
              <Tree
                showLine={true}
                switcherIcon={
                  <div className="icp-center">
                    <Icon name="oct:triangle-down" color="gray" />
                  </div>
                }
                defaultExpandedKeys={orgData?.map((item) => item[fieldNames.key])}
                treeData={orgData}
                fieldNames={fieldNames}
                onSelect={(_, event) => setSelectedOrg(event.selectedNodes[0])}
              />
            </div>
            <div className="org-tree-container-right">
              <Tabs
                defaultActiveKey="role"
                value={activeTab}
                onChange={setActiveTab}
                items={[
                  { key: 'role', label: t('role', { ns: 'icp-common' }) },
                  // TODO 后端接口尚未实现
                  // { key: 'user', label: t('user', { ns: 'icp-common' }) },
                ]}
              />
              {!selectedOrg && (
                <div style={{ color: 'gray' }}>{t('org-tree.select-org-placeholder')}</div>
              )}
              {selectedOrg && activeTab === 'role' && (
                <TableElement
                  componentProps={{
                    dataUrl: `/user-management/api/role/list-by-org-token?orgId=${selectedOrg.id}&orgFormEntityPbcToken=${selectedOrg.pbcToken}&orgFormEntityToken=${selectedOrg.formEntityToken}`,
                    suppressAddButton: true,
                    toolbarFields: [
                      {
                        hidden: !roleCreatable,
                        component: 'Button',
                        componentProps: {
                          content: t('new', { ns: 'icp-common' }),
                          type: 'primary',
                          icon: 'oct:plus',
                          action: {
                            type: 'dialog',
                            openFormProps: {
                              submitMethod: 'post',
                              createUrl: '/user-management/api/role',
                              FormRendererProps: {
                                schema: ROLE_EDIT_SCHEMA(t),
                                readonly: !roleEditable,
                                defaultData: {
                                  orgs: [
                                    {
                                      orgFormEntityPbcToken: selectedOrg.pbcToken,
                                      orgFormEntityToken: selectedOrg.formEntityToken,
                                      value: `${selectedOrg.pbcToken}#@#${selectedOrg.formEntityToken}`,
                                    },
                                  ],
                                },
                              },
                            },
                            openDialogProps: {
                              size: 'lg',
                            },
                            successAction: {
                              type: 'refreshTable',
                            },
                          },
                        },
                      },
                    ],
                    columnDefs: [
                      {
                        type: 'TEXT_COLUMN',
                        colId: 'name',
                        field: 'name',
                        headerName: t('org-tree.role-name'),
                      },
                      {
                        type: 'TEXT_COLUMN',
                        colId: 'description',
                        field: 'description',
                        headerName: t('org-tree.role-description'),
                      },
                      {
                        colId: 'orgRoleUserCount',
                        type: 'ACTION_COLUMN',
                        headerName: t('org-tree.user-count'),
                        cellRendererParams: {
                          actions: [
                            {
                              hidden: !(roleEditable || roleViewable),
                              component: 'Button',
                              componentProps: {
                                type: 'text',
                                content: ':orgRoleUserCount',
                                icon: 'oct:pencil',
                                iconPosition: 'end',
                                action: {
                                  type: 'dialog',
                                  openFormProps: {
                                    disabled: !roleEditable,
                                    FormRendererProps: {
                                      readonly: !roleEditable,
                                    },
                                    // 这个schema不写死，因为选人的ACL列配置不同项目不同需要项目配置员按需配置
                                    schemaId: 'role-members-schema',
                                    submitMethod: 'put',
                                    retrieveUrl: `/user-management/api/org-role-member/${selectedOrg.id}/:id/user/form-data`,
                                    updateUrl: `/user-management/api/org-role-member/update-org-role-user/${selectedOrg.id}/:id?orgFormEntityPbcToken=${selectedOrg.pbcToken}&orgFormEntityToken=${selectedOrg.formEntityToken}`,
                                  },
                                  openDialogProps: {
                                    title: t('org-tree.role-members'),
                                  },
                                  successAction: {
                                    type: 'refreshTable',
                                  },
                                },
                              },
                            },
                          ],
                        },
                      },
                      {
                        colId: 'actions',
                        type: 'ACTION_COLUMN',
                        width: 240,
                        cellRendererParams: {
                          displayLikeButton: true,
                          showIcon: true,
                          actions: [
                            {
                              hidden: !(roleEditable || roleViewable),
                              component: 'Button',
                              componentProps: {
                                content: roleEditable
                                  ? t('edit', { ns: 'icp-common' })
                                  : t('view', { ns: 'icp-common' }),
                                icon: 'oct:pencil',
                                action: {
                                  type: 'dialog',
                                  openFormProps: {
                                    disabled: !roleEditable,
                                    submitMethod: 'put',
                                    retrieveUrl: '/user-management/api/role/:id',
                                    updateUrl: '/user-management/api/role/:id',
                                    FormRendererProps: {
                                      schema: ROLE_EDIT_SCHEMA(t),
                                      readonly: !roleEditable,
                                      transformRetrievedData: `(function (data) {
                                        data.orgs = (data.orgs || []).map(function (org) {
                                          return Object.assign({}, org, { value: org.orgFormEntityPbcToken + '#@#' + org.orgFormEntityToken });
                                        });
                                        return data;
                                      })(this)`,
                                    },
                                  },
                                  openDialogProps: {
                                    size: 'lg',
                                  },
                                  successAction: {
                                    type: 'refreshTable',
                                  },
                                },
                              },
                            },
                            {
                              type: 'DELETE',
                              hidden: !roleDeletable,
                              url: '/user-management/api/role/:id',
                            },
                          ],
                        },
                      },
                    ],
                  }}
                />
              )}
            </div>
          </>
        )}
      </div>
    </ElementDecorator>
  );
});

OrgTreeElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({
    className: PropTypes.string,
    style: PropTypes.shape({}),
  }),
};

export default OrgTreeElement;

const ROLE_EDIT_SCHEMA = (t) =>
  JSON.parse(`
{
  "form": {
    "labelLayout": "vertical"
  },
  "fields": [
    {
      "component": "Stack",
      "componentProps": {
        "flexDirection": "column"
      },
      "fields": [
        {
          "component": "Stack",
          "componentProps": {
            "flexDirection": "row"
          },
          "fields": [
            {
              "id": "name",
              "title": "${t('org-tree.role-name', { ns: 'icp-form-renderer' })}",
              "component": "Input",
              "componentProps": {
                "placeholder": "${t('org-tree.role-name-placeholder', { ns: 'icp-form-renderer' })}"
              },
              "validation": {
                "required": true,
                "maxLength": 200
              }
            },
            {
              "id": "description",
              "title": "${t('org-tree.role-description', { ns: 'icp-form-renderer' })}",
              "component": "Input",
              "componentProps": {
                "placeholder": "${t('org-tree.role-description-placeholder', { ns: 'icp-form-renderer' })}"
              },
              "validation": {
                "maxLength": 200
              }
            },
            {
              "id": "orgs",
              "title": "${t('org', { ns: 'icp-common' })}",
              "component": "TreeSelect",
              "componentProps": {
                "multiple": true,
                "dataUrl": "/form/api/v2/form-entity-data/get-tree-form-structure/ORG",
                "dataResponseKeyPath": "",
                "transformDataResponse": "function formatChild(child) { child.composedToken = child.pbcToken + '#@#' + child.token; if (child.children) { child.children.forEach(formatChild) } }; formatChild(this); [this]; ",
                "mapping": {
                  "orgFormEntityPbcToken": "pbcToken",
                  "orgFormEntityToken": "token",
                  "value": "composedToken",
                  "label": "name"
                }
              },
              "validation": {
                "required": true
              }
            }
          ]
        },
        {
          "component": "Tabs",
          "componentProps": {
            "suppressUrlSearchStorage": true,
            "defaultActiveKey": "access-permission",
            "items": [
              {
                "key": "access-permission",
                "label": "${t('org-tree.access-permission', { ns: 'icp-form-renderer' })}",
                "fields": [
                  {
                    "id": "permissions",
                    "component": "Permissions"
                  }
                ]
              },
              {
                "key": "data-permission",
                "label": "${t('org-tree.data-permission', { ns: 'icp-form-renderer' })}",
                "fields": [
                  {
                    "id": "scope",
                    "style": {
                      "width": "100%"
                    },
                    "component": "Radio",
                    "componentProps": {
                      "style": {
                        "background": "rgba(245, 248, 250, 1)",
                        "borderRadius": 8,
                        "width": "100%",
                        "height": 60,
                        "display": "flex",
                        "alignItems": "center",
                        "paddingLeft": 20
                      },
                      "direction": "horizontal",
                      "options": [
                        {
                          "label": "${t('org-tree.role-scope-1', { ns: 'icp-form-renderer' })}",
                          "value": 1
                        },
                        {
                          "label": "${t('org-tree.role-scope-3', { ns: 'icp-form-renderer' })}",
                          "value": 3
                        }
                      ]
                    },
                    "defaultValue": 1
                  }
                ]
              }
            ]
          }
        }
      ]
    }
  ]
}
`);

/*
  role-members-schema example:

{
  "fields": [
    {
      "id": "roleMembers",
      "component": "ACL",
      "componentProps": {
        "idListUrl": "/user-management/api/user/ids",
        "dataUrl": "/user-management/api/user/lite/list-by-ids",
        "useOriginValue": true,
        "mapping": {
          "value": "id",
          "label": "nickName"
        },
        "multiple": true,
        "unit": "人",
        "columnDefs": [
          {
            "colId": "nickName",
            "field": "nickName",
            "headerName": "姓名"
          },
          {
            "colId": "username",
            "field": "username",
            "headerName": "账号"
          },
          {
            "colId": "gender",
            "field": "gender",
            "headerName": "性别"
          }
        ]
      }
    }
  ]
}
*/
