import { useMemo, useState } from 'react';
import getFilterValueText from './getFilterValueText';
import { getFilterTypeIcon } from './utils';

export default function useFilterItemDisplayText(context, model, colDef) {
  const icon = getFilterTypeIcon(model.filterType);
  const [asyncText, setAsyncText] = useState('');

  const syncText = useMemo(() => {
    const result = getFilterValueText(context, model, colDef);

    // 异步结果延迟显示
    if (result instanceof Promise) {
      result.then(setAsyncText);
      return '';
    }

    // 同步结果直接显示
    return result;
  }, [context, colDef, model]);

  const displayText = syncText || asyncText;

  if (!model || !colDef) {
    return null;
  }

  return (
    <>
      {icon}
      <span>{`${colDef.headerName}${displayText ? ': ' : ''}`}</span>
      {displayText}
    </>
  );
}
