import { Swiper } from 'antd-mobile';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { shouldTranslateByDefault } from '@icp/settings';
import { Loading } from '@icp/components';
import { useElementDecorator, useIsInDesign } from '../FormRenderCtx';
import { useClassName, useDataSource } from '../hooks';
import RecursionRenderer from '../RecursionRenderer/RecursionRenderer';
import { withFieldWrapper } from '../fieldWrapper';
import { CurrentDataProvider } from '../currentDataCtx';

const MOCK_ITEMS = ['#ace0ff', '#bcffbd', '#e4fabd', '#ffcfac', '#ffd6e7'];

const SwiperElement = forwardRef(function SwiperElement(props, ref) {
  const { keyPath, id, className: classNameProp, style, fields = [], componentProps = {} } = props;

  const {
    showIndicator = false,
    autoplay = true,
    autoplayInterval = 5,
    direction = 'horizontal',
    style: swiperStyle,
    dataSource,
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
    values,
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();
  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const { loading, dataFetched } = useDataSource({
    skip: isInDesign || Array.isArray(values),
    dataSource,
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
  });

  const items =
    isInDesign && fields.length === 0
      ? MOCK_ITEMS.map((color, index) => (
          <Swiper.Item key={index}>
            <div className="mock-item" style={{ background: color }}>
              {index + 1}
            </div>
          </Swiper.Item>
        ))
      : (values || dataFetched?.results || (isInDesign && MOCK_ITEMS) || []).map(
          (itemValue, index) => (
            <Swiper.Item key={itemValue.id || index}>
              <CurrentDataProvider value={itemValue}>
                <RecursionRenderer fields={fields} keyPath={keyPath.concat('fields')} />
              </CurrentDataProvider>
            </Swiper.Item>
          ),
        );

  const indicatorProp = !showIndicator ? { indicator: () => null } : {};

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div className={clsx('swiper-element form-element', className)} style={style} ref={nodeRef}>
        {loading ? <Loading /> : null}
        {!loading && (
          <Swiper
            className={classNameComp}
            style={swiperStyle}
            loop={autoplay}
            autoplay={autoplay}
            autoplayInterval={autoplayInterval * 1000}
            direction={direction}
            {...indicatorProp}
          >
            {items}
          </Swiper>
        )}
      </div>
    </ElementDecorator>
  );
});

SwiperElement.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  valueField: PropTypes.string,
  className: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  componentProps: PropTypes.shape({}),
};

SwiperElement.displayName = 'Swiper';

export default withFieldWrapper(SwiperElement);
