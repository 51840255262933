import { useDataSource } from './index';
import useDataSourceLazy from './useDataSourceLazy';

export default function useDataSourceSupportLazy(props) {
  const { skip, lazy, ...config } = props;

  const { loading, dataFetched: clientSideData } = useDataSource({
    ...config,
    skip: skip || lazy,
  });

  const {
    freshLoading,
    moreLoading,
    dataFetched: serverSideData,
    hasMore,
    loadMore,
  } = useDataSourceLazy({
    ...config,
    skip: skip || !lazy,
  });

  const dataFetched = lazy ? serverSideData : clientSideData;

  return {
    loading: loading || freshLoading,
    moreLoading,
    dataFetched,
    hasMore,
    loadMore,
  };
}
