import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { isIndex } from '@icp/utils';
import NumberPickerElement from '../../NumberPickerElement';
import { CurrentDataProvider } from '../../../currentDataCtx';

function NumberCellEditor(props) {
  const { value, onValueChange, cellStartedEdit, eventKey, data, context, componentProps } = props;

  const refInput = useRef(null);

  useEffect(() => {
    let highlightAllOnFocus = true;

    if (isIndex(eventKey)) {
      highlightAllOnFocus = false;
      onValueChange(Number(eventKey));
    }

    if (highlightAllOnFocus) {
      refInput.current.node.querySelector('input').select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentDataProvider value={data}>
      <NumberPickerElement
        className="table-number-editor"
        suppressFormControl={true}
        value={value}
        onChange={onValueChange}
        componentProps={{
          size: context.tableSize,
          autoFocus: cellStartedEdit,
          ...componentProps,
        }}
        ref={refInput}
      />
    </CurrentDataProvider>
  );
}

NumberCellEditor.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onValueChange: PropTypes.func,
  cellStartedEdit: PropTypes.bool,
  eventKey: PropTypes.string,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
  }),
  componentProps: PropTypes.shape({
    precision: PropTypes.number,
  }),
};

export default NumberCellEditor;
