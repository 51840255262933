import { useRef, useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Popover from '../Popover';

function ToolbarSelect(props) {
  const { children, value, options = [], onChange } = props;

  const [open, setOpen] = useState(false);

  const anchorEl = useRef(null);

  return (
    <>
      <button className="icp-action-button" onClick={() => setOpen(true)} ref={anchorEl}>
        {children || options.find((op) => op.value === value)?.label}
        <Icon name="oct:chevron-down" size={12} />
      </button>
      <Popover
        className="icp-table-menu"
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
        style={{ minWidth: Math.max(140, anchorEl.current?.offsetWidth || 0) }}
      >
        {options.map((op) => {
          return (
            <div
              key={op.value}
              className={clsx('item icp-clickable', { selected: op.value === value })}
              onClick={() => {
                setOpen(false);
                onChange(op.value);
              }}
            >
              {op.label}
            </div>
          );
        })}
      </Popover>
    </>
  );
}

ToolbarSelect.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
};

export default ToolbarSelect;
