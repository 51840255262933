import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import Icon from '../../Icon';
import Popover from '../../Popover';

function FavoriteViewItem(props) {
  const {
    modal,
    isCurrent,
    currentLabel,
    isCurrentDirty,
    view,
    onSwitch,
    onSync,
    onRename,
    onDelete,
  } = props;

  const { t } = useTranslation('icp-components');

  const [open, setOpen] = useState(false);

  const anchorEl = useRef(null);

  const handleDelete = () => {
    modal.confirm({
      title: t('toolbar.favorite-view.delete.title'),
      content: t('toolbar.favorite-view.delete.desc', { name: view.name }),
      onOk: () => {
        setOpen(false);
        onDelete();
      },
    });
  };

  return (
    <>
      <div
        className={clsx('item icp-clickable', { selected: isCurrent })}
        onClick={() => onSwitch(view)}
      >
        <span>{isCurrent ? currentLabel : view.name}</span>
        <button
          className="icp-action-button"
          onClick={(event) => {
            event.stopPropagation();
            setOpen(true);
          }}
          ref={anchorEl}
        >
          <Icon name="oct:kebab-horizontal" size={12} />
        </button>
      </div>
      <Popover
        className="icp-table-menu"
        placement="bottom-end"
        anchorEl={anchorEl.current}
        open={open}
        onClose={() => setOpen(false)}
        onClick={() => setOpen(false)}
        style={{ minWidth: 0 }}
      >
        {isCurrent && isCurrentDirty ? (
          <div className="item icp-clickable" onClick={onSync}>
            <Icon name="oct:sync" size={16} />
            {t('toolbar.favorite-view.item.sync')}
          </div>
        ) : null}
        <div className="item icp-clickable" onClick={onRename}>
          <Icon name="solar:pen-bold-duotone" size={16} />
          {t('toolbar.favorite-view.item.rename')}
        </div>
        <div className="item icp-clickable" onClick={handleDelete}>
          <Icon
            name="solar:trash-bin-minimalistic-bold-duotone"
            size={24}
            style={{ width: 16, height: 16 }}
          />
          {t('toolbar.favorite-view.item.delete')}
        </div>
      </Popover>
    </>
  );
}

FavoriteViewItem.propTypes = {
  modal: PropTypes.shape({
    confirm: PropTypes.func,
  }),
  isCurrent: PropTypes.bool,
  isCurrentDirty: PropTypes.bool,
  currentLabel: PropTypes.node,
  view: PropTypes.shape({
    name: PropTypes.string,
  }),
  onSwitch: PropTypes.func,
  onSync: PropTypes.func,
  onRename: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FavoriteViewItem;
