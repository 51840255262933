import isIndex from './isIndex';

/**
 * Simple get like lodash get, but require keyPath must be array
 * @param object
 * @param keyPath
 * @returns {undefined|*}
 *
 * simpleGet({ a: 'aa' }, ['a'])
 * // => 'aa'
 *
 * simpleGet({ a: 'aa' }, ['a', 'b'])
 * // => undefined
 */
function simpleGet(object, keyPath) {
  if (!Array.isArray(keyPath)) {
    return undefined;
  }

  let nested = object;
  for (const key of keyPath) {
    if (!nested[key]) {
      return undefined;
    }
    nested = nested[key];
  }

  return nested;
}

/**
 * Simple clone like lodash clone, but only clone array or object
 * @param object
 */
function simpleClone(object) {
  if (Array.isArray(object)) {
    return [...object];
  }
  if (Object.prototype.toString.call(object) === '[object Object]') {
    return { ...object };
  }
  return object;
}

function immutableOperate(object, keyPath, callback) {
  if (!Array.isArray(keyPath)) {
    return object;
  }

  const newObject = simpleClone(object);

  const { length } = keyPath;
  const lastIndex = length - 1;
  const lastKey = keyPath[lastIndex];

  for (let index = 0, nested = newObject; index < length; index++) {
    const key = keyPath[index];

    if (index === lastIndex) {
      callback(nested, lastKey);
    } else {
      nested[key] = simpleClone(nested[key]);

      if (nested[key] === undefined) {
        nested[key] = isIndex(keyPath[index + 1]) ? [] : {};
      }

      nested = nested[key];
    }
  }

  return newObject;
}

/**
 * Splice function similar to Array.prototype.splice,
 * keyPath[keyPath.length - 1] must be an index of an array.
 * @param object
 * @param keyPath
 * @param deleteCount
 * @param items
 * @returns {*}
 */
export function immutableSplice(object, keyPath, deleteCount, ...items) {
  const simpleSplice = (nested, lastKey) => {
    if (!Array.isArray(nested) || !isIndex(lastKey) || lastKey < 0) {
      return;
    }

    nested.splice(lastKey, deleteCount, ...items);
  };
  return immutableOperate(object, keyPath, simpleSplice);
}

/**
 * Set object data immutably
 * @param object
 * @param keyPath
 * @param newValue
 * @returns {Object}
 * @example
 *
 * immutableSet({}, ['a', 0], 'value');
 * // => { a: ['a'] }
 */
export function immutableSet(object, keyPath, newValue) {
  return immutableOperate(object, keyPath, (nested, lastKey) => {
    nested[lastKey] = newValue;
  });
}

/**
 * Remove the property ant keyPath of object immutably
 * @param object
 * @param keyPath
 * @returns {Object}
 * @example
 *
 * immutableUnset({ a: { b: 1, c: 2 } }, ['a', 'b']);
 * // => { a: { c: 2 }  }
 */
export function immutableUnset(object, keyPath) {
  return immutableOperate(object, keyPath, (nested, lastKey) => {
    delete nested[lastKey];
  });
}

/**
 * Insert item(s) at path of object. keyPath[keyPath.length - 1] must be an index of an array.
 * @param object
 * @param keyPath
 * @param items
 * @returns {*}
 * @example
 *
 * immutableInsert({ a: { b: ['b'] } }, ['a', 'b', 0], 'value')
 * // => { a: b: ['value', 'b'] }
 */
export function immutableInsert(object, keyPath, ...items) {
  return immutableSplice(object, keyPath, 0, ...items);
}

/**
 * Delete item(s) at path of object. keyPath[keyPath.length - 1] must be an index of an array.
 * @param object
 * @param keyPath
 * @param deleteCount
 * @returns {*}
 * @example
 *
 * immutableDelete({ a: { b: ['value'] }, ['a', 'b', 0] })
 * // => { a: { b: [] } }
 */
export function immutableDelete(object, keyPath, deleteCount = 1) {
  return immutableSplice(object, keyPath, deleteCount);
}

/**
 * Append an item at path of object. keyPath[keyPath.length - 1] must be an index of an array.
 * @param object
 * @param keyPath
 * @param items
 * @returns {*}
 */
export function immutablePush(object, keyPath, ...items) {
  const arr = simpleGet(object, keyPath);
  return immutableInsert(object, keyPath.concat(Array.isArray(arr) ? arr.length : 0), ...items);
}

export function immutableMove(items, fromIndex, toIndex) {
  const newItems = [...items];
  const startItem = newItems[fromIndex];
  newItems.splice(fromIndex, 1);
  newItems.splice(toIndex, 0, startItem);
  return newItems;
}
