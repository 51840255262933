import clsx from 'clsx';
import { FORM_TYPE_FLOW_APPROVAL } from '@icp/page-renderer-core';
import loadable from '@loadable/component';
import { selectContext, setFieldValue } from '@icp/form-renderer-core';
import { useEffect, useMemo, useRef, useState } from 'react';
import { resolveVariablePattern } from '@icp/utils';
import { message } from '@icp/settings';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormApi } from '../../FormRenderCtx';
import { useDispatch, useSelector } from '../../store';
import { CurrentDataProvider, useCurrentData } from '../../currentDataCtx';
import { DialogContextProvider } from '../../dialogCtx';
import { removePrevContext } from './utils';
import DialogUI from './DialogUI';
import { useVariablePattern } from '../../hooks';

const PageRenderer = loadable(() =>
  import(
    // eslint-disable-next-line import/no-unresolved
    '@icp/page-renderer'
  ).catch(() => ({
    default: function ErrBoundary() {
      console.error(`"@icp/page-renderer" not installed`);
      return null;
    },
  })),
);

const EMPTY_OBJECT = {};

function OpenDialog(props) {
  const { action, onSuccess, onClose } = props;
  const { openFormProps, openDialogProps = EMPTY_OBJECT } = action;

  const { t } = useTranslation(['icp-components']);
  const params = useParams();
  const dispatch = useDispatch();
  const context = useSelector(selectContext);
  const formApi = useFormApi();
  const currentData = useCurrentData();
  const titleProp = useVariablePattern(openDialogProps.title);

  const [submitting, setSubmitting] = useState(false);
  const [openContentTitle, setOpenContentTitle] = useState('');

  const openFormRef = useRef(null);
  const isUnMount = useRef(false);

  const contextToDialog = useMemo(() => {
    return { ...removePrevContext(context), isInDialog: true };
  }, [context]);

  useEffect(() => {
    return () => {
      isUnMount.current = true;
    };
  }, []);

  useEffect(() => {
    setOpenContentTitle('');
  }, [openDialogProps]);

  const handleDialogOk = () => {
    if (!openFormRef.current?.submit) return;
    setSubmitting(true);

    ({
      default: () => openFormRef.current.submit(),
      modifyFormData: () =>
        openFormRef.current.submit({
          handler: (data) => {
            if (!data) return;
            // 浅合并到当前表单数据
            Object.entries(data).forEach(([k, v]) => {
              dispatch(setFieldValue({ keyPath: k, value: v }));
            });
          },
        }),
    })
      [action.behavior || 'default']?.()
      .catch((err) => {
        if (!isUnMount.current) {
          setSubmitting(false);
        }
        return Promise.reject(err);
      });
  };

  const handleDialogFormSubmitted = (res) => {
    setSubmitting(false);
    if (action.msg) {
      const formData = formApi.getData();
      const msg = resolveVariablePattern({
        context,
        currentData: currentData || formData,
        formData,
        params,
        response: res,
        pattern: action.msg,
        failWhenNoVariableValue: true,
      });
      if (msg) {
        message.success(msg);
      }
    }

    onSuccess(res);
  };

  const handleDialogClose = (event) => {
    const isDirty = () => {
      if (typeof openFormRef.current?.isDirty !== 'function') {
        return false;
      }
      return openFormRef.current.isDirty();
    };
    if (
      !isDirty() ||
      event?.state === 'success' ||
      // eslint-disable-next-line no-alert
      window.confirm(t('confirm.leave.message', { ns: 'icp-components' }))
    ) {
      onClose(event);
    }
  };

  return (
    <DialogUI
      {...openDialogProps}
      open={true}
      className={clsx(
        openFormProps.formType === FORM_TYPE_FLOW_APPROVAL ? 'approval-dialog' : '',
        openDialogProps.className,
      )}
      title={openContentTitle || titleProp}
      submitting={submitting}
      // 审批页面会自带按钮，此时强制隐藏 footer
      hideFooter={
        openDialogProps.hideFooter ||
        openFormProps.formType === FORM_TYPE_FLOW_APPROVAL ||
        openFormProps.disabled
      }
      onOk={handleDialogOk}
      onClose={handleDialogClose}
    >
      <DialogContextProvider value={{ close: handleDialogClose }}>
        <CurrentDataProvider value={undefined}>
          <PageRenderer
            {...openFormProps}
            FormRendererProps={{
              // 如果 openFormProps?.FormRendererProps.defaultData 有值则优先使用
              ...openFormProps?.FormRendererProps,
              onSubmitted: handleDialogFormSubmitted,
            }}
            context={contextToDialog}
            ref={(r) => {
              // 如果配置了 title，以配置的 title 为主，同时兼容老数据和手写 json 只写了 title 没有配 disableUseOpenContentTitle 的情况
              // 如果没有配置 title，才自动读取打开弹窗页面/表单的 title 作为弹窗的 title。
              if (
                !openDialogProps.title &&
                !openDialogProps.disableUseOpenContentTitle &&
                r &&
                r.on &&
                r.getTitle
              ) {
                r.on('firstRendered', () => {
                  setOpenContentTitle(r.getTitle());
                });
              }
              openFormRef.current = r;
            }}
          />
        </CurrentDataProvider>
      </DialogContextProvider>
    </DialogUI>
  );
}

OpenDialog.propTypes = {
  action: PropTypes.shape({
    behavior: PropTypes.oneOf(['default', 'modifyFormData']),
    msg: PropTypes.string,
    openFormProps: PropTypes.shape({
      formType: PropTypes.string,
      disabled: PropTypes.bool,
      FormRendererProps: PropTypes.shape({}),
    }),
    openDialogProps: PropTypes.shape({
      size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'fullscreen']),
      width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      className: PropTypes.string,
      hideFooter: PropTypes.bool,
      cancelProps: PropTypes.shape({
        // @deprecated
        iconName: PropTypes.string,
        icon: PropTypes.string,
      }),
      cancelText: PropTypes.string,
      okProps: PropTypes.shape({
        // @deprecated
        iconName: PropTypes.string,
        icon: PropTypes.string,
      }),
      okText: PropTypes.string,
      title: PropTypes.string,
      disableUseOpenContentTitle: PropTypes.bool,
    }),
  }),
  onSuccess: PropTypes.func,
  onClose: PropTypes.func,
};

export default OpenDialog;
