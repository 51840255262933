import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Icon } from '@icp/components';
import ButtonUI from '../../ButtonElement/ButtonUI';

function ACLImportExport(props) {
  const { componentLibrary, supportImport, supportExport, size, onImport, onExport } = props;

  const { t } = useTranslation(['icp-components', 'icp-common']);

  const [anchorEl, setAnchorEl] = useState(false);

  const open = !!anchorEl;

  if (componentLibrary === 'material-ui') {
    return (
      <>
        <ButtonUI
          icon="material:more-vert-rounded"
          size={size}
          type="text"
          onClick={(event) => setAnchorEl(event.currentTarget)}
        />
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          onClick={() => setAnchorEl(null)}
          MenuListProps={{
            sx: { minWidth: 140 },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          {supportImport ? (
            <MenuItem onClick={onImport}>
              <ListItemIcon>
                <Icon name="solar:import-bold" size={20} />
              </ListItemIcon>
              {t('import', { ns: 'icp-common' })}
            </MenuItem>
          ) : null}
          {supportExport ? (
            <MenuItem onClick={onExport}>
              <ListItemIcon>
                <Icon name="solar:export-bold" size={20} />
              </ListItemIcon>
              {t('export', { ns: 'icp-common' })}
            </MenuItem>
          ) : null}
        </Menu>
      </>
    );
  }

  return (
    <Dropdown
      menu={{
        items: [
          supportImport
            ? {
                label: (
                  <>
                    <Icon name="excel" size={16} style={{ marginRight: 8 }} />
                    {t('import', { ns: 'icp-common' })}
                  </>
                ),
                key: 'import',
              }
            : null,
          supportExport
            ? {
                label: (
                  <>
                    <Icon name="excel" size={16} style={{ marginRight: 8 }} />
                    {t('export', { ns: 'icp-common' })}
                  </>
                ),
                key: 'export',
              }
            : null,
        ].filter(Boolean),
        onClick: (event) => {
          if (event.key === 'import') {
            onImport();
          } else if (event.key === 'export') {
            onExport();
          }
        },
      }}
      trigger={['click']}
    >
      <ButtonUI icon="material:more-vert-rounded" size={size} type="text" />
    </Dropdown>
  );

  /* return (
    <Space>
      {supportImport && (
        <Button
          size={size}
          icon={<Icon name="excel" size={16} />}
          disabled={disabled}
          loading={importLoading}
          onClick={onImport}
        >
          {t('import', { ns: 'icp-common' })}
        </Button>
      )}
      {supportExport && (
        <Button
          size={size}
          icon={<Icon name="excel" size={16} />}
          onClick={onExport}
          disabled={disabled}
          loading={exportLoading}
        >
          {t('export', { ns: 'icp-common' })}
        </Button>
      )}
    </Space>
  ); */
}

ACLImportExport.propTypes = {
  componentLibrary: PropTypes.string,
  supportImport: PropTypes.bool,
  supportExport: PropTypes.bool,
  size: PropTypes.string,
  onImport: PropTypes.func,
  onExport: PropTypes.func,
};

export default ACLImportExport;
