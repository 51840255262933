import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react';
import { isEqual } from 'lodash-es';
import { debounce } from '@icp/utils';
import { AgTable } from '@icp/components';
import { resolveIdsFromRequest, setSelectedIfNeed } from './utils';
import ACLDataSource from './ACLDataSource';

const ACLTableServerSideTwoUrl = forwardRef(function ACLTableServerSideTwoUrl(props, ref) {
  const {
    aclDataSource,
    position,
    multiple,
    mapping,
    selectedKeys = [],
    onSelectionChanged,
    onLoadTotalCount,
    ...other
  } = props;

  // First load all ids, then load rows detail when scroll
  const ids = useRef(null);
  // allIds backup use re-calculate ids when transfer happens
  const allIdsBackup = useRef(null);
  const gridRef = useRef(null);
  // If no filter, ids will equal to selectedKeys
  const hasFilter = useRef(false);
  const hasSort = useRef(false);
  const prevRequest = useRef({});
  // flag to suppress ag-grid update warning when is unmount
  const isUnMount = useRef(false);

  useImperativeHandle(ref, () => ({
    getSelectedKeys: () => {
      const { selectAll, toggledNodes } = gridRef.current.api.getServerSideSelectionState();
      if (selectAll) {
        return ids.current.filter((id) => !toggledNodes.includes(id));
      }
      return toggledNodes;
    },
    deselectAll: () => {
      gridRef.current.api.deselectAll();
    },
  }));

  const calculateIds = useCallback(() => {
    if (!position) {
      // is single select, not in transfer
      ids.current = allIdsBackup.current;
      return;
    }

    if (position === 'left') {
      // is transfer left table.
      // ids are allIds excluding selectedKeys
      if (allIdsBackup.current) {
        ids.current = allIdsBackup.current.filter((id) => !selectedKeys.includes(id));
      }
    } else if (position === 'right') {
      // is transfer right table.
      if (hasFilter.current || hasSort.current) {
        // if there is request.filterModel, ids is selectedKeys which contains in allIds.
        if (allIdsBackup.current) {
          ids.current = allIdsBackup.current.filter((id) => selectedKeys.includes(id));
        }
      } else {
        // If no request.filterModel, ids is selectedKeys,
        ids.current = selectedKeys;
      }
    }
  }, [position, selectedKeys]);

  useEffect(() => {
    return () => {
      isUnMount.current = true;
    };
  }, []);

  useEffect(() => {
    // selectedKeys change means init or transfer happens
    calculateIds();
    if (gridRef.current?.api) {
      // It seems like ag-grid with purge=false will never not send new request where there is no data.
      const purge = gridRef.current.api.getDisplayedRowCount() === 0;
      gridRef.current.api.refreshServerSide({ purge });
      gridRef.current.api.showLoadingOverlay();
    }
  }, [calculateIds, selectedKeys]);

  const initIds = (request) => {
    if (position === 'right' && !hasFilter.current && !hasSort.current) {
      calculateIds();
      return Promise.resolve();
    }
    return aclDataSource.fetchAllIds(request).then((allIds) => {
      allIdsBackup.current = allIds;
      calculateIds();
      if (onLoadTotalCount) {
        onLoadTotalCount(allIds.length);
      }
    });
  };

  const getRowsData = (request) => {
    const fetchRowsData = () => {
      const idsToLoad = resolveIdsFromRequest(request, ids.current);
      return aclDataSource.fetchRowsByIds(idsToLoad);
    };

    const { filterModel, searchText, sortModel } = request;

    hasFilter.current = Boolean((Array.isArray(filterModel) && filterModel.length) || searchText);
    hasSort.current = Boolean(sortModel && sortModel.length);

    if (
      !isEqual(filterModel, prevRequest.current.filterModel) ||
      !isEqual(searchText, prevRequest.current.searchText) ||
      !isEqual(sortModel, prevRequest.current.sortModel)
    ) {
      // Clear to trigger fetchAllIds
      ids.current = null;
      prevRequest.current = request;
    }

    if (!ids.current) {
      return initIds(request).then(fetchRowsData);
    }
    return fetchRowsData();
  };

  const getRows = (request) => {
    return getRowsData(request).then((data) => {
      if (!data) {
        return Promise.reject();
      }

      const count = ids.current.length;

      setSelectedIfNeed(multiple, selectedKeys, gridRef.current.api);

      if (multiple && request.startRow === 0) {
        // auto size columns width according its content on the first data loaded
        gridRef.current.api.autoSizeAllColumns();
      }

      return { rowData: data, rowCount: count };
    });
  };

  const handleSelectionChanged = debounce((params) => {
    const serverSideSelectionState = params.api.getServerSideSelectionState();
    const { selectAll, toggledNodes } = serverSideSelectionState;

    let hasSelect;

    if (selectAll) {
      hasSelect = toggledNodes.length !== ids.current.length;
    } else {
      hasSelect = toggledNodes.length;
    }
    if (onSelectionChanged) {
      onSelectionChanged(hasSelect);
    }
  }, 50);

  return (
    <AgTable
      cacheBlockSize={30}
      suppressContextMenu={true}
      {...other}
      rowModelType="serverSide"
      getRows={getRows}
      getRowId={(params) => String(params.data[mapping.value] ?? params.data.id)}
      rowSelection={multiple ? 'multiple' : 'single'}
      isRowSelectable={(params) => !!params.data?.[mapping.value]}
      onSelectionChanged={handleSelectionChanged}
      ref={gridRef}
    />
  );
});

ACLTableServerSideTwoUrl.propTypes = {
  aclDataSource: PropTypes.instanceOf(ACLDataSource),
  position: PropTypes.oneOf(['left', 'right']),
  multiple: PropTypes.bool,
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  selectedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onSelectionChanged: PropTypes.func,
  onLoadTotalCount: PropTypes.func,
};

export default ACLTableServerSideTwoUrl;
