import { cloneDeep } from 'lodash-es';
import { INPUT_ELEMENT_ENUM } from '@icp/form-schema';
import cardWithCollapse from './templages/CardWithCollapse.json';
import cardWithGrid from './templages/CardWithGrid.json';
import stepsWithCard from './templages/8.json';
import userProfileCard from './templages/14.json';

export const Styles = {
  CardWithCollapse: 'CardWithCollapse',
  CardWithGrid: 'CardWithGrid',
  StepsWithCard: '8',
  UserProfileCard: '14',
};

const createNodeMap = {
  CardWithCollapse: createNodeForCardWithCollapse,
  CardWithGrid: createNodeForCardWithGrid,
  8: createNodeForStepsWithCard,
  14: createNodeForUserProfileCard,
};

const wrapperMap = {
  8: wrapForStepsWithCard,
  14: wrapForUserProfileCard,
};

const containerTypes = ['Grid', 'Box', 'Card'];

export function stylizeFields(style, layout) {
  const sections = [];
  // clone layout fields in order to make fields properties mutable.
  const layoutFields = cloneDeep(layout.fields);
  let groupFields = [];
  for (const field of layoutFields) {
    if (!containerTypes.includes(field.component)) {
      groupFields.push(field);
      continue;
    }
    if (groupFields.length > 0) {
      addFieldsToSections({ fields: groupFields }, sections, style);
      groupFields = [];
    }
    addFieldsToSections(field, sections, style);
  }
  if (groupFields.length > 0) {
    addFieldsToSections({ fields: groupFields }, sections, style);
  }
  return wrapperMap[style] ? wrapperMap[style](sections) : sections;
}

function addFieldsToSections(node, sections, style) {
  if (
    findAllLeaves(node).some((leaf) => INPUT_ELEMENT_ENUM.includes(leaf.component) && !leaf.hidden)
  ) {
    const title = {
      id: node.id || `sectionTitle${sections.length + 1}`,
      label: node.title || `S-${sections.length + 1}`,
    };
    sections.push(createNodeMap[style]?.(title, node.fields));
  } else if (node.component) {
    sections.push(node);
  } else {
    // add fields directly if there's no input components.
    node.fields.forEach((field) => sections.push(field));
  }
}

function findAllLeaves(root) {
  const queue = [root];
  const leaves = [];
  let start = 0;
  let end = 1;
  while (start < end) {
    const node = queue[start];
    const children = node.fields || node.componentProps?.items;
    if (children && children.length > 0) {
      for (const field of children) {
        queue.push(field);
        end += 1;
      }
    } else {
      leaves.push(node);
    }
    start += 1;
  }
  return leaves;
}

function findNode(node, predicate) {
  if (node && predicate(node)) {
    return node;
  }
  const children = node.fields || node.componentProps?.items;
  if (children && Array.isArray(children)) {
    for (const field of children) {
      const childNode = findNode(field, predicate);
      if (childNode) {
        return childNode;
      }
    }
  }
  return null;
}

const cardWithCollapseNode = (() => {
  const root = cloneDeep(cardWithCollapse);
  const collapse = findNode(root, (node) => node.component === 'Collapse');
  const title = findNode(root, (node) => node.isSectionTitle);
  const container = findNode(root, (node) => node.isFieldsContainer);
  //  eliminate warnings
  title.isSectionTitle = undefined;
  container.isFieldsContainer = undefined;
  return { root, collapse, title, container };
})();

function createNodeForCardWithCollapse(title, fields) {
  const { root, collapse, title: titleNode, container } = cardWithCollapseNode;
  collapse.componentProps.defaultActiveKey = [title.id];
  titleNode.key = title.id;
  titleNode.label = title.label;
  container.fields = fields;
  return cloneDeep(root);
}

const cardWithGridNode = (() => {
  const root = cloneDeep(cardWithGrid);
  const title = findNode(root, (node) => node.isSectionTitle);
  const container = findNode(root, (node) => node.isFieldsContainer);
  //  eliminate warnings
  title.isSectionTitle = undefined;
  container.isFieldsContainer = undefined;
  return { root, title, container };
})();

function createNodeForCardWithGrid(title, fields) {
  const { root: cardRoot, title: titleNode, container: containerNode } = cardWithGridNode;
  titleNode.componentProps.content = title.label;
  containerNode.fields = fields;
  return cloneDeep(cardRoot);
}

const stepsWithCardNode = (() => {
  const root = cloneDeep(stepsWithCard);
  const title = findNode(root, (node) => node.isSectionTitle);
  const container = findNode(root, (node) => node.isFieldsContainer);
  const collapse = findNode(root, (node) => node.component === 'Collapse');
  //  eliminate warnings
  title.isSectionTitle = undefined;
  container.isFieldsContainer = undefined;
  return { root, collapse, title, container };
})();

function createNodeForStepsWithCard(title, fields) {
  const { title: titleNode, container } = stepsWithCardNode;
  titleNode.key = title.id;
  titleNode.label = title.label;
  container.fields = fields;
  return cloneDeep(titleNode);
}

function wrapForStepsWithCard(items) {
  const { root, collapse } = stepsWithCardNode;
  const collapseItems = items.filter((item) => item.key && !item.component);
  collapse.componentProps.defaultActiveKey = collapseItems.map((item) => item.key);
  collapse.componentProps.items = collapseItems;
  root.fields = root.fields.filter((field) => field.keepForApplyStyle || field === collapse);
  root.fields = root.fields.concat(items.filter((item) => !collapseItems.includes(item)));
  return [cloneDeep(root)];
}

const userProfileCardNode = (() => {
  const root = cloneDeep(userProfileCard);
  const container = findNode(root, (node) => node.isFieldsContainer);
  const card = findNode(root, (node) => node.fields?.includes(container));
  //  eliminate warnings
  container.isFieldsContainer = undefined;
  return { root, card, container };
})();

function createNodeForUserProfileCard(title, fields) {
  const { container: containerNode } = userProfileCardNode;
  containerNode.fields = fields;
  return cloneDeep(containerNode);
}

function wrapForUserProfileCard(items) {
  const { root, card } = userProfileCardNode;
  root.fields = root.fields.filter((field) => field.keepForApplyStyle || field === card);
  card.fields = items;
  return [cloneDeep(root)];
}
