import { get } from 'lodash-es';
import { fieldHasItems, forEachFieldInSchema } from '@icp/form-schema';
import { selectionIsItem } from './otherUtils';
import { FIELD_TYPE_TABLE_COLUMN } from '../constant';

function getMatchObjAndKeyPath(schema, selectedField) {
  const fieldInfo = get(schema, selectedField.keyPath);
  const { type, keyPath, colIndex, isAutoGroupColColumn, itemIndex } = selectedField;

  let toMatchObj = fieldInfo;
  let objKeyPath = keyPath;

  if (type === FIELD_TYPE_TABLE_COLUMN) {
    const colDefKeyPath = isAutoGroupColColumn
      ? ['componentProps', 'autoGroupColumnDef']
      : ['componentProps', 'columnDefs', colIndex];
    const colDef = get(fieldInfo, colDefKeyPath);

    if (colDef) {
      toMatchObj = colDef;
      objKeyPath = keyPath.concat(colDefKeyPath);
    }
  }

  if (selectionIsItem(type)) {
    const stepKeyPath = ['componentProps', 'items', itemIndex];
    const step = get(fieldInfo, stepKeyPath);

    if (step) {
      toMatchObj = step;
      objKeyPath = keyPath.concat(stepKeyPath);
    }
  }

  return { toMatchObj, objKeyPath };
}

function getMatchStr(schema, selectedField) {
  const { toMatchObj, objKeyPath } = getMatchObjAndKeyPath(schema, selectedField);

  const indentSpaces = Array(objKeyPath.length * 2)
    .fill(' ')
    .join('');
  let str = JSON.stringify(toMatchObj, null, 2);

  str = str.slice(2, str.length - 2);
  str = indentSpaces + str;

  for (let i = 0, len = str.length; i < len; i++) {
    if (str[i] === '\n') {
      str = str.slice(0, i + 1) + indentSpaces + str.slice(i + 1, str.length);
      len += indentSpaces.length;
      i += indentSpaces.length;
    }
  }

  // 1000 已经足够长去匹配了, 太长的话 monaco findMatches 会抛错 invalid regexp
  return str.slice(0, 1000);
}

// cb 返回 true 停止 for each
function forEachSelectableInSchema(schema, cb) {
  forEachFieldInSchema(schema, (field, keyPath) => {
    const { component, componentProps } = field;

    if (['Table', 'EditableTable'].includes(component)) {
      for (let index = 0; index < componentProps?.columnDefs?.length; index++) {
        const colDef = componentProps.columnDefs[index];
        const result = cb(colDef, keyPath.concat('componentProps', 'columnDefs', index));
        if (result) {
          return true;
        }
      }
    }

    if (fieldHasItems(field)) {
      for (let index = 0; index < componentProps?.items?.length; index++) {
        const item = componentProps.items[index];
        const result = cb(item, keyPath.concat('componentProps', 'items', index));
        if (result) {
          return true;
        }
      }
    }

    return cb(field, keyPath);
  });
}

function findMatchIndex(schema, selectedField) {
  const { toMatchObj, objKeyPath } = getMatchObjAndKeyPath(schema, selectedField);
  const selectedKeyPathNumber = objKeyPath
    .filter((key) => typeof key === 'number')
    .reduce((acc, cur) => acc + cur, 0);

  const isBefore = (keyPath) => {
    const number = keyPath
      .filter((key) => typeof key === 'number')
      .reduce((acc, cur) => acc + cur, 0);
    return number < selectedKeyPathNumber;
  };

  let index = 0;

  forEachSelectableInSchema(schema, (field, keyPath) => {
    const isBeforeItem = isBefore(keyPath);

    if (!isBeforeItem) {
      return true;
    }

    if (keyPath.length === objKeyPath.length) {
      if (JSON.stringify(field, null, 2) === JSON.stringify(toMatchObj, null, 2)) {
        index++;
      }
    }

    return false;
  });

  return index;
}

export default function jumpToSelectedLine(schema, selectedField, editor) {
  // select form
  if (Array.isArray(selectedField.keyPath) && selectedField.keyPath.length === 0) {
    editor.setPosition({ lineNumber: 0, column: 0 });
    editor.setScrollTop(0);
    return;
  }

  const fieldInfo = get(schema, selectedField.keyPath);
  if (!fieldInfo) {
    return;
  }

  const toMatchStr = getMatchStr(schema, selectedField);
  const match = editor.getModel().findMatches(toMatchStr);

  if (!match.length) return;

  const matchIndex = match.length > 1 ? findMatchIndex(schema, selectedField) : 0;

  const { range } = match[matchIndex];

  const position = {
    lineNumber: range.startLineNumber,
    column: range.endColumn,
  };

  editor.setPosition(position);
  // editor.setSelection(range);
  // -2 顶部预留一行空白防止太过于顶头高亮行不明显
  editor.revealLinesInCenterIfOutsideViewport(range.startLineNumber - 2, range.endLineNumber);
}
