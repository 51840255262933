import { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Loading16 from './Loading16';
import Loading20 from './Loading20';

const Loading = forwardRef(function Loading(props, ref) {
  const {
    className,
    size = 20,
    centered = true,
    overlay = false,
    delayed = true,
    delayTime = 800,
    ...other
  } = props;

  const [show, setShow] = useState(false);
  const timeout = useRef(null);

  useEffect(() => {
    timeout.current = setTimeout(() => setShow(true), delayTime);

    return () => clearTimeout(timeout.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span
      className={clsx(
        'icp-loading',
        {
          'icp-loading-centered': centered,
          'icp-loading-overlay': overlay,
          'icp-transparent': delayed && !show,
        },
        className,
      )}
      role="progressbar"
      ref={ref}
      {...other}
    >
      {size < 20 ? <Loading16 size={size} /> : <Loading20 size={size} />}
    </span>
  );
});

Loading.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  centered: PropTypes.bool,
  overlay: PropTypes.bool,
  delayed: PropTypes.bool,
  delayTime: PropTypes.number,
};

export default Loading;
