import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Icon from '../../Icon';
import Popover from '../../Popover';
import FilterItemCondition from './FilterItemCondition';
import FilterItemValue from './FilterItemValue';
import { AG_FILTER_TYPES_UNARY } from '../../AgTable/constants';
import useFilterItemDisplayText from './useFilterItemDisplayText';
import { filterHasValue } from './utils';

function FilterItem(props) {
  const {
    context,
    defaultOpen = false,
    columnDefs,
    model,
    deletable = true,
    onChange,
    onDelete,
  } = props;

  const [open, setOpen] = useState(false);
  const anchorEl = useRef(null);

  useEffect(() => {
    // effect 过后 anchorEl.current 才有值
    if (defaultOpen) {
      setOpen(true);
    }
    // 不响应 defaultOpen 的变化
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const colDef = columnDefs.find((col) => col.colId === model.colId);

  // set filter 的 values 可能是异步的，hooks 一下
  const displayText = useFilterItemDisplayText(context, model, colDef);

  if (!colDef) {
    return null;
  }

  return (
    <>
      <div
        className={clsx('icp-table-filter-item icp-clickable icp-center', {
          'has-value': filterHasValue(model),
        })}
        ref={anchorEl}
        onClick={() => setOpen(true)}
      >
        {displayText}
        <Icon name="oct:chevron-down" size={12} />
      </div>
      <Popover
        className="icp-table-filter-item-detail"
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
      >
        <FilterItemCondition
          model={model}
          deletable={deletable}
          onChange={onChange}
          onDelete={onDelete}
        />
        {!AG_FILTER_TYPES_UNARY.includes(model.type) ? (
          <FilterItemValue
            key={model.type}
            context={context}
            colDef={colDef}
            model={model}
            onChange={onChange}
          />
        ) : null}
      </Popover>
    </>
  );
}

FilterItem.propTypes = {
  context: PropTypes.shape({}),
  defaultOpen: PropTypes.bool,
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  model: PropTypes.shape({
    colId: PropTypes.string,
    filterType: PropTypes.string,
    type: PropTypes.string,
  }),
  deletable: PropTypes.bool,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
};

export default FilterItem;
