import { ensureDisplayText } from '@icp/utils';
import { findI18nValues } from '@icp/i18n';
import { excludeData } from '../ACLElement/ACL';

export const DEFAULT_VALUE_TYPE = 'itemAlwaysArray';
export const DEFAULT_MAPPING = { value: 'value', label: 'label' };

export function originToMapping(item, mapping) {
  if (!item) return item;

  const newItem = {
    ...item,
    value: item[mapping.value],
    label: item[mapping.label],
  };

  if (mapping.value !== 'value') {
    delete newItem[mapping.value];
  }
  if (mapping.label !== 'label') {
    delete newItem[mapping.label];
  }

  // 处理 label 有多语言资源的情况
  const labelI18nValues = findI18nValues(newItem, mapping.label);
  for (const [suffix, i18nValue] of Object.entries(labelI18nValues)) {
    newItem[`label${suffix}`] = i18nValue;
    delete newItem[`${mapping.label}${suffix}`];
  }

  return newItem;
}

export function mappingToOrigin(item, mapping) {
  if (!item) return item;

  const newItem = {
    ...item,
    [mapping.value]: item.value,
    [mapping.label]: item.label,
  };

  if (mapping.value !== 'value') {
    delete newItem.value;
  }
  if (mapping.label !== 'label') {
    delete newItem.label;
  }

  // 处理 label 有多语言资源的情况
  const labelI18nValues = findI18nValues(newItem, 'label');
  for (const [suffix, i18nValue] of Object.entries(labelI18nValues)) {
    newItem[`${mapping.label}${suffix}`] = i18nValue;
    delete newItem[`label${suffix}`];
  }

  return newItem;
}

export function selectFindOption(options, value, mapping) {
  const find = (v) => {
    return options.find((item) => item[mapping.value] === v);
  };

  // 里面可能会存在找不到的 options，后续操作依此来判断是不是需要用已经存下来的旧 value
  if (Array.isArray(value)) {
    return value.map(find);
  }

  return find(value);
}

function formatOptionsByStringEqual(options, mapping) {
  if (!options || !options.length) {
    return [];
  }
  return options.map((op) => {
    return {
      ...op,
      [mapping.value]: String(op[mapping.value]),
    };
  });
}

export function formatOptions({
  optionsProp,
  dataFetched,
  mapping,
  stringEqual,
  dataExclusion,
  loading,
}) {
  let options = optionsProp || dataFetched;

  if (loading || !Array.isArray(options)) {
    return [];
  }

  if (stringEqual) {
    options = formatOptionsByStringEqual(options, mapping);
  }

  if (Array.isArray(dataExclusion) && dataExclusion.length) {
    return excludeData(options, dataExclusion, mapping.value);
  }

  return options;
}

export function getValueOfObjectValue(item, mapping, useOriginValue) {
  if (!item || typeof item !== 'object') return null;
  return useOriginValue ? item[mapping.value] : item.value;
}

export function getLabelOfObjectValue(item, mapping, useOriginValue) {
  if (!item || typeof item !== 'object') return null;
  return useOriginValue ? item[mapping.label] : item.label;
}

export function getDisplayText({
  valueProp,
  options,
  mapping,
  useOriginValue,
  findOption = selectFindOption,
  hideMeaningLessValue,
}) {
  if (!valueProp) return '';

  const getLabel = (item) => {
    // 如果是 object 有 label 直接显示，不显示 options 里最新的
    if (typeof item === 'object' && item !== null) {
      const label = getLabelOfObjectValue(item, mapping, useOriginValue);

      if (label !== null && label !== undefined) {
        return label;
      }
    }

    // 否则根据 options 里的 label 显示
    const value = getValueOfObjectValue(item, mapping, useOriginValue) ?? item;
    const option = findOption(options, value, mapping);
    if (option) {
      return option[mapping.label];
    }

    // 找不到可以显示的 label，fallback 显示看不懂的 value
    return !hideMeaningLessValue ? value : '';
  };

  if (Array.isArray(valueProp)) {
    return valueProp.map(getLabel).join(', ');
  }

  return ensureDisplayText(getLabel(valueProp));
}

export function toComponentValue({ valueProp, multiple, mapping, useOriginValue }) {
  let labeledValue;

  const toLabeledValueItem = (v) => {
    let item;
    if (typeof v === 'object' && v) {
      item = v;
    } else if (typeof v === 'number' || typeof v === 'string' || typeof v === 'boolean') {
      item = { value: v };
    }

    if (useOriginValue) {
      item = originToMapping(item, mapping);
    }

    return item;
  };

  if (multiple) {
    // material 要求 multiple 的时候 value 必须是数组
    labeledValue = []
      .concat(valueProp)
      .filter((x) => x !== undefined && x !== null)
      .map(toLabeledValueItem);
  } else if (Array.isArray(valueProp)) {
    labeledValue = toLabeledValueItem(valueProp[0]);
  } else {
    labeledValue = toLabeledValueItem(valueProp);
  }

  return labeledValue;
}

export function toSimpleValue(value) {
  const getValue = (item) => {
    if (Array.isArray(item)) {
      return item.map(getValue);
    }

    if (typeof item === 'object' && item !== null) {
      return item.value;
    }
    return item;
  };
  return getValue(value);
}

export function getValueItem(option, mapping, useOriginValue, isStandardDataSource) {
  const item = !isStandardDataSource
    ? {}
    : {
        // 表单引擎标准数据源后端dot walking功能，约定hardcode mapping: id -> id
        id: option.id != null ? +option.id : undefined,
      };
  for (const [k, v] of Object.entries(mapping)) {
    item[k] = option[v];

    // eg：mapping 是 { value: 'id', label: 'name' }，
    // 当 options 里存在 { id: 12345, name: 'abc', name_i18n_en-US: 'abc-en' } 等 name 的多语言资源时，
    // 给 item 设置上 item.label_en-US = 'abc-en'。
    const i18nValues = findI18nValues(option, v);
    for (const [suffix, i18nValue] of Object.entries(i18nValues)) {
      item[`${k}${suffix}`] = i18nValue;
    }
  }

  if (!useOriginValue) {
    return item;
  }

  return mappingToOrigin(item, mapping);
}

// op: option array when mode is multiple, single option when mode is single
export function toInterfaceValue({
  oldValue,
  newValue,
  op,
  multiple,
  valueType,
  mapping,
  useOriginValue,
  isStandardDataSource,
}) {
  // Select 组件表单不应该支持 tags 模式任意输入字符串。
  // 这个需求应该是 AutoComplete 组件来完成的。
  /* if (mode === 'tags') {
    if (valueType === 'value' || valueType === 'valueAlwaysArray') {
      return newValue;
    }
    return newValue?.map((x) => ({ value: x, label: x }));
  } */

  if (multiple) {
    if (valueType === 'value' || valueType === 'valueAlwaysArray') {
      return newValue;
    }
    return op
      ?.map((option, index) => {
        if (option) {
          return getValueItem(option, mapping, useOriginValue, isStandardDataSource);
        }
        // 如果没有 option，尝试从 oldValue 里找到旧的 value 直接返回，不清已经存下来的旧数据
        const key = newValue[index];
        if (key) {
          return [].concat(oldValue).find((x) => x?.value === key);
        }
        return null;
      })
      .filter(Boolean);
  }

  switch (valueType) {
    case 'value':
      return newValue;
    case 'valueAlwaysArray':
      return newValue ? [newValue] : [];
    case 'item':
      return op ? getValueItem(op, mapping, useOriginValue) : op;
    case 'itemAlwaysArray':
    default:
      return op ? [getValueItem(op, mapping, useOriginValue, isStandardDataSource)] : [];
  }
}

export function getMaterialDisplayLabel({ options, value, v, mapping }) {
  const innerValueItem = [].concat(value).find((item) => item.value === v);

  // 如果已经保存了 label，直接显示
  if (innerValueItem?.label) {
    return innerValueItem.label;
  }

  const op = (options || []).find((item) => item[mapping.value] === v);

  // 如果有 options，直接显示 options 里最新的值
  if (op) {
    return op[mapping.label];
  }

  return v;
}
