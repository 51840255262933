import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { debounce } from '@icp/utils';
import { AgTable } from '@icp/components';
import ACLDataSource from './ACLDataSource';
import { setSelectedIfNeed } from './utils';

const ACLTableClientSide = forwardRef(function ACLTableClientSide(props, ref) {
  const {
    aclDataSource,
    position,
    multiple,
    mapping,
    selectedKeys = [],
    onSelectionChanged,
    onLoadTotalCount,
    ...other
  } = props;

  // If isLazy is false, ag-grid is clientSide, request all data and then apply to ag-grid
  const [rowData, setRowData] = useState(null);
  const gridRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getSelectedKeys: () => {
      const rows = gridRef.current.api.getSelectedRows();
      // stringEqual already handled by fetchAllRows.then
      return rows.map((row) => row[mapping.value]);
    },
    deselectAll: () => {
      gridRef.current.api.deselectAll();
    },
  }));

  const calculateRowData = useCallback(
    (allRowsData) => {
      if (position === 'left') {
        const data = allRowsData.filter((item) => !selectedKeys.includes(item[mapping.value]));
        setRowData(data);
      } else if (position === 'right') {
        const data = selectedKeys
          .map((id) => allRowsData.find((item) => item[mapping.value] === id))
          .filter(Boolean);
        setRowData(data);
      } else {
        // is single select, not in transfer
        setRowData(allRowsData);
      }
    },
    [mapping.value, position, selectedKeys],
  );

  useEffect(() => {
    // selectedKeys change means init or transfer happens
    aclDataSource
      .fetchAllRows(selectedKeys)
      .then((res) => {
        if (onLoadTotalCount) {
          onLoadTotalCount(res.length);
        }
        return res;
      })
      .then(calculateRowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKeys]);

  const handleFirstDataRendered = useCallback((params) => {
    setSelectedIfNeed(multiple, selectedKeys, params.api);

    if (multiple) {
      gridRef.current.api.autoSizeAllColumns();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectionChanged = debounce((params) => {
    const rows = params.api.getSelectedRows();

    const hasSelect = rows.length > 0;

    if (onSelectionChanged) {
      onSelectionChanged(hasSelect);
    }
  }, 50);

  return (
    <AgTable
      {...other}
      rowModelType="clientSide"
      rowData={rowData}
      getRowId={(params) => String(params.data[mapping.value] ?? params.data.id)}
      rowSelection={multiple ? 'multiple' : 'single'}
      isRowSelectable={(params) => !!params.data?.[mapping.value]}
      onSelectionChanged={handleSelectionChanged}
      onFirstDataRendered={handleFirstDataRendered}
      ref={gridRef}
    />
  );
});

ACLTableClientSide.propTypes = {
  aclDataSource: PropTypes.instanceOf(ACLDataSource),
  position: PropTypes.oneOf(['left', 'right']),
  multiple: PropTypes.bool,
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  selectedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onSelectionChanged: PropTypes.func,
  onLoadTotalCount: PropTypes.func,
  rowModelType: PropTypes.string,
  pagination: PropTypes.bool,
};

export default ACLTableClientSide;
