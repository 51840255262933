import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash-es';
import { fetchSingleDataSource, selectValues } from '@icp/form-renderer-core';
import { isCancel } from '@icp/settings';
import useDataUrl from './useDataUrl';
import useDataFilters from './useDataFilters';
import { useStore } from '../store';

function useDataWithLength() {
  const [dataFetched, setDataFetched] = useState(null);

  const dataLength = useRef(0);
  dataLength.current = dataFetched?.length || 0;

  return [dataFetched, dataLength, setDataFetched];
}

export default function useDataSourceLazy(props) {
  const {
    skip: skipProp,
    searchText,
    debounceLeading = true,
    cacheBlockSize = 30,
    // single data source config
    dataSource,
    dataUrl: dataUrlProp,
    dataFilters,
    sortModel,
    dataResponseKeyPath,
    transformDataResponse,
    translateDataResponse,
    debounceTime = 200,
    defaultValue,
    httpMethod,
    selectColId,
    // aggregation config，目前只有 echart 单数据源支持
    aggregation,
    dataFilterAfterAggregation,
  } = props;

  const store = useStore();

  const dataUrl = useDataUrl({ dataUrlProp, dataSource });
  const filterModel = useDataFilters(dataFilters);
  const filterModelAfterAggregation = useDataFilters(dataFilterAfterAggregation);

  const skip = skipProp || !dataUrl;

  const [freshLoading, setFreshLoading] = useState(!skip);
  const [moreLoading, setMoreLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataFetched, dataLength, setDataFetched] = useDataWithLength();

  // 有个小问题是当总数刚好能整除的时候会多发一个无意义的请求
  const hasMore = !skip && dataLength.current % cacheBlockSize === 0;

  const fetchDataSource = useCallback(
    (signal, isLoadMore) => {
      if (isLoadMore) {
        setMoreLoading(true);
      } else {
        setError(null);
        setFreshLoading(true);
      }

      return fetchSingleDataSource({
        signal,
        // 不能在外面 selectContext，alias 会 dispatch 去修改 context
        context: store.getState().context,
        formData: selectValues(store.getState()),
        searchText,
        startRow: isLoadMore ? dataLength.current : 0,
        endRow: (isLoadMore ? dataLength.current : 0) + cacheBlockSize,

        // single data source config
        dataUrl,
        dataResponseKeyPath,
        filterModel,
        sortModel,
        transformDataResponse,
        translateDataResponse,
        defaultValue,
        httpMethod,
        selectColId,

        // aggregation config
        aggregation,
        filterModelAfterAggregation,
      })
        .then((data) => {
          setError(null);
          setMoreLoading(false);
          setFreshLoading(false);
          return data;
        })
        .catch((err) => {
          if (!isCancel(err)) {
            setDataFetched(null);
            setError(err);
            setMoreLoading(false);
            setFreshLoading(false);
          }
          return Promise.reject(err);
        });
    },
    [
      store,
      searchText,
      dataLength,
      cacheBlockSize,
      dataUrl,
      dataResponseKeyPath,
      filterModel,
      sortModel,
      transformDataResponse,
      translateDataResponse,
      defaultValue,
      httpMethod,
      aggregation,
      filterModelAfterAggregation,
      setDataFetched,
      selectColId,
    ],
  );

  // 要支持 leaning，单独提一个 memo 的 debounce 函数
  const debounceRequest = useMemo(() => {
    return debounce((cb) => cb(), debounceTime, { leading: debounceLeading });
  }, [debounceTime, debounceLeading]);

  useEffect(() => {
    if (skip) {
      setDataFetched(null);
      setError(null);
      setFreshLoading(false);
      return () => {};
    }

    const controller = new AbortController();
    const { signal } = controller;

    debounceRequest(() => {
      fetchDataSource(signal).then(setDataFetched);
    });

    return () => {
      controller.abort();
    };
  }, [skip, fetchDataSource, debounceRequest, setDataFetched]);

  const loadMore = (signal) => {
    if (!hasMore || freshLoading || moreLoading) {
      return;
    }

    debounceRequest(() => {
      fetchDataSource(signal, true).then((data) => {
        setDataFetched((exists) => (exists || []).concat(data));
      });
    });
  };

  return { freshLoading, moreLoading, error, dataFetched, hasMore, loadMore };
}
