import { useMemo } from 'react';
import { useDataSource } from '../../hooks';
import { useIsInDesign } from '../../FormRenderCtx';
import { transformGanttData } from './utils';

export default function useGanttDataSource({
  dataSources,
  refresh,
  mapping,
  relation,
  filterModel,
  searchText,
}) {
  const isInDesign = useIsInDesign();

  const relationFilter = useMemo(() => {
    if (!relation.colId || !relation.value) {
      return null;
    }

    return [
      {
        colId: relation.colId,
        filterType: 'text',
        type: 'equals',
        filter: relation.value,
      },
    ];
  }, [relation]);

  const multiDataSource = useMemo(() => {
    return [
      dataSources.task
        ? {
            dataResponseKeyPath: 'results',
            ...dataSources.task,
            dataFilters: (dataSources.task.dataFilters || [])
              .concat(filterModel, relationFilter)
              .filter(Boolean),
            searchText,
          }
        : null,
      dataSources.link
        ? {
            dataResponseKeyPath: 'results',
            ...dataSources.link,
            dataFilters: (dataSources.link.dataFilters || [])
              .concat(relationFilter)
              .filter(Boolean),
          }
        : null,
      dataSources.calendar,
    ];
  }, [dataSources, filterModel, relationFilter, searchText]);

  const taskRes = useDataSource({
    skip: isInDesign || !multiDataSource[0],
    refresh,
    ...multiDataSource[0],
  });
  const linkRes = useDataSource({
    skip: isInDesign || !multiDataSource[1],
    refresh,
    ...multiDataSource[1],
  });
  const calendarRes = useDataSource({
    skip: isInDesign || !multiDataSource[2],
    refresh,
    ...multiDataSource[2],
  });

  const taskData = taskRes.dataFetched;
  const linkData = linkRes.dataFetched;
  const calendarData = calendarRes.dataFetched;

  const ganttData = useMemo(() => {
    return transformGanttData({ data: taskData, links: linkData, mapping });
  }, [linkData, mapping, taskData]);

  return {
    loading: taskRes.loading || linkRes.loading || calendarRes.loading,
    error: taskRes.error || linkRes.error || calendarRes.error,
    ganttData,
    calendarData,
  };
}
