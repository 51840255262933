import PropTypes from 'prop-types';
import { useMemo } from 'react';
import SelectElement from '../../SelectElement';
import { CurrentDataProvider } from '../../../currentDataCtx';
import { DEFAULT_MAPPING, DEFAULT_VALUE_TYPE, toComponentValue } from '../../SelectElement/utils';

function ParentCellEditor(props) {
  const {
    api: gridApi,
    value,
    onValueChange,
    cellStartedEdit,
    colDef,
    data,
    context,
    componentProps,
    node: nodeProp,
  } = props;

  const {
    allowLoop,
    mode: legacyMode, // @deprecated
    multiple: multipleProp = false,
    mapping = DEFAULT_MAPPING,
    valueType = DEFAULT_VALUE_TYPE,
    useOriginValue,
    ...otherComponentProps
  } = componentProps || {};

  const multiple = multipleProp || legacyMode === 'multiple' || legacyMode === 'tags';

  const options = useMemo(() => {
    const list = [];

    if (allowLoop) {
      gridApi.forEachNode((node) => {
        // 禁止选自己
        if (node === nodeProp) return;
        list.push(node.data);
      });
      return list;
    }

    const getId = (v) => {
      const compVal = toComponentValue({ valueProp: v, multiple, mapping, useOriginValue });
      if (!compVal) return undefined;
      // eslint-disable-next-line no-nested-ternary
      return Array.isArray(compVal)
        ? compVal[0]?.value
        : typeof compVal === 'object'
          ? compVal.value
          : compVal;
    };

    const allDataMap = {};
    gridApi.forEachNode((node) => {
      allDataMap[getId(node.data[mapping.value])] = node.data;
    });

    const getParent = (row) => {
      return allDataMap[getId(row[colDef.colId])];
    };

    const selfId = getId(data[mapping.value]);

    gridApi.forEachNode((node) => {
      // 禁止选自己
      if (node === nodeProp) return;

      // 禁止循环
      let parent = getParent(node.data);
      while (parent) {
        if (getId(parent[mapping.value]) === selfId) return;
        parent = getParent(parent);
      }
      list.push(node.data);
    });
    return list;
  }, [gridApi, data, nodeProp, colDef.colId, allowLoop, useOriginValue, mapping, multiple]);

  return (
    <CurrentDataProvider value={data}>
      <SelectElement
        className="table-select-editor"
        suppressFormControl={true}
        value={value}
        onChange={onValueChange}
        componentProps={{
          size: context.tableSize,
          defaultOpen: cellStartedEdit,
          autoFocus: cellStartedEdit,
          options,
          mapping,
          useOriginValue,
          multiple,
          valueType,
          ...otherComponentProps,
        }}
      />
    </CurrentDataProvider>
  );
}

ParentCellEditor.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  onValueChange: PropTypes.func,
  cellStartedEdit: PropTypes.bool,
  colDef: PropTypes.shape({
    colId: PropTypes.string,
  }),
  api: PropTypes.shape({
    forEachNode: PropTypes.func,
  }),
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  node: PropTypes.shape({}),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  componentProps: PropTypes.shape({
    /**
     * 是否允许循环引用父节点
     */
    allowLoop: PropTypes.bool,
  }),
};

export default ParentCellEditor;
