/*
export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
*/

// 前端架构目前为根据 url 是否是 /mobile 来判断应用 mobile 的页面和 template，如果有判断真实设备的需求请单独写函数
export function isMobile() {
  return (
    window.location.pathname.startsWith(`${window.ICP_PUBLIC_PATH}mobile`) ||
    ![ENV_TYPE.DEFAULT, ENV_TYPE.MACOS].includes(getEnvType())
  );
}

// TODO
/* export function isPad() {
  return window.location.pathname.startsWith(`${window.ICP_PUBLIC_PATH}pad`);
} */

export function isMacOs() {
  return /Mac OS X/i.test(navigator.userAgent);
}

export const ENV_TYPE = {
  WECHAT: 'WeChat',
  MACOS: 'macOS',
  IPADOS: 'iPadOS',
  IOS: 'iOS',
  ANDROID: 'Android',
  DEFAULT: 'PC / Unknown',
};

export function getEnvType() {
  const userAgent = navigator.userAgent;
  if (/miniProgram/i.test(userAgent) && /micromessenger/i.test(userAgent)) {
    return ENV_TYPE.WECHAT;
  }
  if (isMacOs()) {
    return ENV_TYPE.MACOS;
  }
  if (/(iPad)/i.test(userAgent)) {
    return ENV_TYPE.IPADOS;
  }
  if (/(iPhone|iPad|iPod|iOS)/i.test(userAgent)) {
    return ENV_TYPE.IOS;
  }
  if (/(Android)/i.test(userAgent)) {
    return ENV_TYPE.ANDROID;
  }
  return ENV_TYPE.DEFAULT;
}
