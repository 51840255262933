import { isEqualWith, mapValues } from 'lodash-es';
import { resolveVariablePattern } from '@icp/utils';
import dayjs from 'dayjs';

export function submissionPreprocessorLazyValue(data) {
  return Promise.all(
    Object.entries(data).map(([k, v]) => {
      if (v && typeof v === 'object' && typeof v.resolveValue === 'function') {
        return v.resolveValue().then((actualValue) => [k, actualValue]);
      }
      return Promise.resolve([k, v]);
    }),
  ).then(Object.fromEntries);
}

function replaceRichTextUrl(resources, newValue) {
  const { oldResources, newResources } = resources;
  let fieldNewValue = newValue;

  oldResources.forEach((item) => {
    // token 第一次上传时传入
    fieldNewValue = fieldNewValue.replaceAll(item.url, item.token);
  });
  newResources.forEach((item) => {
    // 第一次上传还未传入token,此时storageId与token一致
    fieldNewValue = fieldNewValue.replaceAll(item.url, item.storageId);
  });

  return fieldNewValue;
}

function getRichTextFilesList(list) {
  const urlList = [];
  const typeList = ['attachment', 'image', 'video', 'link'];

  const recursion = (nodeList) => {
    nodeList.forEach((node) => {
      if (typeList.includes(node?.type)) {
        urlList.push(node?.link || node?.src || node?.url);
      } else if (node?.type && node?.children) {
        recursion(node.children);
      }
    });
  };

  recursion(list);
  return urlList;
}

/**
 * Transfer submitted form data from
 * { resources: { fileName, storageId, url }, longText } to
 * { resources: { tempSessionId, tempResources, unmodifiedResources}, longText }
 * @param values
 * @param initialValues
 * @param fileStorage
 * @returns {*}
 */
function transformValueWithFileStorage({ values, initialValues, fileStorage }) {
  const valueWithFileStorage = { ...values };
  const fieldsWithResource = Object.keys(values).filter((fieldId) => {
    return Array.isArray(values[fieldId]?.resources);
  });

  const { sessionId } = fileStorage;
  for (const fieldId of fieldsWithResource) {
    const newFieldValue = values[fieldId];
    const oldFieldValue = initialValues[fieldId] || {};

    let newResources = newFieldValue.resources.filter((newItem) => {
      return !oldFieldValue.resources?.find((oldItem) => oldItem.storageId === newItem.storageId);
    });
    let oldResources = newFieldValue.resources.filter((newItem) => {
      return oldFieldValue.resources?.find((oldItem) => oldItem.storageId === newItem.storageId);
    });

    // 处理 RichText 上传文件过后删除的情况
    if (newFieldValue.children) {
      const urlList = getRichTextFilesList(newFieldValue.children);
      const newList = [...newResources];
      const oldList = [...oldResources];
      newResources = newList.filter((file) => urlList.includes(file.url));
      oldResources = oldList.filter((file) => urlList.includes(file.url));
    }

    // Transfer to api request data format
    const tempResources = newResources.map((item) => {
      return {
        tempStorageId: item.storageId,
        // 由于tempStorageId和查看的时候返回的storageId无法建立映射关系，此处 token仅作为上传的文件唯一标识使用
        token: item.storageId,
        fileName: item.fileName,
      };
    });
    const unmodifiedResources = oldResources.map((item) => item.storageId);

    // 通过属性中是否含有 longText 来判断是否为富文本的值,传入后端字段为 longText，非 value。(后续添加新的上传功能组件可能更换判断方式)
    if (newFieldValue.longText) {
      const newValue = replaceRichTextUrl({ oldResources, newResources }, newFieldValue.longText);

      valueWithFileStorage[fieldId] = {
        value: '',
        longText: newValue,
        resources: {
          tempSessionId: sessionId,
          tempResources,
          unmodifiedResources,
        },
      };
    } else {
      valueWithFileStorage[fieldId] = {
        resources: {
          tempSessionId: sessionId,
          tempResources,
          unmodifiedResources,
        },
      };
    }
  }

  return valueWithFileStorage;
}

function transformEditableTableFileStorage(fieldValue, fileStorage) {
  const { inserted = [], updated = [], deleted = [], oldValues = {} } = fieldValue || {};

  const newInserted = inserted.map((values) => {
    const initialValues = oldValues[values.id] || {};
    return transformValueWithFileStorage({ values, initialValues, fileStorage });
  });

  const newUpdated = updated.map((values) => {
    const initialValues = oldValues[values.id] || {};
    return transformValueWithFileStorage({ values, initialValues, fileStorage });
  });

  return { inserted: newInserted, updated: newUpdated, deleted };
}

export function submissionPreprocessorFileStorage({ values, initialValues, fileStorage }) {
  const newValues = transformValueWithFileStorage({ values, initialValues, fileStorage });

  const editableTableFields = Object.keys(values).filter((fieldId) => {
    // 通过是否有 inserted 等来判断是不是 EditableTable
    return (
      Array.isArray(values[fieldId]?.inserted) ||
      Array.isArray(values[fieldId]?.updated) ||
      Array.isArray(values[fieldId]?.deleted)
    );
  });

  for (const fieldId of editableTableFields) {
    // EditableTable has UploadCellEditor, handle it
    newValues[fieldId] = transformEditableTableFileStorage(newValues[fieldId], fileStorage);
  }

  return newValues;
}

export function getDataWithPreprocessor({ store, formApi }) {
  const { formData, fileStorage } = store.getState();

  const { initialValues, validatorDelegates } = formData;
  const values = { ...formData.values };

  // EditableTable 需要提交的数据没有存到 store 了，需要调用 api 生成 inserted updated deleted
  for (const id of Object.keys(validatorDelegates)) {
    const obj = validatorDelegates[id];
    if (!obj) continue;

    const validation = obj[Symbol.for('validation')];

    if (!validation) continue;

    if (validation.component === 'EditableTable' && !formApi.getFieldApi(id).isSimpleMode()) {
      values[id] = formApi.getFieldApi(id).getChangedData();
    }
  }

  return submissionPreprocessorFileStorage({ values, initialValues, fileStorage });
}

export function resolveInitialValues({
  defaultValues = {},
  currentData,
  formData,
  context,
  params,
}) {
  const resolvedMagic =
    typeof defaultValues === 'object'
      ? mapValues(defaultValues, resolveMagicDefaultValue)
      : defaultValues;
  return resolveNestedValue({
    obj: resolvedMagic,
    currentData,
    formData,
    context,
    params,
  });
}

export function resolveNestedValue({ obj, currentData, formData, context, params, response }) {
  if (!obj) {
    return obj;
  }

  const doResolve = (pattern) => {
    return resolveVariablePattern({
      pattern,
      currentData: currentData || formData,
      formData,
      context,
      params,
      response,
    });
  };

  const resolveValueObj = (v) => {
    return mapValues(v, resolveValue);
  };
  const resolveValueArray = (v) => {
    return v.map((item) => resolveValue(item));
  };
  const resolveValue = (v) => {
    if (typeof v === 'string') {
      return doResolve(v);
    }

    if (Array.isArray(v)) {
      return resolveValueArray(v);
    }

    if (typeof v === 'object' && v !== null) {
      return resolveValueObj(v);
    }

    return v;
  };

  return resolveValue(obj);
}

// obj2 values cover obj1 values
export function mergeDefaultValues(obj1, obj2) {
  const isKeyValue = (obj) => {
    return (typeof obj === 'object' || obj === undefined) && !Array.isArray(obj);
  };

  if (!isKeyValue(obj1)) {
    console.error('Not an object value:', obj1);
    return null;
  }
  if (!isKeyValue(obj2)) {
    console.error('Not an object value:', obj2);
    return null;
  }

  const obj = { ...obj1 };

  Object.keys(obj2 || {}).forEach((key) => {
    if (obj2[key] !== undefined) {
      obj[key] = obj2[key];
    }
  });

  return obj;
}

export function resolveMagicDefaultValue(v) {
  if (v === '<ICP:CURRENT_DATE>') {
    // 取当前时区的日期
    return dayjs().format().slice(0, 10);
  }
  if (v === '<ICP:CURRENT_DATE_TIME>') {
    return new Date().toISOString();
  }
  return v;
}

function weekEqual(a, b) {
  if (['', undefined, null].includes(a) && ['', undefined, null].includes(b)) {
    return true;
  }
  // undefined 会回到 lodash 默认的逻辑
  return undefined;
}

export function isValueEqual(newValue, oldValue) {
  return isEqualWith(newValue, oldValue, (a, b) => {
    // textarea 的数据格式是 longText 的格式，只判断 longText 是否相等
    if (a && typeof a === 'object' && Object.hasOwnProperty.call(a, 'longText') && !a.resources) {
      return weekEqual(a.longText, b?.longText);
    }
    // '' undefined null 判断为相等, 因为 Input 和 Textarea 先填值在删除会变成 '', NumberPicker 填值再删除会变成 null
    // 更复杂的情况例如 EditableTable 先增加行再删除行会变成 []，就不处理了不是特别有必要
    return weekEqual(a, b);
  });
}
