import './Toolbar.css';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

const Toolbar = forwardRef(function Toolbar(props, ref) {
  const { children, className, ...other } = props;

  return (
    <div className={clsx('icp-toolbar', className)} {...other} ref={ref}>
      {children}
    </div>
  );
});

Toolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default Toolbar;
