import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import Button from '../AgTable/TableToolbar/Button';
import Icon from '../Icon';

function RefreshButton(props) {
  const { t } = useTranslation(['icp-components']);

  return (
    <Tooltip title={t('toolbar.refresh')}>
      <Button
        className="icp-table-toolbar-refresh-button"
        type="text"
        icon={<Icon name="oct:sync" size={16} />}
        {...props}
      />
    </Tooltip>
  );
}

export default RefreshButton;
