import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  fetchMultiDataSource,
  fetchSingleDataSource,
  legacyMultiDataUrlConfigSupport,
  selectValues,
  setContextDataSource,
  setValues,
} from '@icp/form-renderer-core';
import { debounce } from 'lodash-es';
import { isCancel } from '@icp/settings';
import { useDispatch, useStore } from '../store';
import useDataFilters from './useDataFilters';
import useMultiDataSourceConfig from './useMultiDataSourceConfig';
import useDataUrl from './useDataUrl';
import { useIsInDesign } from '../FormRenderCtx';

export default function useDataSource(props) {
  const {
    skip: skipProp,
    refresh,
    alias,
    setToFormData = false,
    searchText, // searchText 暂时只有 Select 等单数据源组件用，没有支持给多数据源
    debounceLeading = true,
    // single data source config
    dataSource,
    dataUrl: dataUrlProp,
    dataFilters,
    sortModel,
    dataResponseKeyPath,
    transformDataResponse,
    translateDataResponse, // fetchSingleDataSource 会默认 shouldTranslateByDefault()
    debounceTime = 200,
    intervalTime,
    defaultValue,
    httpMethod,
    httpBody,
    selectColId,
    // multi config
    // aggregation config，目前只有 echart 单数据源支持
    aggregation,
    dataFilterAfterAggregation,
  } = props;

  const dispatch = useDispatch();
  const store = useStore();
  const isInDesign = useIsInDesign();

  const dataUrl = useDataUrl({ dataUrlProp, dataSource });
  const filterModel = useDataFilters(dataFilters);
  const filterModelAfterAggregation = useDataFilters(dataFilterAfterAggregation);

  const { multiDataSource: multiDataSourceProp, multiDataSourceFlat } = useMemo(() => {
    return legacyMultiDataUrlConfigSupport(props);
    // 不响应其余 dataUrl 等属性的变更，老配置没这个需求，legacy support 一次就够了。
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.multiDataSource, props.multiDataSourceFlat]);
  const multiDataSource = useMultiDataSourceConfig(multiDataSourceProp);

  const skip = skipProp || (!dataUrl && !multiDataSource?.length);

  const [loading, setLoading] = useState(!skip);
  const [error, setError] = useState(null);
  const [dataFetched, setDataFetched] = useState(null);
  const [intervalTimes, setIntervalTimes] = useState(0);

  const fetchDataSource = useCallback(
    (signal) => {
      if (multiDataSource) {
        return fetchMultiDataSource({
          signal,
          skipResponseInterceptors: isInDesign,
          context: store.getState().context,
          formData: selectValues(store.getState()),
          multiDataSource,
          multiDataSourceFlat,
          dataResponseKeyPath,
          sortModel,
          transformDataResponse,
        });
      }
      return fetchSingleDataSource({
        signal,
        skipResponseInterceptors: isInDesign,
        // 不能在外面 selectContext，alias 会 dispatch 去修改 context
        context: store.getState().context,
        formData: selectValues(store.getState()),
        searchText,
        // single data source config
        dataUrl,
        dataResponseKeyPath,
        filterModel,
        sortModel,
        transformDataResponse,
        translateDataResponse,
        defaultValue,
        httpMethod,
        httpBody,
        selectColId,
        // aggregation config
        aggregation,
        filterModelAfterAggregation,
      });
    },
    [
      isInDesign,
      aggregation,
      dataResponseKeyPath,
      dataUrl,
      defaultValue,
      filterModel,
      filterModelAfterAggregation,
      httpMethod,
      httpBody,
      multiDataSource,
      multiDataSourceFlat,
      searchText,
      sortModel,
      store,
      transformDataResponse,
      translateDataResponse,
      selectColId,
    ],
  );

  // 要支持 leaning，单独提一个 memo 的 debounce 函数
  const debounceRequest = useMemo(() => {
    return debounce((cb) => cb(), debounceTime, { leading: debounceLeading });
  }, [debounceTime, debounceLeading]);

  useEffect(() => {
    if (skip) {
      setDataFetched(null);
      setError(null);
      setLoading(false);
      return () => {};
    }

    const controller = new AbortController();
    const { signal } = controller;
    let timerHandle = null;

    setError(null);
    setLoading(true);
    debounceRequest(() => {
      fetchDataSource(signal)
        .then((data) => {
          if (alias) {
            dispatch(setContextDataSource({ alias, data }));
          }
          if (setToFormData) {
            if (typeof setToFormData === 'object' && setToFormData.merge === true) {
              dispatch(setValues({ ...selectValues(store.getState()), ...data }));
            } else {
              dispatch(setValues(data));
            }
          }
          setDataFetched(data);
          setError(null);
          setLoading(false);
        })
        .catch((err) => {
          if (!isCancel(err)) {
            setDataFetched(null);
            setError(err);
            console.error(err);
            setLoading(false);
          }
        })
        .finally(() => {
          if (intervalTime != null) {
            timerHandle = setTimeout(() => {
              setIntervalTimes((prev) => prev + 1);
            }, intervalTime);
          }
        });
    });

    return () => {
      controller.abort();
      clearTimeout(timerHandle);
    };
  }, [
    alias,
    debounceRequest,
    dispatch,
    fetchDataSource,
    intervalTime,
    intervalTimes,
    setToFormData,
    skip,
    refresh, // 外部参数发生改变强制刷新
    store,
  ]);

  return { loading, error, dataFetched };
}
