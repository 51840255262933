import { Alert, Button, Input, Modal } from 'antd';
import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { immutablePush, immutableSet } from '@icp/utils';
import Icon from '../../Icon';
import Popover from '../../Popover';
import { loadSetting, saveSetting } from '../../AgTable/settingHandler';
import { isStateEqual } from '../../AgTable/utils';
import FavoriteViewItem from './FavoriteViewItem';

function getToSave(currentState) {
  return {
    ...currentState,
    // scroll 存下来无意义，同时如果保存了一滚动就会 isCurrentDirty true 了
    scrollLeft: undefined,
  };
}

function FavoriteView(props) {
  const { settingKey, currentState = {}, onSwitchFavoriteView } = props;

  const { t } = useTranslation('icp-components');
  const [modal, contextHolder] = Modal.useModal();

  const [favoriteView, setFavoriteView] = useState(() => {
    const { favoriteView: saved } = loadSetting(settingKey);
    return Array.isArray(saved?.viewList)
      ? {
          current: saved.current,
          viewList: saved.viewList,
        }
      : {
          current: null,
          viewList: [],
        };
  });
  const [open, setOpen] = useState(false);
  const [nameDialogOpen, setNameDialogOpen] = useState(false);
  const [nameError, setNameError] = useState(null);

  const anchorEl = useRef(null);
  const nameRef = useRef(null);

  const { current, viewList } = favoriteView;

  useEffect(() => {
    setTimeout(() => {
      if (nameDialogOpen) {
        nameRef.current.focus();
      }
    }, 16);
  }, [nameDialogOpen]);

  const updateAndSave = (newFavoriteView) => {
    setFavoriteView(newFavoriteView);
    saveSetting(settingKey, { favoriteView: newFavoriteView });
  };

  const handleSaveNew = () => {
    if (!settingKey) {
      modal.error({ content: t('toolbar.favorite-view.no-setting-key') });
    } else {
      setNameDialogOpen(true);
    }
  };

  const handleNameConfirm = (event) => {
    event.preventDefault();

    const name = nameRef.current.input.value;

    if (!name) {
      return;
    }

    // check name duplicated
    if (viewList.find((view) => view.name === name)) {
      setNameError(t('toolbar.favorite-view.name-exist', { name }));
      return;
    }

    // do save
    // string 表示 rename, 否则表示新增
    let newFavoriteView = favoriteView;
    if (typeof nameDialogOpen === 'string') {
      const index = viewList.findIndex((item) => item.name === nameDialogOpen);
      newFavoriteView = immutableSet(newFavoriteView, ['viewList', index, 'name'], name);

      // rename current, update
      if (current === nameDialogOpen) {
        newFavoriteView = immutableSet(newFavoriteView, ['current'], name);
      }
    } else {
      newFavoriteView = immutablePush(newFavoriteView, ['viewList'], {
        name,
        content: getToSave(currentState),
      });
      newFavoriteView = immutableSet(newFavoriteView, ['current'], name);
    }
    updateAndSave(newFavoriteView);

    setNameDialogOpen(false);
  };

  const handleDelete = (index) => {
    const newViewList = [...viewList];
    newViewList.splice(index, 1);
    const isCurrentDeleted = !newViewList.find((view) => view.name === current);
    const newFavoriteView = {
      current: isCurrentDeleted ? null : current,
      viewList: newViewList,
    };
    updateAndSave(newFavoriteView);
    // 删除 current 过后切换回默认
    if (isCurrentDeleted) {
      onSwitchFavoriteView();
    }
  };

  const handleSwitchView = (view) => {
    const newFavoriteView = {
      current: view ? view.name : undefined,
      viewList,
    };
    updateAndSave(newFavoriteView);
    onSwitchFavoriteView(view ? view.content : undefined);
    setOpen(false);
  };

  const handleSyncCurrent = (index) => {
    const newFavoriteView = immutableSet(favoriteView, ['viewList', index, 'content'], {
      ...getToSave(currentState),
    });
    updateAndSave(newFavoriteView);
  };

  const savedCurrentState = viewList.find((view) => view.name === current)?.content;
  const isCurrentDirty =
    savedCurrentState && !isStateEqual(savedCurrentState, getToSave(currentState));
  const currentLabel = (() => {
    // TODO，default view 的 show dirty 暂时做不好，先不显示了，只给保存的 view 显示 dirty
    if (!current) {
      return <span>{t('toolbar.favorite-view.default')}</span>;
    }
    return (
      <span>
        {current}
        {isCurrentDirty ? <span> ({t('toolbar.favorite-view.current-is-dirty')})</span> : null}
      </span>
    );
  })();

  return (
    <>
      <Button
        className="icp-table-toolbar-favorite-view-button"
        type="text"
        icon={<Icon name="oct:bookmark" size={16} />}
        style={{ padding: '0 8px' }}
        onClick={() => setOpen(true)}
        ref={anchorEl}
      >
        {currentLabel}
      </Button>
      <Popover
        className="icp-table-save-view icp-table-menu"
        placement="bottom-end"
        open={open}
        anchorEl={anchorEl.current}
        onClose={() => setOpen(false)}
      >
        <div className="item icp-clickable" onClick={handleSaveNew}>
          <Icon name="oct:plus" size={16} />
          {t('toolbar.favorite-view.save-new')}
        </div>
        <hr />
        <div
          className={clsx('item icp-clickable', { selected: !current })}
          onClick={() => handleSwitchView()}
        >
          <span>{t('toolbar.favorite-view.default')}</span>
        </div>
        {viewList.map((view, index) => {
          return (
            <FavoriteViewItem
              key={view.name + index}
              modal={modal}
              view={view}
              currentLabel={currentLabel}
              isCurrent={current === view.name}
              isCurrentDirty={isCurrentDirty}
              onSwitch={handleSwitchView}
              onSync={() => handleSyncCurrent(index)}
              onRename={() => setNameDialogOpen(view.name)}
              onDelete={() => handleDelete(index)}
            />
          );
        })}
      </Popover>
      <Modal
        title={t('toolbar.favorite-view.name')}
        open={!!nameDialogOpen}
        destroyOnClose={true}
        onOk={handleNameConfirm}
        onCancel={() => setNameDialogOpen(false)}
      >
        <form onSubmit={handleNameConfirm}>
          <Input
            id="name"
            ref={nameRef}
            style={{ margin: '12px 0' }}
            defaultValue={typeof nameDialogOpen === 'string' ? nameDialogOpen : ''}
            onChange={() => {
              if (nameError) {
                setNameError(null);
              }
            }}
          />
        </form>
        {nameError ? <Alert type="error" showIcon={true} message={nameError} /> : null}
      </Modal>
      {contextHolder}
    </>
  );
}

FavoriteView.propTypes = {
  settingKey: PropTypes.string,
  currentState: PropTypes.shape({}),
  onSwitchFavoriteView: PropTypes.func,
};

export default FavoriteView;
