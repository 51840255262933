import { findParentNode } from '@icp/utils';

function toStyle(obj) {
  return Object.entries(obj)
    .flatMap((x) => `${x[0]}: ${x[1]}`)
    .join('; ');
}

export default class Reorder {
  constructor(props) {
    this.direction = props.direction;
    this.callback = props.callback;
    this.item = props.item;
    this.dragging = false;
    this.scrollDiv = null;
    this.ghost = null;
    this.movingItem = null;
    this.startOffset = { x: 0, y: 0 };
  }

  dragStart(event) {
    this.dragging = true;

    const itemBox = this.item.getBoundingClientRect();

    this.scrollDiv = this.item.parentNode;
    this.movingItem = this.item;
    this.ghost = this.item.cloneNode(true);
    this.ghost.id = 'icp-drag-ghost';
    this.startOffset = {
      x: event.clientX - itemBox.left,
      y: event.clientY - itemBox.top,
    };
    this.ghost.style = toStyle({
      width: `${itemBox.width}px`,
      height: `${itemBox.height}px`,
      left: `${event.clientX - this.startOffset.x}px`,
      top: `${event.clientY - this.startOffset.y}px`,
    });
    this.item.style.opacity = 0;
    this.scrollDiv.appendChild(this.ghost);

    this.startTransition();
  }

  drag(event) {
    if (!this.dragging) {
      return;
    }

    if (this.direction === 'horizontal') {
      this.ghost.style.left = `${event.clientX - this.startOffset.x}px`;
    } else {
      this.ghost.style.top = `${event.clientY - this.startOffset.y}px`;
    }

    const mouseItem = findParentNode(event.target, 'icp-reorderable');

    if (!this.scrollDiv.contains(event.target)) {
      this.restoreToStart();
      return;
    }

    if (!mouseItem || mouseItem === this.movingItem) {
      return;
    }

    const itemBox = mouseItem.getBoundingClientRect();

    const isBefore = () =>
      this.direction === 'horizontal'
        ? event.clientX - itemBox.left < itemBox.width / 2
        : event.clientY - itemBox.top < itemBox.height / 2;
    const isAfter = () =>
      this.direction === 'horizontal'
        ? event.clientX - itemBox.left > itemBox.width / 2
        : event.clientY - itemBox.top > itemBox.height / 2;

    if (isBefore() && mouseItem.previousSibling !== this.movingItem) {
      this.movingItem.parentNode.insertBefore(this.movingItem, mouseItem);
      this.setPositions();
    } else if (isAfter() && mouseItem.nextSibling !== this.movingItem) {
      this.movingItem.parentNode.insertBefore(this.movingItem, mouseItem.nextSibling);
      this.setPositions();
    }
  }

  dragEnd(event, mouseMove, isCancel) {
    if (!this.dragging) {
      return;
    }

    const fromIndex = Number(this.movingItem.getAttribute('data-index'));
    const nextIndex =
      this.movingItem.nextSibling && this.movingItem.nextSibling !== this.ghost
        ? this.movingItem.nextSibling.getAttribute('data-index')
        : null;

    let toIndex;

    if (nextIndex === null) {
      toIndex = this.getAllItems().length - 1;
    } else if (fromIndex < nextIndex) {
      // 从前往后移动，前面会少一个元素，目标 index 应该减1
      toIndex = nextIndex - 1;
    } else {
      toIndex = nextIndex - 0;
    }

    if (isCancel) {
      // esc to cancel drag, restore
      this.restoreToStart();
    } else if (fromIndex !== toIndex) {
      this.callback({ fromIndex, toIndex });
    }
    this.ghost.parentNode.removeChild(this.ghost);
    this.ghost = null;
    this.movingItem.style.opacity = null;
    this.movingItem = null;

    if (isCancel) {
      setTimeout(() => {
        this.endTransition();
      }, 200);
    } else {
      this.endTransition();
    }
  }

  getAllItems() {
    const childNodes = this.scrollDiv.childNodes;
    return Array.from(childNodes).filter((node) => node.id !== 'icp-drag-ghost');
  }

  setPositions() {
    const allItems = this.getAllItems();
    let prev = parseInt(getComputedStyle(this.scrollDiv).paddingTop, 10);
    for (const item of allItems) {
      const width = item.offsetWidth;
      const height = item.offsetHeight;
      item.style.position = 'absolute';
      item.style.width = `${width}px`;
      item.style.height = `${height}px`;

      if (this.direction === 'horizontal') {
        item.style.left = `${prev}px`;
        prev += width;
      } else {
        item.style.top = `${prev}px`;
        prev += height;
      }
    }
  }

  startTransition() {
    this.scrollDiv.style.width = `${this.scrollDiv.offsetWidth}px`;
    this.scrollDiv.style.height = `${this.scrollDiv.offsetHeight}px`;
    this.scrollDiv.style.flex = 'none';

    const cssPosition = window.getComputedStyle(this.scrollDiv).position;
    // transition need parent is absolute or relative to set place
    if (cssPosition === 'static') {
      this.scrollDiv.style.position = 'relative';
    }

    const allItems = this.getAllItems();
    allItems.forEach((item, index) => {
      item.setAttribute('data-index', index);
      item.classList.add('dragging');
    });

    this.setPositions();
  }

  endTransition() {
    const allItems = this.getAllItems();
    for (const item of allItems) {
      item.classList.remove('dragging');
      item.style = null;
    }
    this.scrollDiv.style = null;
  }

  restoreToStart() {
    const fromIndex = Number(this.movingItem.getAttribute('data-index'));
    const startNextNode = this.movingItem.parentNode.querySelector(
      `[data-index="${fromIndex + 1}"]`,
    );
    if (startNextNode && startNextNode !== this.ghost) {
      this.movingItem.parentNode.insertBefore(this.movingItem, startNextNode);
    } else {
      this.movingItem.parentNode.appendChild(this.movingItem);
    }
    this.setPositions();
  }
}
