import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useEventCallback } from '@icp/hooks';
import RecursionRenderer from '../RecursionRenderer';
import { useClassName } from '../hooks';
import { withFieldWrapper } from '../fieldWrapper';
import { useElementDecorator, useFormApi } from '../FormRenderCtx';
import { CombinedViewProvider } from '../combinedViewCtx';

const allowedView = ['Table', 'Gantt'];

const CombinedViewWrapper = forwardRef(function CombinedViewWrapper(props, ref) {
  const {
    // children,
    keyPath,
    className: classNameProp,
    fields = [],
    componentProps = {},
    style,
  } = props;

  const { style: componentStyle, ...otherComponentProps } = componentProps;

  const ElementDecorator = useElementDecorator();
  const formApi = useFormApi();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);

  const componentMap = useMemo(() => {
    const allowed = fields.filter((fieldInfo) => allowedView.includes(fieldInfo.component));
    // 只允许一个视图出现一次
    const map = {};
    allowed.forEach((fieldInfo) => {
      map[fieldInfo.component] = fieldInfo;
    });
    return map;
  }, [fields]);

  // Table, Gantt, Kanban, List
  const [currentView, setCurrentView] = useState(() => Object.keys(componentMap)[0]);

  const nodeRef = useRef(null);

  const currentComponent = componentMap[currentView];

  const handleRefresh = useEventCallback(() => {
    formApi.getFieldApi(currentComponent.id).refresh();
  });

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
      refresh: handleRefresh,
    }),
    [handleRefresh],
  );

  const noChildren = /* !children &&  */ !fields?.length;

  const combinedViewContext = useMemo(() => {
    return {
      currentView,
      setCurrentView,
      components: Object.keys(componentMap),
    };
  }, [componentMap, currentView]);

  return (
    <CombinedViewProvider value={combinedViewContext}>
      <ElementDecorator keyPath={keyPath}>
        <div
          {...otherComponentProps}
          className={clsx(
            'combined-view-wrapper form-wrapper',
            {
              'no-children': noChildren,
            },
            className,
            classNameComp,
          )}
          style={{ ...style, ...componentStyle }}
          ref={nodeRef}
        >
          {(fields || []).map((fieldInfo, index) => {
            if (fieldInfo === currentComponent) {
              // 将 currentComponent 放在原始 index 的位置，方便 designer 里设置
              const childFields = [];
              childFields[index] = currentComponent;
              const reactKey = `${fieldInfo.id} ${fieldInfo.component} ${index}`;
              return (
                <RecursionRenderer
                  key={reactKey}
                  fields={childFields}
                  keyPath={(keyPath || []).concat('fields')}
                />
              );
            }
            return null;
          })}
        </div>
      </ElementDecorator>
    </CombinedViewProvider>
  );
});

CombinedViewWrapper.propTypes = {
  children: PropTypes.node,
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  // id: PropTypes.string,
  className: PropTypes.string,
  componentProps: PropTypes.shape({}),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
CombinedViewWrapper.displayName = 'CombinedView';

export default withFieldWrapper(CombinedViewWrapper);
