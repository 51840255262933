import { findParentNode, getDataKeyPath } from '@icp/utils';
import { get } from 'lodash-es';
import {
  FIELD_TYPE_COLLAPSE_ITEM,
  FIELD_TYPE_FORM,
  FIELD_TYPE_STEP,
  FIELD_TYPE_TAB,
  FIELD_TYPE_TABLE_COLUMN,
} from '../constant';
import { colIdToColIndex } from '../store';

export default function getMouseFieldState(event, keyPath, schema) {
  const { target } = event;

  const has = (className) => {
    return target.classList.contains(className);
  };

  if (has('fd-canvas') || has('form-renderer')) {
    return { type: FIELD_TYPE_FORM, keyPath: [] };
  }

  if (has('ag-header-cell')) {
    const colId = target.getAttribute('col-id');
    const fieldInfo = get(schema, keyPath);
    const colIndex = colIdToColIndex(fieldInfo, colId);
    const isEditableTable = !!findParentNode(target, 'editable-table-element');
    const isAutoGroupColColumn = target.getAttribute('col-id') === 'ag-Grid-AutoColumn';
    return {
      type: FIELD_TYPE_TABLE_COLUMN,
      keyPath,
      colId,
      colIndex,
      isEditableTable,
      isAutoGroupColColumn,
    };
  }

  if (has('gantt_grid_head_cell')) {
    const colId = target.getAttribute('data-column-id');
    const fieldInfo = get(schema, keyPath);
    const columnDefs = fieldInfo.componentProps?.columnDefs;
    if (!columnDefs) {
      return { keyPath };
    }
    const colIndex = columnDefs.findIndex((colDef) => colDef.colId === colId);
    return {
      type: FIELD_TYPE_TABLE_COLUMN,
      keyPath,
      colId,
      colIndex,
      isEditableTable: false,
      isAutoGroupColColumn: false,
    };
  }

  if (has('icp-step')) {
    const itemIndex = Number(
      Array.from(target.parentNode.querySelectorAll('.icp-step')).indexOf(target),
    );
    return {
      type: FIELD_TYPE_STEP,
      keyPath,
      itemIndex,
    };
  }

  if (has('ant-collapse-item')) {
    const itemIndex = Number(
      Array.from(target.parentNode.querySelectorAll('.ant-collapse-item')).indexOf(target),
    );
    return {
      type: FIELD_TYPE_COLLAPSE_ITEM,
      keyPath,
      itemIndex,
    };
  }

  if (has('ant-tabs-tab')) {
    const itemIndex = Number(
      Array.from(target.parentNode.querySelectorAll('.ant-tabs-tab')).indexOf(target),
    );
    return {
      type: FIELD_TYPE_TAB,
      keyPath,
      itemIndex,
    };
  }

  if (has('toolbar-fields-empty')) {
    return {
      keyPath: getDataKeyPath(target),
    };
  }

  if (has('page-header-actions-empty')) {
    return {
      keyPath: getDataKeyPath(target),
    };
  }

  if (has('icp-combined-view-tab')) {
    const parentKeyPath = keyPath.slice(0, keyPath.length - 2);
    const index = Array.from(target.parentNode.childNodes).indexOf(event.target);
    return { keyPath: parentKeyPath.concat('fields', index) };
  }

  return { keyPath };
}
