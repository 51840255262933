import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { immutableDelete } from '@icp/utils';
import Loading from '../../Loading';
import SearchBox from '../../SearchBox';
import { getSetFilterOptions } from './utils';

function SetFilterValues(props) {
  const { context, colDef, values: valuesProp, onChange } = props;
  const values = Array.isArray(valuesProp) ? valuesProp : [];

  const { t } = useTranslation(['icp-components', 'icp-vendor-aggrid']);

  const [options, setOptions] = useState(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    Promise.resolve(getSetFilterOptions(context, colDef)).then(setOptions);
  }, [colDef, context]);

  const optionsFiltered = useMemo(() => {
    if (!options) {
      return null;
    }
    return options.filter((op) => {
      return !searchText || op.label?.toLowerCase().includes(searchText);
    });
  }, [options, searchText]);

  // 空数组也显示 loading，set filter 没有选项则没有意义，loading 提示用户发生了错误
  if (!optionsFiltered?.length) {
    return <Loading size={16} style={{ height: 80 }} />;
  }

  const isAllSelected = values.length && values.length === optionsFiltered.length;

  const handleCheckAll = () => {
    if (!isAllSelected) {
      onChange(options.map((op) => op.value));
    } else {
      onChange([]);
    }
  };

  const handleChange = (v) => {
    const index = values.indexOf(v);
    if (index >= 0) {
      onChange(immutableDelete(values, [index]));
    } else {
      onChange(values.concat(v));
    }
  };

  return (
    <div className="icp-table-filter-item-value icp-filter-set-values">
      <SearchBox
        open={true}
        suppressSpeech={true}
        autoFocus={true}
        debounce={false}
        onSearch={setSearchText}
      />
      <div className="icp-table-menu icp-thin-scrollbar">
        <div className="item icp-clickable" onClick={handleCheckAll}>
          <Checkbox
            checked={isAllSelected}
            indeterminate={values.length && values.length !== optionsFiltered.length}
            style={{ pointerEvents: 'none' }}
          />
          {t('selectAll', { ns: 'icp-vendor-aggrid' })}
        </div>
        {optionsFiltered.map((op) => {
          const CellRenderer = colDef.cellRenderer;
          return (
            <div
              key={op.value}
              className="item icp-clickable"
              onClick={() => handleChange(op.value)}
            >
              <Checkbox checked={values.includes(op.value)} style={{ pointerEvents: 'none' }} />
              {/* TODO, 这里写死 ENUM_COLUMN， ENUM_COLUMN 这个 const 在 icp-form-renderer-react 里，无法 import */}
              {colDef.type === 'ENUM_COLUMN' ? (
                <CellRenderer value={op.value} {...colDef.cellRendererParams} context={context} />
              ) : (
                op.label
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

SetFilterValues.propTypes = {
  context: PropTypes.shape({}),
  colDef: PropTypes.shape({
    filterParams: PropTypes.shape({}),
    type: PropTypes.string,
    cellRenderer: PropTypes.elementType,
    cellRendererParams: PropTypes.shape({}),
  }),
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  onChange: PropTypes.func,
};

export default SetFilterValues;
