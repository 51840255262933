import './PDFChatAI.css';
import { AIAgent, useDefaultFeedbackSettings } from '@icp/components';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { restApi } from '@icp/settings';
import { selectUserProfile } from '../../store/features/authSlice';
import useNonScalableViewport from '../../hooks/useNonScalableViewport';
import configuration from './configuration.json';

const SMALL_SCREEN_THRESHOLD = 512;

function PDFChatAI() {
  const { t } = useTranslation();
  const userProfile = useSelector(selectUserProfile);

  useNonScalableViewport(SMALL_SCREEN_THRESHOLD);

  useEffect(() => {
    document.getElementById('root').classList.add('pdf-chat-embedded');
    return () => {
      document.getElementById('root').classList.remove('pdf-chat-embedded');
    };
  }, []);

  const feedbackSettings = useDefaultFeedbackSettings({
    url: configuration.apis.feedback.url,
    formTitle: t('pdf-chat.feedback-form-title'),
    formMessage: t('pdf-chat.feedback-form-message'),
  });

  const chatApi = {
    name: 'pdf-chat',
    url: configuration.apis.chat.url,
    stream: false,
    sendMessage({ prompt }, signal) {
      return restApi.post(configuration.apis.chat.url, { prompt }, { signal }).then((response) => {
        const { status, content, kb_items: kbItems } = response.result;
        if (status !== 0) {
          throw Error(content);
        }
        return { answer: content, source: kbItems };
      });
    },
  };

  return (
    <div className="pdf-chat-ai">
      <AIAgent
        userProfile={userProfile}
        chatConfiguration={{ chatApi }}
        uiConfiguration={{
          inputToolbar: {
            hideModelPicker: true,
            hideSpeech: true,
          },
        }}
        feedbackSettings={feedbackSettings}
      />
    </div>
  );
}

export default PDFChatAI;
