import PropTypes from 'prop-types';
import ACLElement from '../../ACLElement';
import { CurrentDataProvider } from '../../../currentDataCtx';

function ACLCellEditor(props) {
  const { value, onValueChange, data, context, componentProps } = props;

  return (
    // set currentData as row data for resolveDataFilters
    <CurrentDataProvider value={data}>
      <ACLElement
        className="table-acl-editor"
        suppressFormControl={true}
        value={value}
        onChange={onValueChange}
        componentProps={{ size: context.tableSize, ...componentProps, clickUseCapture: true }}
      />
    </CurrentDataProvider>
  );
}

ACLCellEditor.propTypes = {
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  onValueChange: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  context: PropTypes.shape({
    tableSize: PropTypes.oneOf(['default', 'small']),
    yupSchema: PropTypes.shape({
      validateSyncAt: PropTypes.func,
    }),
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  componentProps: PropTypes.shape({}),
};

// 验证unique列时使用
ACLCellEditor.getUniqueKey = ({ colDef, value }) => {
  if (!value || !Array.isArray(value)) return JSON.stringify(value);
  const mapping = colDef.cellEditorParams?.componentProps?.mapping;
  const valueKey = colDef.cellEditorParams?.componentProps?.useOriginValue
    ? mapping?.value || 'value'
    : 'value';
  return JSON.stringify(value.map((item) => String(item[valueKey])));
};

export default ACLCellEditor;
