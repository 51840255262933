import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { debounce } from '@icp/utils';
import { AgTable } from '@icp/components';
import ACLDataSource from './ACLDataSource';
import { setSelectedIfNeed } from './utils';

const ACLTableServerSideOneUrl = forwardRef(function ACLTableClientSide(props, ref) {
  const {
    aclDataSource,
    position,
    multiple,
    mapping,
    selectedKeys = [],
    onSelectionChanged,
    onLoadTotalCount,
    ...other
  } = props;

  const count = useRef(0);
  const gridRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getSelectedKeys: () => {
      // TODO, 暂时没考虑 server side 多选的事情，还没有支持
      const rows = gridRef.current.api.getSelectedRows();
      // stringEqual already handled by fetchAllRows.then
      return rows.map((row) => row[mapping.value]);
    },
    deselectAll: () => {
      gridRef.current.api.deselectAll();
    },
  }));

  const getRows = (request) => {
    // single select
    if (!position) {
      return aclDataSource.fetchRowsSlice(request).then((res) => {
        if (res.count !== count.current) {
          count.current = res.count;
          if (onLoadTotalCount) {
            onLoadTotalCount(res.count);
          }
        }

        setSelectedIfNeed(multiple, selectedKeys, gridRef.current.api);

        if (multiple && request.startRow === 0) {
          // auto size columns width according its content on the first data loaded
          gridRef.current.api.autoSizeAllColumns();
        }

        return res;
      });
    }

    return Promise.reject();

    // TODO, 产品 api 暂时不支持使用 filter 去排除某一些 ids
    /* // transfer left
    if (position === 'left') {
      return aclDataSource.fetchRowsSlice(request);
    }

    // transfer right
    if (position === 'right') {

    } */
  };

  const handleSelectionChanged = debounce((params) => {
    const serverSideSelectionState = params.api.getServerSideSelectionState();
    const { selectAll, toggledNodes } = serverSideSelectionState;

    let hasSelect;

    if (selectAll) {
      hasSelect = toggledNodes.length !== count.current;
    } else {
      hasSelect = toggledNodes.length;
    }
    if (onSelectionChanged) {
      onSelectionChanged(hasSelect);
    }
  }, 50);

  return (
    <AgTable
      cacheBlockSize={30}
      suppressContextMenu={true}
      {...other}
      rowModelType="serverSide"
      getRows={getRows}
      getRowId={(params) => String(params.data[mapping.value] ?? params.data.id)}
      rowSelection={multiple ? 'multiple' : 'single'}
      isRowSelectable={(params) => !!params.data?.[mapping.value]}
      onSelectionChanged={handleSelectionChanged}
      ref={gridRef}
    />
  );
});

ACLTableServerSideOneUrl.propTypes = {
  aclDataSource: PropTypes.instanceOf(ACLDataSource),
  position: PropTypes.oneOf(['left', 'right']),
  multiple: PropTypes.bool,
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  selectedKeys: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  onSelectionChanged: PropTypes.func,
  onLoadTotalCount: PropTypes.func,
  rowModelType: PropTypes.string,
  pagination: PropTypes.bool,
};

export default ACLTableServerSideOneUrl;
