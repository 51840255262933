import { Tooltip } from 'antd';
import { HTMLElementType } from '@icp/utils';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import Button from '../AgTable/TableToolbar/Button';
import Icon from '../Icon';

function FullScreen(props) {
  const { el } = props;

  const { t } = useTranslation(['icp-components']);

  const [fullScreen, setFullScreen] = useState(false);

  const elRef = useRef(null);
  elRef.current =
    el && el.parentNode.classList.contains('combined-view-wrapper') ? el.parentNode : el;

  useEffect(() => {
    const listener = () => {
      // 同时监听 esc 推出全屏
      if (!document.fullscreenElement) {
        setFullScreen(false);
        elRef.current.classList.remove('icp-fullscreen');
      }
    };
    document.addEventListener('fullscreenchange', listener);

    return () => document.removeEventListener('fullscreenchange', listener);
  }, []);

  const handleFullScreen = () => {
    if (fullScreen) {
      document.exitFullscreen();
    } else {
      setFullScreen(true);
      document.body.requestFullscreen();
      elRef.current.classList.add('icp-fullscreen');
    }
  };

  return (
    <Tooltip title={fullScreen ? t('toolbar.exit-fullscreen') : t('toolbar.fullscreen')}>
      <Button
        className="icp-toolbar-fullscreen-button"
        type="text"
        icon={
          fullScreen ? (
            <Icon name="oct:screen-normal" size={16} />
          ) : (
            <Icon name="oct:screen-full" size={16} />
          )
        }
        onClick={handleFullScreen}
      />
    </Tooltip>
  );
}

FullScreen.propTypes = {
  el: HTMLElementType,
};

export default FullScreen;
