import { useCallback, useEffect, useRef, useState } from 'react';

export default function useNonScalableViewport(threshold = 512) {
  const [shouldApply, setShouldApply] = useState(window.innerWidth <= threshold);
  const originalViewportMeta = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setShouldApply(window.innerWidth <= threshold);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [threshold]);

  const removeViewportMeta = () => {
    const viewportMeta = document.querySelector('meta[name="viewport"]');
    if (viewportMeta) {
      viewportMeta.parentNode.removeChild(viewportMeta);
    }
    return viewportMeta;
  };

  const restoreViewportMeta = useCallback(() => {
    if (!originalViewportMeta.current) return;
    removeViewportMeta();
    document.getElementsByTagName('head')[0].appendChild(originalViewportMeta.current);
    originalViewportMeta.current = null;
  }, []);

  useEffect(() => {
    if (shouldApply) {
      originalViewportMeta.current = removeViewportMeta();
      const metaElm = document.createElement('meta');
      metaElm.name = 'viewport';
      metaElm.content =
        'width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no,viewport-fit=cover';
      document.getElementsByTagName('head')[0].appendChild(metaElm);
    } else {
      // Restore when window width > threshold.
      restoreViewportMeta();
    }
    return () => {
      // Restore when leave the current page.
      restoreViewportMeta();
    };
  }, [restoreViewportMeta, shouldApply, threshold]);
}
