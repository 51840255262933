import { Icon } from '@icp/components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function ScaleNavigate(props) {
  const { navigateTo } = props;

  const { t } = useTranslation();

  return (
    <span style={{ whiteSpace: 'nowrap', padding: '0 4px' }}>
      <button
        className="icp-action-button"
        onClick={() => navigateTo('prev')}
        style={{ minWidth: 0, padding: '0 4px' }}
      >
        <Icon name="oct:chevron-left" size={12} />
      </button>
      <button
        className="icp-action-button"
        onClick={() => navigateTo('today')}
        style={{ minWidth: 0, padding: '0 4px' }}
      >
        {t('today', { ns: 'icp-common' })}
      </button>
      <button
        className="icp-action-button"
        onClick={() => navigateTo('next')}
        style={{ minWidth: 0, padding: '0 4px' }}
      >
        <Icon name="oct:chevron-right" size={12} />
      </button>
    </span>
  );
}

ScaleNavigate.propTypes = {
  navigateTo: PropTypes.func,
};

export default ScaleNavigate;
