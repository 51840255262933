import { get } from 'lodash-es';
import { excludeData } from '../ACLElement/ACL';

export function treeFindOption(treeData, value, mapping, comparator) {
  // treeCheckStrictly prop will make antd labelInValue always true, then value is always an object
  const getValue = (v) => {
    return typeof v === 'object' && v !== null ? v.value : v;
  };

  const dfs = (children, v) => {
    for (const item of children) {
      const result = comparator
        ? comparator(item[mapping.value], getValue(v))
        : item[mapping.value] === getValue(v);

      if (result) {
        return item;
      }

      if (item.children) {
        const found = dfs(item.children, v);
        if (found) {
          return found;
        }
      }
    }

    return null;
  };

  if (Array.isArray(value)) {
    return value.map((v) => dfs(treeData, v));
  }

  return dfs(treeData, value);
}

export function excludeTreeData(treeDataFetched, dataExclusion, key) {
  const dfs = (node) => {
    if (!Array.isArray(node.children)) {
      return;
    }
    node.children = excludeData(node.children, dataExclusion, key);
    for (const item of node.children) {
      dfs(item);
    }
  };

  const root = { children: treeDataFetched };
  dfs(root);
  return root.children;
}

export function buildTree(data, parentKeyPath) {
  const getParentId = (item) => {
    const value = get(item, parentKeyPath);

    // 自动识别一下 value，如果用表单引擎配置出来的选择父亲的一般是 select 或者 acl，值都是 { value, label } 的
    // 形式，可视化设置的时候选择字段就行了不用自己手动填 parentId[0].value 这种形式。
    if (Array.isArray(value)) {
      return value[0]?.value;
    }

    if (typeof value === 'object' && value !== null) {
      return value.value;
    }

    return value;
  };
  const existsParentNode = (parentId) => {
    return data.find((e) => `${e.id}` === `${parentId}`);
  };

  const rootNodes = data.filter((item) => {
    const paremtId = getParentId(item);
    // 如果有paremtId，但是未找到，则将该节点设置为根节点
    if (paremtId) {
      return !existsParentNode(paremtId);
    }
    return !paremtId;
  });

  let childNodes = data.filter((item) => {
    const parentId = getParentId(item);
    return data.find((parent) => {
      // 用 stringEqual，acl select 等会把 number 的 value 转成 string
      return String(parent.id) === String(parentId) && String(parent.id) !== String(item.id);
    });
  });

  const forEachLeaf = (children, callback) => {
    if (!children) {
      return;
    }
    for (const node of children) {
      if (!node.children) {
        callback(node);
      }
      forEachLeaf(node.children, callback);
    }
  };

  let prevLength = childNodes.length;
  while (childNodes.length) {
    // eslint-disable-next-line no-loop-func
    forEachLeaf(rootNodes, (node) => {
      node.children = childNodes.filter((item) => {
        const parentId = getParentId(item);
        return String(node.id) === String(parentId);
      });
      if (!node.children.length) {
        delete node.children;
      }
      childNodes = childNodes.filter((item) => {
        const parentId = getParentId(item);
        return String(node.id) !== String(parentId);
      });
    });
    if (prevLength === childNodes.length) {
      break;
    }
    prevLength = childNodes.length;
  }

  return rootNodes;
}

export function formatTreeDataByStringEqual({ treeData, mapping }) {
  const dfs = (items) => {
    if (!items || !items.length) {
      return null;
    }
    return items.map((op) => {
      const newOp = {
        ...op,
        [mapping.value]: String(op[mapping.value]),
      };
      if (newOp[mapping.children]) {
        newOp[mapping.children] = dfs(newOp[mapping.children]);
      }
      return newOp;
    });
  };

  return dfs(treeData);
}

export function formatOptions({
  treeDataProp,
  dataFetched,
  mapping,
  stringEqual,
  buildTreeBy,
  dataExclusion,
}) {
  let treeData = treeDataProp || dataFetched;

  if (!Array.isArray(treeData)) {
    return [];
  }

  if (buildTreeBy) {
    treeData = buildTree(treeData, buildTreeBy);
  }

  if (stringEqual) {
    treeData = formatTreeDataByStringEqual({ treeData, mapping });
  }

  // 树状结构移除节点的同时就会移除掉其所有子孙节点
  if (Array.isArray(dataExclusion) && dataExclusion.length) {
    return excludeTreeData(treeData, dataExclusion, mapping.value);
  }

  return treeData;
}

function forEachTree(treeData, cb) {
  const dfs = (items) => {
    if (!Array.isArray(items)) {
      return;
    }

    for (const item of items) {
      cb(item);
      dfs(item.children);
    }
  };

  dfs(treeData);
}

export function getAllIds(treeData, mapping) {
  const ids = [];

  // material tree view 要求 id 必须是 string
  forEachTree(treeData, (item) => ids.push(String(item[mapping.value])));

  return ids;
}

export function getMaterialDisplayLabel(labeledValue, options, mapping) {
  if (!labeledValue) {
    return '';
  }

  const { value: id, label } = labeledValue;

  if (label !== undefined && label !== null) {
    return label;
  }

  if (id === undefined || id === null) {
    return '';
  }

  const item = treeFindOption(options, id, mapping, (a, b) => String(a) === String(b));

  return item ? item[mapping.label] : id;
}
