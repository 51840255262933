import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { ChevronRightIcon, ChevronLeftIcon } from '@primer/octicons-react';
import { useTranslation } from 'react-i18next';
import ACLTable from './ACLTable';
import { getIdsInValue } from './utils';

const ACLTransfer = forwardRef(function TableTransfer(props, ref) {
  const { AgTableProps, defaultSearchText, unit, stringEqual, value, ...other } = props;

  const { t } = useTranslation(['icp-components', 'icp-common']);

  const [selectedKeys, setSelectedKeys] = useState(() => getIdsInValue(value, stringEqual));
  const [total, setTotal] = useState(0);
  const [leftHasSelect, setLeftHasSelect] = useState(false);
  const [rightHasSelect, setRightHasSelect] = useState(false);
  const leftTableRef = useRef(null);
  const rightTableRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getSelectedKeys: () => selectedKeys,
  }));

  const handleLeftSelectionChanged = (hasSelected) => {
    setLeftHasSelect(hasSelected);
  };

  const handleRightSelectionChanged = (hasSelected) => {
    setRightHasSelect(hasSelected);
  };

  const transferToRight = () => {
    const ids = leftTableRef.current.getSelectedKeys();
    setSelectedKeys(selectedKeys.concat(ids));
    leftTableRef.current.deselectAll();
  };

  const transferToLeft = () => {
    const ids = rightTableRef.current.getSelectedKeys();
    setSelectedKeys(selectedKeys.filter((id) => !ids.includes(id)));
    rightTableRef.current.deselectAll();
  };

  return (
    <div className="icp-table-transfer">
      <div className="icp-transfer-list">
        <div className="icp-transfer-border">
          <ACLTable
            {...AgTableProps}
            {...other}
            defaultSearchText={defaultSearchText}
            position="left"
            title={t('acl.title.all')}
            multiple={true}
            stringEqual={stringEqual}
            selectedKeys={selectedKeys}
            onSelectionChanged={handleLeftSelectionChanged}
            onLoadTotalCount={setTotal}
            ref={leftTableRef}
          />
        </div>
        <div className="icp-transfer-count">
          {t('acl.selected', { length: `${selectedKeys?.length} / ${total}`, unit })}
        </div>
      </div>
      <div className="icp-transfer-operator">
        <Button
          type="primary"
          icon={<ChevronRightIcon size={16} />}
          size="small"
          onClick={transferToRight}
          disabled={!leftHasSelect}
        />
        <Button
          type="primary"
          icon={<ChevronLeftIcon size={16} />}
          size="small"
          onClick={transferToLeft}
          disabled={!rightHasSelect}
        />
      </div>
      <div className="icp-transfer-list">
        <div className="icp-transfer-border">
          <ACLTable
            {...AgTableProps}
            {...other}
            position="right"
            title={t('acl.title.selected')}
            multiple={true}
            stringEqual={stringEqual}
            selectedKeys={selectedKeys}
            onSelectionChanged={handleRightSelectionChanged}
            ref={rightTableRef}
          />
        </div>
        <div className="icp-transfer-count" />
      </div>
    </div>
  );
});

ACLTransfer.propTypes = {
  AgTableProps: PropTypes.shape({}),
  defaultSearchText: PropTypes.string,
  isLazy: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    }),
  ),
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  unit: PropTypes.string,
  stringEqual: PropTypes.bool,
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};

export default ACLTransfer;
