import {
  ACLElement,
  AuditElement,
  ButtonElement,
  CarouselElement,
  CascaderElement,
  CheckboxElement,
  CodeEditorElement,
  CollapseElement,
  DatePickerElement,
  DateRangePickerElement,
  DividerElement,
  DropDownElement,
  EChartElement,
  EditableTableElement,
  EventCalendarElement,
  FormDesignerElement,
  GanttElement,
  IconElement,
  IconUploadElement,
  ImageElement,
  InputElement,
  ListElement,
  MiningElement,
  NavTabsElement,
  NumberPickerElement,
  OrgTreeElement,
  OcrElement,
  PageHeaderElement,
  PasswordElement,
  PermissionsElement,
  ProgressElement,
  QRCodeElement,
  RadioElement,
  RateElement,
  RichTextElement,
  SelectElement,
  SliderElement,
  StatusElement,
  StepsElement,
  SuccessElement,
  SwitchElement,
  SwiperElement,
  TableElement,
  TabsElement,
  TextareaElement,
  TextElement,
  TimePickerElement,
  TimeRangePickerElement,
  TodoListElement,
  TooltipElement,
  TreeElement,
  TreeSelectElement,
  UploadElement,
  VideoElement,
} from '../elements';
import { BoxLayout, CardLayout, GridLayout, MobileLayout, StackLayout } from '../layouts';
import {
  CombinedViewWrapper,
  DataWrapper,
  LinkWrapper,
  TimerWrapper,
  ExtensionWrapper,
} from '../wrappers';

const inputElementMap = {
  ACL: ACLElement,
  Checkbox: CheckboxElement,
  DatePicker: DatePickerElement,
  DateRangePicker: DateRangePickerElement,
  EditableTable: EditableTableElement,
  FormDesigner: FormDesignerElement,
  Input: InputElement,
  IconUpload: IconUploadElement,
  NumberPicker: NumberPickerElement,
  OrgTree: OrgTreeElement,
  Password: PasswordElement,
  Permissions: PermissionsElement,
  Radio: RadioElement,
  Rate: RateElement,
  RichText: RichTextElement,
  Select: SelectElement,
  Slider: SliderElement,
  Switch: SwitchElement,
  Textarea: TextareaElement,
  TimePicker: TimePickerElement,
  TimeRangePicker: TimeRangePickerElement,
  Upload: UploadElement,
  Cascader: CascaderElement,
  TreeSelect: TreeSelectElement,
  CodeEditor: CodeEditorElement,
  Ocr: OcrElement,
};
const displayElementMap = {
  // Flow: FlowElement,
  Audit: AuditElement,
  Button: ButtonElement,
  Carousel: CarouselElement,
  Collapse: CollapseElement,
  Divider: DividerElement,
  DropDown: DropDownElement,
  EChart: EChartElement,
  Gantt: GanttElement,
  EventCalendar: EventCalendarElement,
  Icon: IconElement,
  IconString: IconUploadElement,
  Image: ImageElement,
  List: ListElement,
  Mining: MiningElement,
  NavTab: NavTabsElement, // legacy support, NavTab already renamed to NavTabs
  NavTabs: NavTabsElement,
  PageHeader: PageHeaderElement,
  Progress: ProgressElement,
  QRCode: QRCodeElement,
  Status: StatusElement,
  Steps: StepsElement,
  Success: SuccessElement,
  Swiper: SwiperElement,
  Tab: TabsElement, // legacy support, Tab already renamed to Tabs
  Table: TableElement,
  Tabs: TabsElement,
  Text: TextElement,
  TodoList: TodoListElement,
  Tooltip: TooltipElement,
  Tree: TreeElement,
  Video: VideoElement,
};
const layoutMap = {
  // compatible with old usage
  CardLayout,
  FlowLayout: StackLayout,
  GridLayout,
  MobileLayout,
  NormalLayout: BoxLayout,
  //
  Box: BoxLayout,
  Stack: StackLayout,
  Grid: GridLayout,
  Card: CardLayout,
  Mobile: MobileLayout,
};
const wrapperMap = {
  // compatible with old usage
  LinkWrapper,
  //
  CombinedView: CombinedViewWrapper,
  Data: DataWrapper,
  Link: LinkWrapper,
  Timer: TimerWrapper,
  Extension: ExtensionWrapper,
};

const componentMap = { ...inputElementMap, ...displayElementMap, ...layoutMap, ...wrapperMap };

export default componentMap;
