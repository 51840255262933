import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { shouldTranslateByDefault } from '@icp/settings';
import { HELPER_TEXT_TYPES, helpersIsEmpty } from '@icp/form-renderer-core';
import { useElementDecorator, useIsInDesign } from '../../FormRenderCtx';
import { useClassName, useDataSource, useVariablePatternArray } from '../../hooks';
import { withFieldWrapper } from '../../fieldWrapper';
import { ConditionalPropertyPropType, SingleDataSourceTypes } from '../../propTypes';
import {
  DEFAULT_VALUE_TYPE,
  getDisplayText,
  toComponentValue,
  toInterfaceValue,
} from '../SelectElement/utils';
import { formatOptions, treeFindOption } from './utils';
import { DEFAULT_MAPPING } from '../CascaderElement/utils';
import TreeSelectUI from './TreeSelectUI';

const TreeSelectElement = forwardRef(function TreeSelectElement(props, ref) {
  const {
    keyPath,
    id,
    className: classNameProp,
    title,
    value: valueProp,
    disabled,
    readonly,
    style,
    validation,
    status,
    helpers,
    onChange,
    onTouchChanged,
    fieldTitleProps,
    componentProps = {},
  } = props;

  const {
    multiple,
    valueType = DEFAULT_VALUE_TYPE,
    mapping = DEFAULT_MAPPING,
    useOriginValue = false,
    stringEqual = false,
    dataSource,
    dataUrl,
    dataFilters,
    sortModel,
    dataResponseKeyPath = 'results',
    transformDataResponse,
    translateDataResponse = shouldTranslateByDefault(),
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
    treeData: treeDataProp,
    buildTreeBy,
    dataExclusion: dataExclusionProp,
    ...otherComponentProps
    //
  } = componentProps;

  const ElementDecorator = useElementDecorator();
  const isInDesign = useIsInDesign();

  const className = useClassName(classNameProp);
  const classNameComp = useClassName(componentProps.className);
  const dataExclusion = useVariablePatternArray(dataExclusionProp);

  const nodeRef = useRef(null);

  useImperativeHandle(
    ref,
    () => ({
      node: nodeRef.current,
    }),
    [],
  );

  const { loading, dataFetched } = useDataSource({
    skip: isInDesign || !!treeDataProp,
    dataSource,
    dataUrl,
    dataResponseKeyPath,
    dataFilters,
    sortModel,
    transformDataResponse,
    translateDataResponse,
    debounceTime,
    defaultValue,
    httpMethod,
    selectColId,
  });

  const options = useMemo(() => {
    return formatOptions({
      treeDataProp,
      dataFetched,
      mapping,
      stringEqual,
      buildTreeBy,
      dataExclusion,
    });
  }, [buildTreeBy, dataExclusion, mapping, stringEqual, dataFetched, treeDataProp]);

  const innerValue = useMemo(() => {
    return toComponentValue({ valueProp, multiple, mapping });
  }, [mapping, multiple, valueProp]);

  const handleChange = (newValue) => {
    const op = treeFindOption(options, newValue, mapping);

    const val = toInterfaceValue({
      newValue,
      op,
      multiple,
      valueType,
      mapping,
    });
    onChange(val);
  };

  // 为了避免当 valueType 是 value 的时候，fetching 过程中显示一串看不懂的 code
  const hideMeaningLessValue =
    loading &&
    innerValue &&
    (Array.isArray(innerValue) ? !innerValue[0]?.label : !innerValue.label);

  const readonlyText = useMemo(() => {
    if (!readonly) {
      return '';
    }

    return getDisplayText({
      valueProp,
      options,
      mapping,
      useOriginValue,
      findOption: treeFindOption,
      hideMeaningLessValue,
    });
  }, [hideMeaningLessValue, mapping, options, readonly, useOriginValue, valueProp]);

  return (
    <ElementDecorator keyPath={keyPath} id={id}>
      <div
        className={clsx(
          'tree-select-element',
          'input-element',
          'form-element',
          {
            'has-helper': !helpersIsEmpty(helpers),
          },
          className,
        )}
        style={style}
        ref={ref}
      >
        <TreeSelectUI
          title={title}
          validation={validation}
          disabled={disabled}
          readonly={readonly}
          status={status}
          helpers={helpers}
          value={(() => {
            if (hideMeaningLessValue) {
              return multiple ? [] : {};
            }
            return innerValue;
          })()}
          onChange={handleChange}
          onTouchChanged={onTouchChanged}
          componentProps={{
            ...otherComponentProps,
            className: classNameComp,
          }}
          fieldTitleProps={fieldTitleProps}
          multiple={multiple}
          mapping={mapping}
          readonlyText={readonlyText}
          loading={loading}
          options={options}
        />
      </div>
    </ElementDecorator>
  );
});

TreeSelectElement.propTypes = {
  keyPath: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
    }),
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool])),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
      }),
    ),
  ]),
  componentProps: PropTypes.shape({
    /**
     * 组件的 className
     */
    className: PropTypes.string,
    /**
     * 组件的 style
     */
    style: PropTypes.shape({}),
    /**
     * Describes the value is a `childEnum` item or a `childEnum` item's value,
     * describes whether the value is always an array no matter mode is single or multiple.
     *
     * example:
     *
     * Given childEnum: `[{ value: 1, label: 'AAA' }, { value: 'b', label: 'BBB' }]`
     *
     * When valueType is `value`, value would be `1` or `'b'` in `single` mode, `[1]` or `[1, 'b']` in `multiple` mode.
     *
     * When valueType is `item`, value would be `{ value: 1, label: 'AAA' }`  in `single` mode, `[{ value: 1, label: 'AAA' }, {...}]` in `multiple` mode.
     *
     * When valueType is `valueAlwaysArray`, value would be `[1]` in `single` mode, `[1, 'b']` in `multiple` mode.
     *
     * When valueType is `itemAlwaysArray`, value would be `[{ value: 1, label: 'AAA' }]` in `single` mode, `[{...}, {...}]` in `multiple` mode.
     */
    valueType: PropTypes.oneOf(['value', 'item', 'valueAlwaysArray', 'itemAlwaysArray']),
    /**
     * value 的映射关系，默认是 { value, label }
     */
    mapping: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
      // Other key map to save
    }),
    ...SingleDataSourceTypes,
    /**
     * antd TreeSelect 元素的 treeData
     */
    treeData: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string,
        children: PropTypes.arrayOf(PropTypes.shape({})),
      }),
    ),
    /**
     * 是否多选
     */
    multiple: PropTypes.bool,
    /**
     * 在 api 返回的数据中排除某些数据
     */
    dataExclusion: PropTypes.arrayOf(PropTypes.string),
  }),
  fieldTitleProps: PropTypes.shape({
    showColon: PropTypes.bool,
  }),
  validation: PropTypes.shape({
    required: PropTypes.bool,
  }),
  disabled: ConditionalPropertyPropType(PropTypes.bool),
  readonly: ConditionalPropertyPropType(PropTypes.bool),
  status: PropTypes.oneOf(HELPER_TEXT_TYPES),
  helpers: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(HELPER_TEXT_TYPES),
      text: PropTypes.string,
    }),
  ),
  onChange: PropTypes.func,
  onTouchChanged: PropTypes.func,
};

// for @icp/utils/getComponentDisplayName, otherwise, in production mode, function name will be compressed.
TreeSelectElement.displayName = 'TreeSelect';

export default withFieldWrapper(TreeSelectElement);
