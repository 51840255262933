import { IconButton, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { Icon } from '@icp/components';

function InputClearButton(props) {
  const { onClear } = props;
  return (
    <InputAdornment position="end">
      <IconButton size="small" onClick={onClear}>
        <Icon name="material:clear-rounded" />
      </IconButton>
    </InputAdornment>
  );
}

InputClearButton.propTypes = {
  onClear: PropTypes.func,
};

export default InputClearButton;
