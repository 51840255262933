import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import ACLTransfer from './ACLTransfer';
import ACLTable from './ACLTable';
import { composeClientSideValue, composeServerSideValue, formatColumnDefs } from './utils';
import ACLDataSource from './ACLDataSource';
import ACLDialogUI from './ACLDialogUI';

function ACLDialog(props) {
  const {
    componentLibrary,
    className,
    value,
    mapping,
    title,
    unit,
    multiple,
    stringEqual,
    idListUrl,
    dataUrl,
    staticAllRowsData,
    isStandardDataSource,
    dataResponseKeyPath,
    translateDataResponse,
    transformDataResponse,
    outerFilterModel,
    dataExclusion,
    selectColId,
    columnDefs: columnDefsProp,
    onClose,
    onChange,
    AgTableProps,
    defaultSearchText,
    // 前面摘除多余不需要传给 ACLTable 的属性，other 里留后路传额外属性给 AgTable
    ...other
  } = props;

  const aclDataSource = useMemo(() => {
    return new ACLDataSource(props);
    // acl 打开的弹框 fetch data 不响应任何更新，不好做也没有这个需求，关掉 dialog 再打开就是新的了。
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectorRef = useRef(null);

  const columnDefs = useMemo(() => formatColumnDefs(columnDefsProp), [columnDefsProp]);

  const handleOk = () => {
    const isLazy = idListUrl || (AgTableProps?.rowModelType === 'serverSide' && !multiple);
    const selectedKeys = selectorRef.current.getSelectedKeys();
    const newValue = isLazy
      ? composeServerSideValue(
          aclDataSource.cachedData,
          selectedKeys,
          mapping,
          stringEqual,
          isStandardDataSource,
        )
      : composeClientSideValue(
          aclDataSource.cachedAllRowsData,
          selectedKeys,
          mapping,
          stringEqual,
          isStandardDataSource,
        );
    onChange(newValue);
  };

  return (
    <ACLDialogUI
      componentLibrary={componentLibrary}
      title={title}
      multiple={multiple}
      {...other}
      onOk={handleOk}
      onClose={onClose}
    >
      {multiple ? (
        <ACLTransfer
          AgTableProps={AgTableProps}
          defaultSearchText={defaultSearchText}
          columnDefs={columnDefs}
          unit={unit}
          stringEqual={stringEqual}
          value={value}
          mapping={mapping}
          aclDataSource={aclDataSource}
          idListUrl={idListUrl}
          ref={selectorRef}
        />
      ) : (
        <ACLTable
          {...AgTableProps}
          defaultSearchText={defaultSearchText}
          columnDefs={columnDefs}
          multiple={false}
          stringEqual={stringEqual}
          value={value}
          mapping={mapping}
          aclDataSource={aclDataSource}
          idListUrl={idListUrl}
          ref={selectorRef}
        />
      )}
    </ACLDialogUI>
  );
}

ACLDialog.propTypes = {
  componentLibrary: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  stringEqual: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      // Other key map saved
    }),
  ),
  // Mapping value keys to option data keys
  mapping: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    // Other key map to save
  }),
  unit: PropTypes.string,
  idListUrl: PropTypes.string,
  dataUrl: PropTypes.string,
  staticAllRowsData: PropTypes.arrayOf(PropTypes.shape({})),
  isStandardDataSource: PropTypes.bool,
  dataResponseKeyPath: PropTypes.string,
  translateDataResponse: PropTypes.bool,
  transformDataResponse: PropTypes.string,
  outerFilterModel: PropTypes.arrayOf(PropTypes.shape({})),
  columnDefs: PropTypes.arrayOf(PropTypes.shape({})),
  // eslint-disable-next-line react/forbid-prop-types
  dataExclusion: PropTypes.array,
  selectColId: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  onChange: PropTypes.func,
  AgTableProps: PropTypes.shape({
    rowModelType: PropTypes.string,
  }),
  defaultSearchText: PropTypes.string,
};

export default ACLDialog;
