import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { Icon } from '../index';

function FilterButton(props) {
  const { t } = useTranslation(['icp-components']);

  return (
    <Tooltip title={t('toolbar.filter.label')}>
      <Button
        type="text"
        icon={<Icon name="material:filter-list-rounded" size={20} />}
        {...props}
      />
    </Tooltip>
  );
}

export default FilterButton;
